import React, { useState } from 'react';
import {
  Box,
  ButtonGroup,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from 'apis/axiosInstance';
import EditIcon from '@mui/icons-material/Edit';

const GroupTable = ({
  data,
  setData,
  loading,
  setLoading,
  setIsSuccess,
  setSuccessMessage,
  setOpenFullScreenDialog,
  setSelectedGroup,
}) => {
  const columns = [
    {
      field: 'group_id',
      headerName: 'S.No',
      width: 60,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => `${params.row.group_name}`,
    },
    {
      field: 'states',
      headerName: 'Status',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const states = ['active', 'inactive'];

        return (
          <ButtonGroup
            color='primary'
            size='small'
          >
            {states.map((state) => (
              <Button
                key={state}
                variant={
                  params.row.group_status === state ? 'contained' : 'outlined'
                }
                onClick={() => updateGroupStatus(params.row, state)}
              >
                {state}
              </Button>
            ))}
          </ButtonGroup>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row)}
          color='primary'
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const handleEditClick = (row) => {
    setSelectedGroup(row);
    setOpenFullScreenDialog(true);
  };

  const updateGroupStatus = async (row, status) => {
    try {
      setLoading(row.group_id);
      const response = await axiosInstance.patch(
        `/api/v1/access-table/${row.group_id}/groups`,
        { data: { status } }
      );

      if (response.status === 200) {
        setIsSuccess(true);
        setSuccessMessage('Status updated successfully');
        const updatedData = data.map((group) =>
          group.group_id === row.group_id
            ? { ...group, group_status: status }
            : group
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error('Error updating group status:', error);
    } finally {
      setLoading(null);
    }
  };

  return (
    <Box
      width='100%'
      height='100%'
      sx={{
        mt: 5,
        '& .access.allowed': {
          color: 'green',
          fontWeight: '600',
        },
        '& .access.disabled': {
          color: 'red',
          fontWeight: '600',
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.group_id}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '15px',
          },
        }}
      />
    </Box>
  );
};

export default GroupTable;
