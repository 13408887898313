import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { base_url } from '../../components/Mode';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import PortalUsers from './AdminDashboard/PortalUsers';
import TeamOverview from './TeamOverview/TeamOverview';
import Drives from './Drives/Drives';
import Holidays from './Holidays/Holidays';
import Monitor from './Monitor/Monitor';
import ImportLeads from './ImportLeads/ImportLeads';
import LeadAccessPolicies from './LeadAccessPolicies/LeadAccessPolicies';
import LeadsToAssignedBySalsa from '../ManagerDashboard/SalsaNextAssignment/LeadsToAssignedBySalsa';
import Reshuffle from './Reshuffle/Reshuffle';
import LeadsAssignedBySalsa from '../ManagerDashboard/SalsaNextAssignment/LeadsAssignedBySalsa';
import DisableAccountPage from './DisableAccountPage';
import AdminManualReshuffle from './Reshuffle/AdminManualReshuffle';
import PcLeads from './PcLeads/PcLeads';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AdminDashboard() {
  const [roles, setRoles] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchAllRoles();
    return () => {};
  }, []);

  function fetchAllRoles() {
    axiosInstance
      .get(`/api/roles`)
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not fetch roles.'
        );
      });
  }

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-2.5rem' }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant='scrollable'
        >
          <Tab label='Users' />
          <Tab label='Leads' />
          <Tab label='Monitor' />
          <Tab label='Drives' />
          {/* <Tab label='Import Leads' /> */}
          <Tab label='Holidays' />
          <Tab label='Lead Access Policies' />
          <Tab label='Salsa Next Assignments' />
          <Tab label='Leads Assigned By Salsa' />
          {/* <Tab label='reshuffle' /> */}
          <Tab label='Disable Accounts' />
          <Tab label='Manual Reshuffle' />
          <Tab label='PC Lead' />
        </Tabs>
      </Box>
      <TabPanel
        value={currentTab}
        index={0}
      >
        <PortalUsers
          {...{
            roles,
            setRoles,
            setIsSuccess,
            setHasError,
            setSuccessMessage,
            setErrorMessage,
          }}
        />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
      >
        <TeamOverview {...{ setHasError, setErrorMessage }} />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={2}
      >
        <Monitor {...{ setHasError, setErrorMessage }} />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={3}
      >
        <Drives />
      </TabPanel>
      {/* <TabPanel
        value={currentTab}
        index={4}
      >
        <ImportLeads />
      </TabPanel> */}
      <TabPanel
        value={currentTab}
        index={4}
      >
        <Holidays />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={5}
      >
        <LeadAccessPolicies />
      </TabPanel>

      <TabPanel
        value={currentTab}
        index={6}
      >
        <LeadsToAssignedBySalsa old={false} />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={7}
      >
        <LeadsAssignedBySalsa />
      </TabPanel>
      {/* <TabPanel
        value={currentTab}
        index={9}
      >
        <Reshuffle
          {...{
            setHasError,
            setErrorMessage,
            setIsSuccess,
            setSuccessMessage,
          }}
        />
      </TabPanel> */}
      <TabPanel
        value={currentTab}
        index={8}
      >
        <DisableAccountPage />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={9}
      >
        <AdminManualReshuffle />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={10}
      >
        <PcLeads />
      </TabPanel>
    </Box>
  );
}
