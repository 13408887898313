export const newLeadsColumnsOrder = [
  'fname',
  'lname',
  'email',
  'mobile',
  'whatsapp',
  'lead_source_type',
  'college',
  'branch',
  'graduation_year',
  'company',
  'linkedin_link',
  'github_link',
  'experience',
  'tech_stack',
  'role',
  'current_salary',
  'desired_salary',
  'current_location',
  'total_experience',
  'portfolio_link',
  'internship_experience',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_region',
  'utm_adset',
  'utm_campaign_type',
  'product',
];

export const metaOrLinkedinFixedColumns = [
  'First name',
  'Last name',
  'Email address',
  'Phone number',
  'Job title',
  'Company name',
  'How many Years of work experience?',
  'In which year did you graduated?',
  'Your preferred programming language?',
];

export const metaOrLinkedinFixedColumnsObj = {
  'First name': 'fname',
  'Last name': 'lname',
  'Email address': 'email',
  'Phone number': 'mobile',
  'Job title': 'role',
  'Company name': 'company',
  'How many Years of work experience?': 'experience',
  'In which year did you graduated?': 'graduation_year',
  'Your preferred programming language?': 'tech_stack',
};
