import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAdminsData,
  updateAutoDialerAccess,
  addAdmin,
  updateTelecmiAccess,
  updateAdmin,
} from './adminThunk';

const adminSlice = createSlice({
  name: 'admins',
  initialState: {
    allAdmins: [],
    subordinateAdmins: [],
    adminIdToIndexMap: {},
    loading: false,
    successMessage: null,
    errorMessage: null,
    showRecordingColumn: false,
    showImportBtn: false,
  },
  reducers: {
    setAdminIdToIndexMap(state) {
      state.adminIdToIndexMap = state.allAdmins.reduce((map, admin, index) => {
        map[admin.id] = index;
        return map;
      }, {});
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminsData.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      .addCase(fetchAdminsData.fulfilled, (state, action) => {
        state.loading = false;
        state.allAdmins = action.payload.allAdmins;
        state.showRecordingColumn = action.payload.showRecordingColumn;
        state.subordinateAdmins = action.payload.subordinateAdmins;
        state.showImportBtn = action.payload.showImportBtn;
        adminSlice.caseReducers.setAdminIdToIndexMap(state);
      })
      .addCase(fetchAdminsData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(updateAutoDialerAccess.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(updateAutoDialerAccess.fulfilled, (state, action) => {
        const { adminId, autodialer_access } = action.payload;
        const index = state.adminIdToIndexMap[adminId];
        if (index !== undefined) {
          state.allAdmins[index].autodialer_access = autodialer_access;
        }
        state.loading = false;
        state.successMessage = `Autodialer ${
          autodialer_access ? 'enabled' : 'disabled'
        } successfully...`;
      })
      .addCase(updateAutoDialerAccess.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = `Error updating auto dialer access: ${action.payload}`;
      })
      .addCase(addAdmin.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        const { admin, roles } = action.payload;
        state.allAdmins.push({ ...admin, roles }); // Append the new admin
        state.loading = false;
        state.successMessage = 'Admin successfully added.';
        adminSlice.caseReducers.setAdminIdToIndexMap(state);
      })
      .addCase(addAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(updateTelecmiAccess.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(updateTelecmiAccess.fulfilled, (state, action) => {
        const { adminId, data } = action.payload;
        const index = state.adminIdToIndexMap[adminId];
        if (index === undefined) {
          state.errorMessage = 'Admin not found';
          return;
        }
        state.allAdmins[index].telecmi_access = data.telecmi_access;
        state.loading = false;
        state.successMessage = `Telecmi ${
          data.telecmi_access ? 'enabled' : 'disabled'
        } successfully...`;
      })
      .addCase(updateTelecmiAccess.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.successMessage = null;
        state.errorMessage = null;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        const { adminId, data } = action.payload;
        const index = state.adminIdToIndexMap[adminId];
        if (index === undefined) {
          state.errorMessage = 'Admin not found';
          return;
        }
        state.allAdmins[index] = data;
        state.loading = false;
        state.successMessage = 'Admin successfully updated';
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      });
  },
});

export const { setAdminIdToIndexMap } = adminSlice.actions;
export default adminSlice.reducer;
