import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance'; // Ensure axiosInstance is correctly set up to handle API requests
import React, { useState } from 'react';

const initialState = {
  groupName: '',
};

const AddGroupModal = ({
  openNewAdminModal,
  setOpenNewAdminModal,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) => {
  const [modalData, setModalData] = useState(initialState);
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  const handleInputChange = (field, value) => {
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddClick = async () => {
    if (!modalData.groupName.trim()) {
      setHasModalError(true);
      setModalErrorMessage('Group name is required');
      return;
    }

    try {
      const response = await axiosInstance.post('/api/v1/access-table/groups', {
        data: {
          name: modalData.groupName,
        },
      });

      // Show success message
      setSuccessMessage('Group created successfully');
      setIsSuccess(true);

      console.log('response', response);
      console.log('Group created successfully:', response.data);

      // Clear modal data and close modal
      setModalData(initialState);
      setOpenNewAdminModal(false);
    } catch (error) {
      setHasModalError(true);
      setModalErrorMessage(
        error.response?.data?.message || 'Failed to create group'
      );
      console.error('Error creating group:', error);
    }
  };

  return (
    <Dialog
      open={openNewAdminModal}
      onClose={() => setOpenNewAdminModal(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button onClick={() => setOpenNewAdminModal(false)}>Close</Button>
      </Box>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {hasModalError && (
              <Typography
                variant='body2'
                color='error'
              >
                *{modalErrorMessage}
              </Typography>
            )}
          </Stack>
        </DialogContentText>
        <Box mb={2}>
          <TextField
            label='Group Name'
            fullWidth
            value={modalData.groupName}
            onChange={(e) => handleInputChange('groupName', e.target.value)}
            variant='outlined'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleAddClick}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGroupModal;
