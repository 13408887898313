import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';

const ShowExitAutodialerDialog = ({ open, onClose, onSubmit }) => {
  const [exitReason, setExitReason] = useState('');
  const [details, setDetails] = useState('');
  const [error, setError] = useState(false);

  const handleReasonChange = (event) => {
    setExitReason(event.target.value);
    if (
      event.target.value === 'technical_error' ||
      event.target.value === 'something_urgent'
    ) {
      setError(false);
    } else {
      setDetails('');
    }
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = () => {
    if (
      (exitReason === 'technical_error' || exitReason === 'something_urgent') &&
      !details.trim()
    ) {
      setError(true);
      return;
    }
    setError(false);
    onSubmit(exitReason, details);
    onClose();
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '600px', // Set the desired width
          maxWidth: '80%', // Optional: Make it responsive
        },
      }}
    >
      <DialogTitle>End Autodialer Mode</DialogTitle>
      <DialogContent>
        <RadioGroup
          value={exitReason}
          onChange={handleReasonChange}
        >
          <FormControlLabel
            value='technical_error'
            control={<Radio />}
            label='Technical Error'
          />
          <FormControlLabel
            value='something_urgent'
            control={<Radio />}
            label='Something Urgent Came Up'
          />
          <FormControlLabel
            value='incoming_call_received'
            control={<Radio />}
            label='Incoming Call Received'
          />
        </RadioGroup>
        {(exitReason === 'technical_error' ||
          exitReason === 'something_urgent') && (
          <TextField
            autoFocus
            margin='dense'
            label='Additional Details'
            type='text'
            fullWidth
            value={details}
            onChange={handleDetailsChange}
            error={error}
            helperText={error ? 'Additional Details are required.' : ''}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!exitReason}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowExitAutodialerDialog;
