import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import AddFeatureModal from 'components/accessTable/AddFeatureModal';
import FeatureTable from 'components/accessTable/FeatureTable';
import FullScreenFeatureDailog from 'components/accessTable/FullScreenFeatureDailog';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import React, { useEffect } from 'react';

const AccessTableFeatures = () => {
  const [role, setRole] = React.useState('');
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [openFullScreenDailog, setOpenFullScreenDialog] = React.useState(false);
  const [selectedFeature, setSelectedFeature] = React.useState({});
  useEffect(() => {
    getAllAccessTableFeatures();
    return () => {};
  }, [isSuccess]);

  const getAllAccessTableFeatures = async () => {
    try {
      const res = await axiosInstance.get('api/v1/access-table/features');
      setData(res.data?.data?.data?.features);
    } catch (error) {
      setHasError(true);
      setErrorMessage('Could not fetch features');
      console.log(error);
    }
  };
  return (
    <>
      <Stack>
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}
        <FeatureTable
          data={data}
          setData={setData}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          loading={loading}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setLoading={setLoading}
          setSelectedFeature={setSelectedFeature}
          setOpenFullScreenDialog={setOpenFullScreenDialog}
        />

        {/*feature modal */}
        {openFullScreenDailog && (
          <FullScreenFeatureDailog
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setHasError={setHasError}
            setSuccessMessage={setSuccessMessage}
            setOpenFullScreenDialog={setOpenFullScreenDialog}
            selectedFeature={selectedFeature}
            openFullScreenDailog={openFullScreenDailog}
          />
        )}
      </Stack>
    </>
  );
};

export default AccessTableFeatures;
