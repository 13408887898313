import React, { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import HolidaysTable from './HolidaysTable';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import { fetchAllHolidays } from 'utils/common';

export default function Holidays() {
  const [allHolidays, setAllHolidays] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchAllHolidays(setAllHolidays, setHasError, setErrorMessage);
    return () => {};
  }, []);

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {allHolidays.length > 0 && <HolidaysTable allHolidays={allHolidays} />}
    </Box>
  );
}
