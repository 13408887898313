import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { currentAdminIsHr } from '../../../utils/common';
import { currentAdminIsTa } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { addAdmin } from 'features/admins/adminThunk';

const initialState = {
  fname: '',
  lname: '',
  email: '',
  access_type: 'allowed',
  role_id: '',
  product: 'super30',
};

export default function NewAdminModal({
  openNewAdminModal,
  setOpenNewAdminModal,
  roles,
}) {
  const [modalData, setModalData] = useState(initialState);
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasModalError) {
      setTimeout(() => {
        setHasModalError(false);
      }, 6000);
    }
  }, [hasModalError]);

  const isHr = currentAdminIsHr();
  const isTa = currentAdminIsTa();
  roles =
    isHr || isTa
      ? roles.filter((ele, index) => {
          return (
            ele.name === 'hr' ||
            ele.name === 'talent_acquisition' ||
            ele.name === 'trainee' ||
            ele.name === 'marketing' ||
            ele.name === 'independent_contributor' ||
            ele.name === 'senior_marketing_manager' ||
            ele.name === 'interviewer'
          );
        })
      : roles;

  const handleAddClick = (e) => {
    const emptyFields = [];
    const body = { ...modalData };
    if (body.fname === '') {
      emptyFields.push('first name');
    }
    if (body.lname === '') {
      emptyFields.push('last name');
    }
    if (body.email === '') {
      emptyFields.push('email');
    }
    if (body.role_id === '') {
      emptyFields.push('role');
    }
    if (emptyFields.length > 0) {
      setHasModalError(true);
      setModalErrorMessage(
        `Please set ${emptyFields.join(', ')} for the new admin`
      );
      return;
    }
    dispatch(addAdmin(modalData))
      .unwrap()
      .then(() => {
        setOpenNewAdminModal(false); // Close modal on success
      })
      .catch((err) => {
        console.error('Error adding admin:', err);
      });
  };

  function getAdminRoleDropdown() {
    return (
      <FormControl
        id='role'
        fullWidth
      >
        <InputLabel id='role'>Admin role</InputLabel>
        <Select
          label='Admin role'
          labelId='role'
          value={modalData.role_id}
          name='role_id'
          onChange={(e) =>
            setModalData({ ...modalData, role_id: e.target.value })
          }
        >
          <MenuItem value=''>None</MenuItem>
          {roles.length > 0 &&
            roles.map((role, index) => {
              return (
                <MenuItem
                  value={role.id}
                  key={index}
                >
                  {role.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  function getProducDropdown() {
    return (
      <FormControl fullWidth>
        <InputLabel id='product_label'>Product</InputLabel>
        <Select
          required
          type='text'
          name='product'
          label='product'
          labelId='product_label'
          value={modalData.product}
          onChange={(e) =>
            setModalData({ ...modalData, product: e.target.value })
          }
        >
          <MenuItem value='super30'>Super 30</MenuItem>
          <MenuItem value='personal_coaching'>Personal coaching</MenuItem>
        </Select>
      </FormControl>
    );
  }

  function getAdminTextField(fieldType) {
    return (
      <TextField
        required
        type={fieldType === 'email' ? 'email' : 'text'}
        value={modalData[fieldType]}
        label={
          fieldType === 'email'
            ? 'Enter email'
            : `Enter ${fieldType === 'fname' ? 'first' : 'last'} name`
        }
        onChange={(e) => {
          if (fieldType === 'fname') {
            setModalData({ ...modalData, fname: e.target.value });
          } else if (fieldType === 'lname') {
            setModalData({ ...modalData, lname: e.target.value });
          } else if (fieldType === 'email') {
            setModalData({ ...modalData, email: e.target.value });
          }
        }}
      />
    );
  }

  return (
    <Dialog
      open={openNewAdminModal}
      onClose={(e) => setOpenNewAdminModal(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button onClick={(e) => setOpenNewAdminModal(false)}>Close</Button>
      </Box>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {hasModalError && (
              <Typography
                variant='body2'
                color='error'
              >
                *{modalErrorMessage}
              </Typography>
            )}
            {getAdminTextField('fname')}
            {getAdminTextField('lname')}
            {getAdminTextField('email')}
            {/* {getLeadAccessTypeDropdown()} */}
            {getAdminRoleDropdown()}
            {getProducDropdown()}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleAddClick}
        >
          Add new admin
        </Button>
      </DialogActions>
    </Dialog>
  );
}
