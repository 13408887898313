import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { base_url } from '../components/Mode';
import axiosInstance from 'apis/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { buildQueryStringFiltersToFetchLeads } from '../components/users/GetLeads';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import { icMotivationTexts } from '../utils/common';
import { useSelector } from 'react-redux';

function IcHomePape() {
  const adminData = useSelector((state) => state.auth.currAdmin);

  const navigate = useNavigate();
  const [userActivitiesStatus, setUserActivitiesStatus] = useState([]);
  const [totalFolloeUpLeads, setTotalFolloeUpLeads] = useState(0);
  const [totalLeadTouched, setTotalLeadTouched] = useState(0);
  const [motivationText, setMotivationText] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getActivitiesStatus();
    getFollowUpDue();
    getLeadTouchedEntries();
    getRandomMotivationTexts();
  }, []);

  const getActivitiesStatus = () => {
    const url = base_url() + '/api/users/status-activities/';
    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setUserActivitiesStatus(res['data']);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const getFollowUpDue = () => {
    let queryString = buildQueryStringFiltersToFetchLeads({
      assignee: adminData['id'],
      closed_at: 'null',
      due_now: 'true',
    });

    let url = base_url() + '/api/users';
    url += '?' + queryString;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setTotalFolloeUpLeads(res['data']['total']);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const getLeadTouchedEntries = () => {
    const url = base_url() + '/api/reports/lead-touched';
    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setTotalLeadTouched(res['data']['total']);
        }
      })
      .catch((err) => {
        console.log('error', err);
        setHasError(true);
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const handleIconClick = (text) => {
    let value =
      userActivitiesStatus.length > 0
        ? userActivitiesStatus.filter((e) => e.status == text).length
          ? userActivitiesStatus
              .filter((e) => e.status == text)
              .map((e) => e.lead_id)
          : []
        : [];

    const usersStatus = value.join(',');
    navigate(`/bucket-search`, { state: usersStatus });
  };

  const showCount = (text) => {
    const count =
      userActivitiesStatus.length > 0
        ? userActivitiesStatus.filter((e) => e.status == text).length
        : 0;
    return count;
  };

  const getRandomMotivationTexts = () => {
    const shuffledTexts = icMotivationTexts.sort(() => Math.random() - 0.5);
    setMotivationText(shuffledTexts.slice(0, 3));
  };

  return (
    <div style={{ background: '#F1F7FF', width: '100%', padding: '20px' }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography
            variant='h5'
            display='inline-block'
            component='div'
            gutterBottom
            fontWeight={700}
          >
            {`Hello ${adminData.fname}!`}
          </Typography>
          <Typography fontWeight={400}>{motivationText[0]}</Typography>
        </Box>
        <div style={{ display: 'flex', marginTop: '10px', width: '20%' }}>
          <Avatar
            alt={`${adminData.fname}`}
            src={`${adminData.profile_picture}`}
          />
          <Typography
            fontWeight={600}
            marginTop={1}
            marginLeft={2}
          >
            {adminData?.fullname}
          </Typography>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '10rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#101010' : '#fff',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mt: 1,
              borderRadius: 2,
              borderLeft: 1,
              borderColor: '#FFAA04',
              textAlign: 'left',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography
              variant='h6'
              display='inline-block'
              component='div'
            >
              Motivation for the day!
            </Typography>
            <ul>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>{motivationText[1]}</li>
                </Box>
              </Typography>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>{motivationText[2]}</li>
                </Box>
              </Typography>
            </ul>
          </Box>

          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '10rem',
              mt: 1,
              display: 'grid',
              gap: 1,
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            <Box
              sx={{
                boxShadow: 0,
                width: '95%',
                height: '95%',
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#101010' : '#fff',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                p: 1,
                mt: 0,
                borderRadius: 2,
                borderLeft: 1,
                borderColor: '#1976D2',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
              }}
            >
              <Typography>
                <Box sx={{ fontSize: '2rem', fontWeight: 800 }}>
                  {userActivitiesStatus.length > 0
                    ? userActivitiesStatus.filter(
                        (e) => e.status == 'complete_program_explained'
                      ).length
                    : 0}
                </Box>
              </Typography>
              <Typography>
                <Box
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginLeft: 2,
                  }}
                >
                  Program Details Explained
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                boxShadow: 0,
                width: '95%',
                height: '95%',
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#101010' : '#fff',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                p: 1,
                mt: 0,
                borderRadius: 2,
                borderLeft: 1,
                borderColor: '#1976D2',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
              }}
            >
              <Typography>
                <Box sx={{ fontSize: '2rem', fontWeight: 800 }}>
                  {userActivitiesStatus.length > 0
                    ? userActivitiesStatus.filter(
                        (e) => e.status == 'eligibility_form_filled'
                      ).length
                    : 0}
                </Box>
              </Typography>
              <Typography>
                <Box
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginLeft: 2,
                  }}
                >
                  Eligibility Forms Filled
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                boxShadow: 0,
                width: '95%',
                height: '95%',
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#101010' : '#fff',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                p: 1,
                mt: 0,
                borderRadius: 2,
                borderLeft: 1,
                borderColor: '#1976D2',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
              }}
            >
              <Typography>
                <Box sx={{ fontSize: '2rem', fontWeight: 800 }}>
                  {userActivitiesStatus.length > 0
                    ? userActivitiesStatus.filter(
                        (e) => e.status == 'token_amount_paid'
                      ).length
                    : 0}
                </Box>
              </Typography>
              <Typography>
                <Box
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginLeft: 2,
                  }}
                >
                  Token Amounts Paid
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                boxShadow: 0,
                width: '95%',
                height: '95%',
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#101010' : '#fff',
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                p: 1,
                mt: 0,
                borderRadius: 2,
                borderLeft: 1,
                borderColor: '#1976D2',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
              }}
            >
              <Typography>
                <Box sx={{ fontSize: '2rem', fontWeight: 800 }}>
                  {userActivitiesStatus.length > 0
                    ? userActivitiesStatus.filter(
                        (e) => e.status == 'complete_amount_paid'
                      ).length
                    : 0}
                </Box>
              </Typography>
              <Typography>
                <Box
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginLeft: 2,
                  }}
                >
                  Complete Amounts Paid
                </Box>
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '7rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#101010' : '#fff',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mt: 1,
              borderRadius: 2,
              borderLeft: 1,
              borderColor: '#68499F',
              textAlign: 'left',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography
              variant='h6'
              display='inline-block'
              component='div'
            >
              Today
            </Typography>
            <ul>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>{totalFolloeUpLeads} follow ups scheduled for today.</li>
                </Box>
              </Typography>
              {/* need to further discussion */}
              {/* <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>1 screening call scheduled at 3 pm</li>
                </Box>
              </Typography> */}
            </ul>
          </Box>
          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '7rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#101010' : '#fff',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mt: 1,
              borderRadius: 2,
              borderLeft: 1,
              borderColor: '#FF0000',
              textAlign: 'left',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography
              variant='h6'
              display='inline-block'
              component='div'
            >
              Notifications
            </Typography>
            <ul>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    {totalLeadTouched <= 60 ? 'Low leads touched' : 'On track'}
                  </li>
                </Box>
              </Typography>
            </ul>
          </Box>

          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '10rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#101010' : '#fff',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mt: 1,
              borderRadius: 2,
              borderLeft: 1,
              borderColor: '#06C146',
              textAlign: 'left',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography
              variant='h6'
              display='inline-block'
              component='div'
            >
              Potential APPs
            </Typography>
            <ul>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    Eligibility form sent: {showCount('eligibility_form_sent')}{' '}
                    <OpenInNewIcon
                      onClick={() => handleIconClick('eligibility_form_sent')}
                      style={{
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        marginLeft: '0.5rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </li>
                </Box>
              </Typography>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    Program details explained done:{' '}
                    {showCount('complete_program_explained')}{' '}
                    <OpenInNewIcon
                      onClick={() =>
                        handleIconClick('complete_program_explained')
                      }
                      style={{
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        marginLeft: '0.5rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </li>
                </Box>
              </Typography>
            </ul>
          </Box>
          <Box
            sx={{
              boxShadow: 0,
              width: '95%',
              height: '10rem',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#101010' : '#fff',
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
              p: 1,
              mt: 1,
              borderRadius: 2,
              borderLeft: 1,
              borderColor: '#06C146',
              textAlign: 'left',
              fontSize: '0.875rem',
              fontWeight: '700',
            }}
          >
            <Typography
              variant='h6'
              display='inline-block'
              component='div'
            >
              Potential CAPs
            </Typography>
            <ul>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    Token amount paid: {showCount('token_amount_paid')}{' '}
                    <OpenInNewIcon
                      onClick={() => handleIconClick('token_amount_paid')}
                      style={{
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        marginLeft: '0.5rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </li>
                </Box>
              </Typography>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    Screening attended: {showCount('screening_call_attended')}{' '}
                    <OpenInNewIcon
                      onClick={() => handleIconClick('screening_call_attended')}
                      style={{
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        marginLeft: '0.5rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </li>
                </Box>
              </Typography>
              <Typography component='div'>
                <Box sx={{ fontSize: '0.875rem', m: 1, ml: 2 }}>
                  <li>
                    Eligibility form filled:{' '}
                    {showCount('eligibility_form_filled')}{' '}
                    <OpenInNewIcon
                      onClick={() => handleIconClick('eligibility_form_filled')}
                      style={{
                        fontSize: '1.1rem',
                        cursor: 'pointer',
                        marginLeft: '0.5rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </li>
                </Box>
              </Typography>
            </ul>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default IcHomePape;
