import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'apis/axiosInstance';
import Cookies from 'universal-cookie';
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (tokenId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/api/auth/login`, { tokenId });

      if (!response.data.data) {
        throw new Error('Invalid login response.');
      }
      const cookies = new Cookies();
      const admin = response.data.data.existingLead;
      admin.roles = (admin.roles ?? [{ name: 'INDEPENDENT_CONTRIBUTOR' }]).map(
        (role) => role.name
      );

      admin.subordinate_admin_ids =
        response.data.data.subordinateAdminIds?.length > 0
          ? response.data.data.subordinateAdminIds
          : [admin.id];

      admin.missingReportsFlag = response.data.data.missingReportsFlag;
      delete admin.created_at;
      delete admin.updated_at;
      cookies.set('x-auth-token', response.data.data.tokenId, { path: '/' });

      return {
        token: response.data.data.tokenId, // This will be persisted in Redux
        admin,
      };
    } catch (error) {
      console.error('error', error);
      return rejectWithValue(error.response?.data || 'Login failed');
    }
  }
);
