import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import { useCurrentLead } from '../CurrentLeadContext';
import moment from 'moment';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';

const AutomatedInteraction = ({
  transcriptedRes,
  setShowAutomatedInteraction,
  updateCurrentLeadContexts,
  setTranscriptedRes,
}) => {
  const { currentLead } = useCurrentLead();
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successText, setSuccessText] = React.useState('');
  const handleAccept = async () => {
    const postData = {
      notes: transcriptedRes?.transcript?.trim(),
      lead_id: currentLead.lead_id,
    };
    try {
      const response = await axiosInstance.post(
        base_url() + '/api/users/' + currentLead.lead_id + '/interactions',
        postData
      );
      if (response) {
        await axiosInstance.post(
          base_url() +
            '/api/users/' +
            currentLead.lead_id +
            '/suggested-interaction-action',
          { leadId: currentLead?.lead_id, action: 'accept' }
        );
        setIsSuccess(true);
        setSuccessText('Auto suggested Interaction has been accepted');
        setTranscriptedRes(null);
        updateCurrentLeadContexts();
      }
      setShowAutomatedInteraction(false);
    } catch (error) {
      setHasError(true);
      setErrorText('Error while accepting auto suggested interaction');
      console.error('Error creating interaction:', error);
    }
  };

  const handleReject = async () => {
    setShowAutomatedInteraction(false);
    try {
      await axiosInstance.post(
        base_url() +
          '/api/users/' +
          currentLead.lead_id +
          '/suggested-interaction-action',
        { leadId: currentLead?.lead_id, action: 'reject' }
      );
      setIsSuccess(true);
      setSuccessText('Auto suggested Interaction has been rejected');
      setTranscriptedRes(null);
      updateCurrentLeadContexts();
    } catch (error) {
      setHasError(true);
      setErrorText('Error while rejecting auto suggested interaction');
      console.error('Error rejecting interaction:', error);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorText}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successText}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box
        sx={{
          p: 2,
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f3f3f3',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          mb: 2,
        }}
      >
        <Typography
          variant='h6'
          component='div'
          gutterBottom
          sx={{ color: '#333', mb: 1 }}
        >
          Suggested Interaction
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          sx={{
            whiteSpace: 'pre-wrap',
            mb: 2,
            backgroundColor: '#ffffff',
            color: '#000000',
            padding: 1,
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {transcriptedRes?.transcript ||
            'Please wait, Generating the interaction'}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          sx={{
            mb: 2,
            padding: 1,
            borderRadius: '4px',
            color: '#000000',
          }}
        >
          {'Snooze time: ' +
            (transcriptedRes
              ? moment(transcriptedRes?.snooze_until_1)
                  .subtract(5, 'hours')
                  .subtract(30, 'minutes')
                  .format('MMM DD, YYYY hh:mm A')
              : 'Please wait, Generating the snooze time')}
        </Typography>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
        >
          <Button
            onClick={handleAccept}
            color='primary'
            variant='contained'
            disabled={!transcriptedRes}
            sx={{
              backgroundColor: '#1976d2',
              '&:hover': { backgroundColor: '#1565c0' },
            }}
          >
            Accept
          </Button>
          <Button
            onClick={handleReject}
            color='secondary'
            variant='outlined'
            disabled={!transcriptedRes}
            sx={{
              mr: 2,
              borderColor: '#d32f2f',
              color: '#d32f2f',
              '&:hover': { borderColor: '#b71c1c' },
            }}
          >
            Reject
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AutomatedInteraction;
