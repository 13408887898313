import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import adminsReducer from '../features/admins/adminSlice';
import authReducer from '../features/auth/authSlice';
import leadDropRequestReducer from '../features/leadDropManagerAction/leadDropManagerActionSlice';
import autoDialerReducer from '../features/autoDialer/autoDailerSlice';

// Persist configuration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['admins', 'auth', 'autoDialer'],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_PERSIST_SECRET_KEY || 'fallback-key', // Use env variables for production
      onError: (error) => {
        console.error('Encryption error:', error);
      },
    }),
  ],
};

// Combine reducers
const rootReducer = combineReducers({
  admins: adminsReducer,
  leadDropRequestData: leadDropRequestReducer,
  auth: authReducer,
  autoDialer: autoDialerReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the logger
const logger = createLogger({
  collapsed: true, // Collapse log entries for cleaner output
  diff: true, // Show the difference between old and new state
});

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Ignore non-serializable values in actions
    }).concat(logger), // Add the logger middleware
});

export const persistor = persistStore(store);

export default store;
