import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from '../components/Mode';
import LeadsDataTable from '../components/data-table/LeadsDataTable';
import {
  currentAdminIsTraineeOrIC,
  getDatabaseFormattedDate,
  getOffsetFromPage,
  getTimeZoneFormattedTimestamp,
} from '../utils/common';
import { Box } from '@mui/material';
import { ShowFilterLabels } from '../components/search/Filters';
import {
  GetDataTableWithTitleAndFilters,
  buildQueryStringFiltersToFetchLeads,
} from '../components/users/GetLeads';
import { useNavigate } from 'react-router-dom';
import { getCurrAdmin } from 'features/auth/authSelector';

export function MyOpenLeads() {
  const getDefaultParams = () => {
    return {
      assignee: adminData['id'],
      closed_at: 'null',
    };
  };

  const adminData = getCurrAdmin();
  const [params, setParams] = useState(getDefaultParams());
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [params, currentPage, limit, columnFilters]);

  // Fetches the data
  const fetchData = () => {
    const offset = getOffsetFromPage(currentPage, limit);
    let queryString = buildQueryStringFiltersToFetchLeads(params);
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;

    let url = base_url() + '/api/users';
    url += '?' + queryString;
    if (columnFilters !== '') {
      url += '&columnFilters[]=' + columnFilters;
    }

    axiosInstance.get(url).then((res) => {
      if (res['data']) {
        setTableData(res['data']['leads']);
        setTotalLeads(res['data']['total']);
      }
    });
  };

  const updateParam = (e, param_name) => {
    let paramsClone = Object.assign({}, params);
    switch (param_name) {
      case 'hideSnoozed':
        delete paramsClone['snoozed_until'];

        if (e.target.checked) {
          paramsClone['snoozed'] = 0;
        } else {
          delete paramsClone['snoozed'];
        }
        break;
      case 'showDueNow':
        // Button is disabled
        delete paramsClone['snoozed'];

        if (e.target.checked) {
          paramsClone['snoozed_until'] = getDatabaseFormattedDate(new Date());
        } else {
          delete paramsClone['snoozed_until'];
        }
        break;
      default:
        paramsClone[param_name] = e.target.value;
    }
    setParams(paramsClone);
  };

  const filters = () => {
    return (
      <>
        <FormGroup
          row
          style={{ float: 'right', marginBottom: '22px' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={params?.snoozed === 0}
                value={params?.snoozed === 0}
              />
            }
            label='Hide all snoozed'
            name='hideSnoozed'
            onClick={(e) => updateParam(e, 'hideSnoozed')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={'snoozed_until' in params}
                value={'snoozed_until' in params}
              />
            }
            name='showDueNow'
            label='Show due'
            onClick={(e) => updateParam(e, 'showDueNow')}
          />
        </FormGroup>
      </>
    );
  };

  const getMyLeads = () => {
    return (
      <>
        <Typography
          variant='h5'
          component='div'
          gutterBottom
          marginTop='-3%'
        >
          My Leads ({totalLeads})
        </Typography>
        <Divider />
        <br />
        {totalLeads !== 0 ? (
          <Box>
            <span style={{ visibility: 'hidden' }}>
              {ShowFilterLabels(params)}
            </span>
            {!currentAdminIsTraineeOrIC() && filters()}
          </Box>
        ) : (
          ''
        )}

        <LeadsDataTable
          data={tableData}
          refreshResults={fetchData}
          totalLeads={totalLeads}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={limit}
          setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
          setColumnFilters={(filter) => setColumnFilters(filter)}
          queryParams={params}
        />
      </>
    );
  };

  // ---------------------------
  // Render everything
  // ---------------------------
  return <>{getMyLeads()}</>;
}

export function MyFollowUpsDue() {
  const getMyLeadsDueNowParams = () => {
    const adminData = getCurrAdmin();
    return {
      assignee: adminData['id'],
      closed_at: 'null',
      due_now: 1,
      sort_by: 'is_hot',
    };
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  const getMyLeadsDuein3HParams = () => {
    const adminData = getCurrAdmin();
    return {
      assignee: adminData['id'],
      closed_at: 'null',
      due_in_3h: 1,
      sort_by: 'is_hot',
    };
  };

  const getMyLeadsDueNow = () => {
    const params = getMyLeadsDueNowParams();
    return GetDataTableWithTitleAndFilters('Follow up due', params);
  };

  const getMyLeadsDuein3H = () => {
    const params = getMyLeadsDuein3HParams();
    return GetDataTableWithTitleAndFilters('My leads due', params);
  };

  return (
    <>
      <Typography marginTop='-3%'>{getMyLeadsDueNow()}</Typography>
      <br />
      {!currentAdminIsTraineeOrIC() && (
        <Typography marginTop='-3%'>{getMyLeadsDuein3H()}</Typography>
      )}
    </>
  );
}

export function LeadsExpiringIn3Days() {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, limit, columnFilters]);

  // Fetches the data
  const fetchData = () => {
    const offset = getOffsetFromPage(currentPage, limit);
    let queryString = buildQueryStringFiltersToFetchLeads({});
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;
    queryString += '&expiring_in_days=3'; // hardcoding it to 3 days.

    let url = base_url() + '/api/users';
    url += '?' + queryString;
    if (columnFilters !== '') {
      url += '&columnFilters[]=' + columnFilters;
    }

    axiosInstance
      .get(url)
      .then((res) => {
        setTableData(res['data']['leads']);
        setTotalLeads(res['data']['total']);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Typography
        variant='h5'
        component='div'
        gutterBottom
        marginTop='-3%'
      >
        Leads Expiring in 3 days ({totalLeads})
      </Typography>
      <Divider />
      <br />
      <LeadsDataTable
        data={tableData}
        refreshResults={fetchData}
        totalLeads={totalLeads}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
      />
    </>
  );
}

export function MyExpiredLeads() {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (currentAdminIsTraineeOrIC()) {
      navigate('/leads/priority-list');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, limit, columnFilters]);

  // Fetches the data
  const fetchData = () => {
    const offset = getOffsetFromPage(currentPage, limit);
    let queryString = buildQueryStringFiltersToFetchLeads({});
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;
    queryString += '&expiring_in_days=0'; // hardcoding it to 0 days.

    let url = base_url() + '/api/users';
    url += '?' + queryString;
    if (columnFilters !== '') {
      url += '&columnFilters[]=' + columnFilters;
    }

    axiosInstance
      .get(url)
      .then((res) => {
        setTableData(res['data']['leads']);
        setTotalLeads(res['data']['total']);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Typography
        variant='h5'
        component='div'
        gutterBottom
        marginTop='-3%'
      >
        My Leads Expired ({totalLeads})
      </Typography>
      <Divider />
      <br />
      <LeadsDataTable
        data={tableData}
        refreshResults={fetchData}
        totalLeads={totalLeads}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
      />
    </>
  );
}
