import { Badge, Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState } from 'react';
import { TabPanel } from '../Admins/AdminDashboard';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import UsersTable from './UsersTable';
import ManagerActions from './ManagerActions';
import RealTimeCPETracking from './RealTimeCPETracking';
import ProductivityInsights from './ProductivityInsights/ProductivityInsights';
import Monitor from '../Admins/Monitor/Monitor';
import ManagerPool from './ManagerPool';
import {
  currentAdminIsAdmin,
  currentAdminIsSeniorManager,
} from '../../utils/common';
import LeadsToAssignedBySalsa from './SalsaNextAssignment/LeadsToAssignedBySalsa';
import { useSelector } from 'react-redux';
import LeadsAssignedBySalsa from './SalsaNextAssignment/LeadsAssignedBySalsa';
import TenMinConnectTable from '../../components/users/QuickSearch/10MinConnectTable';
import AudioToText from './AudioToText/AudioToText';
import SetAdminClass from './SalsaNextAssignment/SetAdminClass';

function ManagerDashboard() {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  const { data } = useSelector((state) => state.leadDropRequestData);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const getVisibleTabs = () => {
    const tabs = [
      { label: 'Lead Pool', component: <ManagerPool />, visible: true },
      {
        label: (
          <div
            style={{
              width: '100px',
              position: 'relative',
              display: 'inline-block',
            }}
          >
            <Badge
              color='error'
              max={9}
              badgeContent={data.length >= 20 ? '9+' : 0}
              invisible={data.length < 20}
              style={{ position: 'absolute', top: 7, right: 2 }}
            />
            Actions
          </div>
        ),
        component: <ManagerActions />,
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: 'Users',
        component: (
          <UsersTable
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setHasError={setHasError}
          />
        ),
        visible: true,
      },
      {
        label: 'Activity',
        component: <Monitor {...{ setHasError, setErrorMessage }} />,
        visible: currentAdminIsAdmin(),
      },
      // { label: 'Team Overview', component: <ManagerTeamOverview {...{ setHasError, setErrorMessage, setIsSuccess, setSuccessMessage }} />, visible: false },
      // { label: 'Individual report', component: <ManagerIndividualMemberOverview {...{ setHasError, setErrorMessage }} />, visible: false },
      {
        label: 'Real Time CPE Tracking',
        component: <RealTimeCPETracking />,
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: 'Performance Funnel',
        component: (
          <ProductivityInsights
            {...{
              setSuccessMessage,
              setErrorMessage,
              setIsSuccess,
              setHasError,
            }}
          />
        ),
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: 'Leads Assigned By Salsa',
        component: <LeadsAssignedBySalsa old={false} />,
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: '10 Min Connect Leads',
        component: <TenMinConnectTable />,
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: 'Pitch quality',
        component: (
          <AudioToText
            {...{
              setSuccessMessage,
              setErrorMessage,
              setIsSuccess,
              setHasError,
            }}
          />
        ),
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
      {
        label: 'Set Admin Class',
        component: <SetAdminClass />,
        visible: currentAdminIsSeniorManager(),
      },
      {
        label: 'Salsa Next Assignments',
        component: <LeadsToAssignedBySalsa />,
        visible: currentAdminIsSeniorManager() || currentAdminIsAdmin(),
      },
    ];

    return tabs.filter((tab) => tab.visible);
  };

  const visibleTabs = getVisibleTabs();

  return (
    <Box sx={{ mr: 5 }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-2.5rem' }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {visibleTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      {visibleTabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={currentTab}
          index={index}
        >
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
}

export default ManagerDashboard;
