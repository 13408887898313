import PostSalesLeadModal from 'components/Conversions/PostSalesDashboard/PostSalesLeadModal/PostSalesLeadModal';

const DetailedPostSalesLeadHandler = ({
  openDetailedPostSalesLead,
  leadData,
  handleDetailedModalClose,
  shouldShowPostSalesInSmallScreen,
}) => (
  <>
    {openDetailedPostSalesLead && leadData && (
      <PostSalesLeadModal
        {...{
          openDetailedPostSalesLead,
          handleDetailedModalClose,
          shouldShowPostSalesInSmallScreen,
        }}
      />
    )}
  </>
);

export default DetailedPostSalesLeadHandler;
