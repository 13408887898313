import React from 'react';
import InteractionFileUploads from '../InteractionFileUploads/InteractionFileUploads';

const FileUploadsHandler = ({
  openFileUploads,
  setOpenFileUploads,
  uploadedFiles,
  setUploadedFiles,
}) => (
  <>
    {openFileUploads && (
      <InteractionFileUploads
        {...{
          openFileUploads,
          setOpenFileUploads,
          uploadedFiles,
          setUploadedFiles,
        }}
      />
    )}
  </>
);

export default FileUploadsHandler;
