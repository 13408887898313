import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SignupLeadsModal from './SignupLeadsModal';
import { metaOrLinkedinFixedColumnsObj } from '../../../utils/importLeadsColumnFormat';
import constants from '../../../utils/constants';
const { YEAR_2024, GRADUATION_YEAR, MOBILE, EXPERIENCE, FORMATED_CSV } =
  constants;

export default function DisplayLeadsModal({
  csvToJSON,
  csvData,
  importedColumns,
  importedFileType,
  resetFilters,
  leadsData,
  setLeadsData,
  utmCampaignType,
  setHasError,
  setErrorMessage,
}) {
  const [openSignupLeadModal, setOpenSignupLeadModal] = useState(false);

  useEffect(() => {
    if (importedFileType === FORMATED_CSV) {
      setLeadsData(csvToJSON(csvData));
    } else {
      setOpenSignupLeadModal(true);
    }
  }, []);

  const metaOrLinkedinCsvToJSON = (bodyForSignups) => {
    const result = [];
    if (bodyForSignups.utm_campaign_type !== utmCampaignType) {
      setHasError(true);
      setErrorMessage(
        `'utm_campaign_type' field is mandatory. The accepted value is: ${utmCampaignType}.`
      );
      resetFilters();
      return;
    }
    /**
     * Reasoning for `-2`:
     * 1. First `-1`: Indexing in csv files will start from 1, and after importing, the indexing will start from 0.
     * 2. second `-1`: First row always contains the column names in the csv file.
     */
    const from = 0;
    const to = csvData.length;

    for (let i = from; i < to; i += 1) {
      const jsonData = {};

      for (let j = 0; j < importedColumns.length; j++) {
        if ((csvData[i][j] ?? '') === '') {
          continue;
        }
        const columnName = importedColumns[j];

        if (metaOrLinkedinFixedColumnsObj[columnName]) {
          if (
            metaOrLinkedinFixedColumnsObj[columnName] === GRADUATION_YEAR &&
            isNaN(Number(csvData[i][j]))
          ) {
            jsonData[metaOrLinkedinFixedColumnsObj[columnName]] = YEAR_2024;
            continue;
          }
          if (metaOrLinkedinFixedColumnsObj[columnName] === MOBILE) {
            jsonData[metaOrLinkedinFixedColumnsObj[columnName]] =
              cleanPhoneNumber(csvData[i][j]);
            continue;
          }

          if (metaOrLinkedinFixedColumnsObj[columnName] === EXPERIENCE) {
            jsonData[metaOrLinkedinFixedColumnsObj[columnName]] =
              checkExperienceType(csvData[i][j]);
            continue;
          }

          jsonData[metaOrLinkedinFixedColumnsObj[columnName]] = csvData[i][j];
        }
      }

      if (Object.keys(jsonData).length > 0) {
        result.push(jsonData);
      }
    }

    if (Object.keys(bodyForSignups).length > 0) {
      result.forEach((e, i) => {
        result[i] = { ...e, ...bodyForSignups };
      });
    }

    return result;
  };

  const checkExperienceType = (str) => {
    let yearsOfExperience = 0;

    const match = str.match(
      /(\d+(?:\.\d+)?)?\s*(y(?:ea)?r?s?|yrs?|yers?)?\s*(\d+)?\s*(m(?:o(?:nth)?)?s?|mths?)?/i
    );

    const collegeRegex =
      /(pursuing|student|semester|college|university|\d+nd|\d+th|\d+st|\d+rd\s*year)/i;

    if (collegeRegex.test(str)) {
      return 'student';
    }

    if (!match || (!match[1] && !match[3])) {
      return str;
    }

    let years = 0;
    let months = 0;
    if (match[2]) {
      years = match[1] ? parseFloat(match[1]) : 0;
      months = match[3] ? parseInt(match[3]) : 0;
    } else if (match[4]) {
      months = match[1] ? parseInt(match[1]) : 0;
    } else {
      years = match[1] ? parseFloat(match[1]) : 0;
    }

    yearsOfExperience = years + months / 12;

    if (yearsOfExperience === 0) {
      return 'graduated';
    } else if (yearsOfExperience > 0 && yearsOfExperience <= 2) {
      return 'working_professional_0_2_exp';
    } else if (yearsOfExperience > 2 && yearsOfExperience <= 5) {
      return 'working_professional_2_5_exp';
    } else if (yearsOfExperience > 5) {
      return 'working_professional_5_plus_exp';
    } else {
      return str;
    }
  };

  const cleanPhoneNumber = (phoneNumber) => {
    let cleanedNumber = phoneNumber.replace('+91', '');
    cleanedNumber = cleanedNumber.replace(/\s+/g, '');
    return cleanedNumber;
  };

  const columns = [
    {
      name: 'Utm Campaign Type',
      selector: (row) => row?.utm_campaign_type ?? '--',
      maxWidth: '300px',
    },
    {
      name: 'First name',
      selector: (row) => row?.fname ?? '--',
      maxWidth: '200px',
    },
    {
      name: 'Last name',
      selector: (row) => row?.lname ?? '--',
      width: '200px',
      maxWidth: '200px',
    },
    {
      name: 'Mobile',
      selector: (row) => row?.mobile ?? '--',
      width: '200px',
    },
    {
      name: 'Email',
      selector: (row) => row?.email ?? '--',
      width: '200px',
    },
    {
      name: 'Whatsapp',
      selector: (row) => row?.whatsapp ?? '--',
      width: '200px',
    },
    {
      name: 'Lead type source',
      selector: (row) => row?.lead_source_type ?? '--',
      width: '200px',
    },
    {
      name: 'College',
      selector: (row) => row?.college ?? '--',
      width: '200px',
    },
    {
      name: 'Branch',
      selector: (row) => row?.branch ?? '--',
      width: '200px',
    },
    {
      name: 'Graduation year',
      selector: (row) => row?.graduation_year ?? '--',
      width: '200px',
    },
    {
      name: 'Company',
      selector: (row) => row?.company ?? '--',
      width: '200px',
    },
    {
      name: 'Linkedin link',
      selector: (row) => row?.linkedin_link ?? '--',
      width: '200px',
    },
    {
      name: 'Github link',
      selector: (row) => row?.github_link ?? '--',
      width: '200px',
    },
    {
      name: 'Experience',
      selector: (row) => row?.experience ?? '--',
      width: '200px',
    },
    {
      name: 'Role',
      selector: (row) => row?.role ?? '--',
      width: '200px',
    },
    {
      name: 'Current salary',
      selector: (row) => row?.current_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Desired salary',
      selector: (row) => row?.desired_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Current location',
      selector: (row) => row?.current_location ?? '--',
      width: '200px',
    },
    {
      name: 'Total experience',
      selector: (row) => row?.total_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Portfolio link',
      selector: (row) => row?.portfolio_link ?? '--',
      width: '200px',
    },
    {
      name: 'Internship experience',
      selector: (row) => row?.internship_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Utm source',
      selector: (row) => row?.utm_source ?? '--',
      width: '200px',
    },
    {
      name: 'Utm campaign',
      selector: (row) => row?.utm_campaign ?? '--',
      width: '200px',
    },
    {
      name: 'Utm medium',
      selector: (row) => row?.utm_medium ?? '--',
      width: '200px',
    },
    {
      name: 'Utm term',
      selector: (row) => row?.utm_term ?? '--',
      width: '200px',
    },
    {
      name: 'Utm content',
      selector: (row) => row?.utm_content ?? '--',
      width: '200px',
    },
    {
      name: 'Utm region',
      selector: (row) => row?.utm_region ?? '--',
      width: '200px',
    },
    {
      name: 'Utm adset',
      selector: (row) => row?.utm_adset ?? '--',
      width: '200px',
    },
    {
      name: 'Product',
      selector: (row) => row?.product ?? '--',
      width: '200px',
    },
  ];

  const customStyles = {
    table: {
      style: {
        border: '1px solid #ddd',
      },
    },
    headCells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    pagination: {
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        {openSignupLeadModal && (
          <SignupLeadsModal
            open={openSignupLeadModal}
            handleClose={setOpenSignupLeadModal}
            setLeadsData={setLeadsData}
            metaOrLinkedinCsvToJSON={metaOrLinkedinCsvToJSON}
            resetFilters={resetFilters}
            utmCampaignType={utmCampaignType}
          />
        )}
        {leadsData.length > 0 && (
          <DataTable
            customStyles={customStyles}
            highlightOnHover={true}
            columns={columns}
            data={leadsData}
            dense
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            paginationComponentOptions={{
              rowsPerPageText: 'Rows per page:',
              rangeSeparatorText: 'of',
            }}
          />
        )}
      </Box>
    </>
  );
}
