import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import AdminMultiComplete from 'components/admins/AdminMultiComplete';

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const initialState = {
  admin_id: [],
  role: '',
};

const AddAdminModal = ({
  openAddPopup,
  setOpenAddPopup,
  setSuccessMessage,
  setErrorMessage,
  setIsSuccess,
  setHasError,
}) => {
  const selectAllAdmins = (state) => state.admins.allAdmins;
  const useAllAdmins = () => {
    return useSelector(selectAllAdmins);
  };
  const [modalData, setModalData] = useState(initialState);
  const [hasModalError, setHasModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const { allAdmins } = useAllAdmins();
  // Handler for input changes
  const handleInputChange = (field, value) => {
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddClick = async () => {
    const emptyFields = [];
    const data = [];

    if (modalData.admin_id.length === 0) {
      emptyFields.push('email');
    }
    if (modalData.role === '') {
      emptyFields.push('role');
    }
    if (emptyFields.length > 0) {
      setHasModalError(true);
      setModalErrorMessage(
        `Please set ${emptyFields.join(', ')} for the new admin`
      );
      return;
    }
    const body = {};

    for (let i = 0; i < modalData.admin_id.length; i++) {
      data.push({
        admin_id: modalData.admin_id[i],
        role: modalData.role,
      });
    }

    body.data = data;
    try {
      const res = await axiosInstance.post(`/api/v1/access-table/users`, body);
      setOpenAddPopup(false);
      const { createdUsers, alreadyCreatedUsers } = res?.data?.data?.data;

      if (createdUsers.length > 0 && alreadyCreatedUsers.length === 0) {
        setSuccessMessage(res.data.message);
        setIsSuccess(true);
      } else if (alreadyCreatedUsers.length > 0 && createdUsers.length > 0) {
        for (let i = 0; i < alreadyCreatedUsers.length; i++) {
          const admin = allAdmins.find(
            (admin) => admin.id === alreadyCreatedUsers[i].admin_id
          );

          if (admin) {
            alreadyCreatedUsers[i].admin_name = `${admin.fname} ${admin.lname}`;
          }
        }

        const alreadyExistingAdmins = alreadyCreatedUsers
          .map((admin) => admin.admin_name)
          .join(', ');
        setErrorMessage(
          `some user ${alreadyExistingAdmins} already exists apart from those got created.`
        );
        setIsSuccess(false);
        setHasError(true);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(
        error.response?.data?.message || 'Could not add the admin.'
      );
    }
  };

  return (
    <Dialog
      open={openAddPopup}
      onClose={() => setOpenAddPopup(false)}
      fullWidth
      maxWidth='sm'
    >
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <Button onClick={() => setOpenAddPopup(false)}>Close</Button>
      </Box>
      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {hasModalError && (
              <Typography
                variant='body2'
                color='error'
              >
                *{modalErrorMessage}
              </Typography>
            )}
          </Stack>
        </DialogContentText>
        {/* Form Fields */}
        <Box mb={2}>
          <Box mb={1}>
            <AdminMultiComplete
              label='Admin'
              value={modalData.admin_id}
              onChangeHandler={(selectedAdmins) =>
                handleInputChange('admin_id', selectedAdmins)
              }
              sx={{
                width: '100%',
              }}
              size='medium'
            />
          </Box>
          <Box mb={1}>
            <FormControl fullWidth>
              <InputLabel id='role'>Admin role</InputLabel>
              <Select
                label='Admin role'
                labelId='role'
                value={modalData.role}
                onChange={(e) => handleInputChange('role', e.target.value)}
              >
                <MenuItem value=''>None</MenuItem>
                <MenuItem value='admin'>Admin</MenuItem>
                <MenuItem value='users'>User</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleAddClick}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAdminModal;
