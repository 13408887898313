import React, { useState } from 'react';
import { TabPanel } from '../Admins/AdminDashboard';
import {
  currentAdminIsManagerOrAdmin,
  currentAdminIsHr,
  currentAdminIsTa,
  currentAdminIsAdmin,
  currentAdminIsManager,
  currentAdminIsIC,
  currentAdminIsSeniorManager,
} from '../../utils/common';
import ReportView from './ReportInsights/ReportView';
import { Box, Tabs, Tab } from '@mui/material';
import WorkLogCalendarView from './WorkLog/WorkLogCalendarView';
import TeamWorkLog from './WorkLog/TeamWorkLog';
import AppReportsInsights from './ReportInsights/AppReportsInsights';

function Reports() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const getVisibleTabs = () => {
    const tabs = [
      {
        label: 'Reports',
        component: <ReportView />,
        visible: !currentAdminIsHr() && !currentAdminIsTa(),
      },
      {
        label: 'Work log',
        component: <WorkLogCalendarView />,
        visible: currentAdminIsAdmin(),
      },
      {
        label: 'Team work logs',
        component: <TeamWorkLog />,
        visible:
          currentAdminIsSeniorManager() ||
          currentAdminIsAdmin() ||
          currentAdminIsHr() ||
          currentAdminIsTa(),
      },
      {
        label: 'App reports',
        component: <AppReportsInsights />,
        visible: currentAdminIsAdmin(),
      },
    ];
    return tabs.filter((tab) => tab.visible);
  };

  const visibleTabs = getVisibleTabs();

  return (
    <>
      <Box sx={{ mr: 5, mt: 4 }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '-3rem' }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {visibleTabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
              />
            ))}
          </Tabs>
        </Box>
        {visibleTabs.map((tab, index) => (
          <TabPanel
            key={index}
            value={currentTab}
            index={index}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}

export default Reports;
