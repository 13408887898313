import React, { useState } from 'react';
import { Typography, Stack, Button, TextField, Box } from '@mui/material';
import { base_url } from '../../../components/Mode';
import CustomBackdrop from '../../CustomBackdrop';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import axiosInstance from 'apis/axiosInstance';

const PcLeads = () => {
  const [leadIds, setLeadIds] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [nonPcConvertedLeads, setNonPcConvertedLeads] = useState([]);

  const handlePcLeads = () => {
    const payload = { ids: leadIds };

    setLoading(true);
    axiosInstance
      .post(`${base_url()}/api/pc-leads`, payload)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Leads updated successfully');
        setNonPcConvertedLeads(res?.data?.failedLeads);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ??
            'An error occurred while converting super30 to PC leads.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const copyLeadsToClipboard = () => {
    if (nonPcConvertedLeads.length > 0) {
      const leadsString = nonPcConvertedLeads.join(', ');
      navigator.clipboard
        .writeText(leadsString)
        .then(() => {
          setIsSuccess(true);
          setSuccessMessage('Lead IDs copied to clipboard!');
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Failed to copy lead IDs.');
        });
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'visible',
          alignSelf: 'center',
        }}
      >
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}

        <CustomBackdrop open={loading} />

        <Typography
          variant='h4'
          gutterBottom
        >
          PC Leads
        </Typography>
        <Stack
          spacing={1}
          left={1}
        >
          <TextField
            id='outlined-basic'
            label='Enter comma separated lead IDs here'
            variant='outlined'
            value={leadIds}
            onChange={(e) => setLeadIds(e.currentTarget.value)}
            multiline
            minRows={4}
            maxRows={6}
          />
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              onClick={handlePcLeads}
              variant='contained'
              sx={{
                width: '15rem',
                marginRight: '10px',
              }}
              disabled={leadIds.length === 0}
            >
              Update
            </Button>
            <Box>
              <Button
                onClick={copyLeadsToClipboard}
                variant='outlined'
                sx={{
                  width: '15rem',
                }}
                disabled={!(nonPcConvertedLeads.length > 0)}
              >
                Copy Failed Leads
              </Button>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default PcLeads;
