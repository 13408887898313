import { Box, Button, Stack } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import AddGroupModal from 'components/accessTable/AddGroupModal';
import FullScreenGroupDailog from 'components/accessTable/FullScreenGroupDailog';
import GroupTable from 'components/accessTable/GroupTable';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';

const AccessTableGroups = () => {
  const [data, setData] = React.useState([]);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openFullScreenDailog, setOpenFullScreenDialog] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState({});

  useEffect(() => {
    getAllAccessTableGroups();
    return () => {};
  }, [openAddPopup, isSuccess]);

  const getAllAccessTableGroups = async () => {
    try {
      const res = await axiosInstance.get('api/v1/access-table/groups');
      setData(res?.data?.data?.data.groups);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Stack>
        {hasError && (
          <ErrorNotifier {...{ message: errorMessage, setHasError }} />
        )}
        {isSuccess && (
          <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setOpenAddPopup(true)}
          >
            Add Groups
          </Button>
        </Box>
        {/* modal */}
        {openAddPopup && (
          <AddGroupModal
            openNewAdminModal={openAddPopup}
            setOpenNewAdminModal={setOpenAddPopup}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setHasError={setHasError}
          />
        )}

        {/* Table */}
        <GroupTable
          data={data}
          setData={setData}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          loading={loading}
          setHasError={setHasError}
          setLoading={setLoading}
          setSelectedGroup={setSelectedGroup}
          setOpenFullScreenDialog={setOpenFullScreenDialog}
        />

        {/*user modal */}
        {openFullScreenDailog && (
          <FullScreenGroupDailog
            setOpenFullScreenDialog={setOpenFullScreenDialog}
            selectedGroup={selectedGroup}
            openFullScreenDailog={openFullScreenDailog}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
            setHasError={setHasError}
          />
        )}
      </Stack>
    </>
  );
};

export default AccessTableGroups;
