import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import AddIcon from '@mui/icons-material/Add';
import CommonModal from 'components/common/commonModal';
import { CircularProgress, TextField } from '@mui/material';
const FullScreenGroupDailog = ({
  selectedGroup,
  setOpenFullScreenDialog,
  openFullScreenDailog,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
}) => {
  const [userData, setUserData] = React.useState({});
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState([]);
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);
  const [inactiveUsers, setInactiveUsers] = React.useState([]);
  const [openAddFeatureModal, setOpenAddFeatureModal] = React.useState(false);
  const [inactiveFeatures, setInactiveFeatures] = React.useState([]);
  const [selectedFeatures, setSelectedFeatures] = React.useState([]);
  const [groupData, setGroupData] = React.useState({});
  const [selectedUserForUpdate, setSelectedUserForUpdate] = React.useState([]);
  const [selectedFeatureForUpdate, setSelectedFeatureForUpdate] =
    React.useState([]);

  const [editedGroupName, setEditedGroupName] = React.useState(
    selectedGroup?.group_name || ''
  );
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] =
    React.useState(false);

  const handleNameChange = (event) => {
    setEditedGroupName(event.target.value);
  };

  const handleUpdateGroupName = async () => {
    if (editedGroupName !== selectedGroup.group_name) {
      setIsUpdating(true);
      try {
        const response = await axiosInstance.patch(
          `/api/v1/access-table/${selectedGroup.group_id}/groups`,
          { data: { name: editedGroupName } }
        );

        if (response.status === 200) {
          setIsSuccess(true);
          setSuccessMessage('Group name updated successfully');

          // Update groupData to ensure UI reflects changes
          setGroupData((prevData) => ({
            ...prevData,
            group_name: editedGroupName,
          }));

          // Optionally update the selectedGroup
          selectedGroup.group_name = editedGroupName;
        }
      } catch (error) {
        console.error('Failed to update group name:', error);
        setHasError(true);
        setErrorMessage('Error updating group name');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  React.useEffect(() => {
    if (selectedGroup?.group_id) {
      getFeatureAndUsersById(selectedGroup?.group_id);
    }
  }, [selectedGroup?.group_id]);

  const getFeatureAndUsersById = async (id) => {
    const response = await axiosInstance.get(
      `/api/v1/access-table/${id}/groups`
    );
    setGroupData(response?.data?.data?.data);
    setSelectedUserForUpdate(
      response?.data?.data?.data?.group_details?.group_users || []
    );
    setSelectedFeatureForUpdate(
      response?.data?.data?.data?.group_details?.group_features || []
    );
  };
  const allFeatures = groupData?.group_details?.group_features;
  const allUsers = groupData?.group_details?.group_users;
  const handleUpdate = async () => {
    try {
      // Identify removed and active users
      const removedUsers = allUsers
        .filter(
          (originalUser) =>
            !selectedUserForUpdate.some(
              (selectedUser) => selectedUser.userId === originalUser.userId
            )
        )
        .map((user) => ({
          user_id: user.userId,
          status: 'inactive',
        }));

      // Identify removed and active features
      const removedFeatures = allFeatures
        .filter(
          (originalFeature) =>
            !selectedFeatureForUpdate.some(
              (selectedFeature) =>
                selectedFeature.feature_id === originalFeature.feature_id
            )
        )
        .map((feature) => ({
          feature_id: feature.feature_id,
          status: 'inactive',
        }));

      // Construct payload
      const data = {};
      if (removedFeatures.length > 0) {
        data.features = [...removedFeatures];
      }
      if (removedUsers.length > 0) {
        data.users = [...removedUsers];
      }

      // Make API call even if there are no updates, but send an empty body
      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedGroup?.group_id}/groups`,
        { data }
      );
      if (response.data.code === 200) {
        setIsSuccess(true);
        setSuccessMessage('Groups updated successfully');
        setIsSuccessSnackbarOpen(true);
      }
      handleClose();
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error while updating access');
      console.error('Error updating user or group:', error);
    }
  };

  const handleClose = () => {
    setOpenFullScreenDialog(false);
  };

  const handleFeatureToggle = (feature) => {
    setSelectedFeatureForUpdate((prev) => {
      const exists = prev.some(
        (selected) => selected.feature_id === feature.feature_id
      );
      if (exists) {
        return prev.filter(
          (selected) => selected.feature_id !== feature.feature_id
        );
      } else {
        return [...prev, feature];
      }
    });
  };

  const handleUserToggle = (user) => {
    setSelectedUserForUpdate((prev) => {
      const exists = prev.some(
        (selectedUser) => selectedUser.userId === user.userId
      );
      if (exists) {
        // Remove the user from selectedUserForUpdate
        return prev.filter(
          (selectedUser) => selectedUser.userId !== user.userId
        );
      } else {
        // Add the user to selectedUserForUpdate
        return [...prev, user];
      }
    });
  };

  const handleOpenAddFeatureModal = () => {
    setOpenAddFeatureModal(true);
  };

  const handleCloseAddFeatureModal = () => {
    setOpenAddFeatureModal(false);
  };

  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  // Inside modal functionality
  React.useEffect(() => {
    if (openAddFeatureModal) {
      fetchActiveFeatures();
    }
    if (openAddUserModal) {
      fetchActiveUsers();
    }
  }, [openAddUserModal, openAddFeatureModal]);

  const fetchActiveFeatures = async () => {
    try {
      const groupFeatures = groupData.group_details.group_features || [];
      const allFeatures = groupData.users_and_features.features || [];

      const inactiveFeatures = allFeatures.filter(
        (feature) =>
          !groupFeatures.some(
            (groupFeature) => groupFeature.feature_name === feature.feature_name
          )
      );

      setInactiveFeatures(inactiveFeatures);
    } catch (error) {
      console.error('Error fetching inactive Features:', error);
    }
  };

  const fetchActiveUsers = async () => {
    try {
      const groupUsers = groupData.group_details.group_users || [];
      const allUsers = groupData.users_and_features.users || [];
      const inactiveUsers = allUsers.filter(
        (user) =>
          !groupUsers.some((groupUser) => groupUser.userId === user.user_id)
      );

      setInactiveUsers(inactiveUsers);
    } catch (error) {
      console.error('Error fetching inactive users:', error);
    }
  };
  const handleUserFeatureToggle = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };
  const handleGroupFeatureToggle = (userId) => {
    setSelectedFeatures((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleAddUser = async () => {
    try {
      const usersPayload = selectedUsers.map((user_id) => ({
        user_id,
        status: 'active',
      }));

      const payload = {
        data: {
          users: usersPayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedGroup?.group_id}/groups`,
        payload
      );

      if (response.data.code === 200) {
        if (usersPayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('Groups Users updated successfully');
        }
        fetchActiveUsers();
        await getFeatureAndUsersById(selectedGroup?.group_id);
        handleCloseAddUserModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const handleAddFeature = async () => {
    try {
      const featurePayload = selectedFeatures.map((feature_id) => ({
        feature_id,
        status: 'active',
      }));

      const payload = {
        data: {
          features: featurePayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedGroup?.group_id}/groups`,
        payload
      );

      if (response.data.code === 200) {
        if (featurePayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('Groups Features updated successfully');
        }
        fetchActiveFeatures();
        await getFeatureAndUsersById(selectedGroup?.group_id);
        handleCloseAddFeatureModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: 2,
    border: '1px solid #ddd',
  };

  return (
    <React.Fragment>
      <Modal
        open={openFullScreenDailog}
        onClose={handleClose}
        aria-labelledby='user-modal-title'
        aria-describedby='user-modal-description'
      >
        <Box sx={modalStyle}>
          <Box align='right'>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              mx: 3,
            }}
          >
            <TextField
              id='editable-group-name'
              variant='standard'
              value={editedGroupName}
              onChange={handleNameChange}
              onBlur={handleUpdateGroupName}
              size='small'
              fullWidth
            />
          </Box>

          <Container sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography>Status: {selectedGroup?.group_status}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              {/* Features Section */}
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Features
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddFeatureModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>{' '}
                {allFeatures?.map((feature) => (
                  <Box
                    key={feature.feature_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {feature.feature_name}
                    </Typography>
                    <Switch
                      checked={selectedFeatureForUpdate.some(
                        (selectedFeature) =>
                          selectedFeature.feature_id === feature.feature_id
                      )}
                      onChange={() => handleFeatureToggle(feature)}
                    />
                  </Box>
                ))}
              </Box>

              <CommonModal
                open={openAddFeatureModal}
                onClose={handleCloseAddFeatureModal}
                title='Add Features'
                onAction={handleAddFeature}
                actionText='Add Features'
              >
                <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {inactiveFeatures.length === 0 ? (
                    <Typography>No Active Features Found</Typography>
                  ) : (
                    inactiveFeatures.map((feature) => (
                      <Box
                        key={feature.feature_id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                          p: 1,
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <Typography sx={{ flex: 1 }}>
                          {feature.feature_name}
                        </Typography>
                        <Switch
                          checked={selectedFeatures.includes(
                            feature.feature_id
                          )}
                          onChange={() =>
                            handleGroupFeatureToggle(feature.feature_id)
                          }
                          color='primary'
                        />
                      </Box>
                    ))
                  )}
                </Box>
              </CommonModal>

              {/* Users Section */}
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Users
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddUserModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                {allUsers?.map((user) => (
                  <Box
                    key={user.userId}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {user.fullName}
                    </Typography>
                    <Switch
                      checked={selectedUserForUpdate.some(
                        (groupUser) => groupUser.userId === user.userId
                      )}
                      onChange={() => handleUserToggle(user)}
                      color='primary'
                    />
                  </Box>
                ))}
              </Box>
              <CommonModal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                title='Add Users'
                onAction={handleAddUser}
                actionText='Add Users'
              >
                <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {inactiveUsers.length === 0 ? (
                    <Typography>No Active Users Found</Typography>
                  ) : (
                    inactiveUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                          p: 1,
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <Typography sx={{ flex: 1 }}>{user.name}</Typography>
                        <Switch
                          checked={selectedUsers.includes(user.user_id)}
                          onChange={() => handleUserFeatureToggle(user.user_id)}
                          color='primary'
                        />
                      </Box>
                    ))
                  )}
                </Box>
              </CommonModal>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Button
                variant='contained'
                sx={{ width: '100%' }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Box>
          </Container>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default FullScreenGroupDailog;
