import axiosInstance from 'apis/axiosInstance';
import moment from 'moment';
import constants from 'utils/constants';
import store from '../../../redux/store.js';
import { initiateCallThunk } from 'features/autoDialer/autoDailerThunk.js';
import {
  resetTimer,
  setLastHangUp,
  timeTicker,
  updateInteractionBox,
  updateTimer,
} from 'features/autoDialer/autoDailerSlice.js';
const { NOT_CALLED, INITITATED, MISSED, HANGUP, ANSWERED } = constants;

const getStateValues = () => {
  const state = store.getState();
  const { autoDialerLimitTimer, isMannualIneractionMarked } = state.autoDialer;
  console.log('state >>>>>>>>>>>>>>>>>>>> ', state);
  return { autoDialerLimitTimer, isMannualIneractionMarked };
};

const dispatchAction = (action) => {
  store.dispatch(action);
};

let isCallHangUp = false;
const time = 180;
export const handleTimer = (response) => {
  const { autoDialerLimitTimer, isMannualIneractionMarked } = getStateValues();
  dispatchAction(timeTicker());
  if (isCallHangUp) {
    if (autoDialerLimitTimer > 0) {
      setTimeout(() => {
        handleTimer(response);
      }, 1000);
    } else if (autoDialerLimitTimer <= 0) {
      dispatchAction(setLastHangUp(null));
      dispatchAction(updateInteractionBox(false));
      isCallHangUp = false;
      if (isMannualIneractionMarked) {
        response({ action: 'move_next_lead', dnp_admin: 0, dnp_lead: 0 });
      } else {
        response({ action: 'exit_autodialer', message: 'admin_not_available' });
      }
    }
  } else {
    dispatchAction(resetTimer());
  }
};

let timeoutId;
export const statusCheck = async (leadId, isPooling, response) => {
  const { autoDialerLimitTimer } = getStateValues();
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  const res = await checkAPI(leadId);
  if (res) {
    const { status_admin, status_lead, hangup_lead, hangup_admin } = res;
    if (status_lead === ANSWERED) {
      dispatchAction(updateInteractionBox(true));
    }
    if (
      [HANGUP, MISSED].includes(status_admin) ||
      [HANGUP, MISSED].includes(status_lead)
    ) {
      const isNextCall = checkDNP(res);
      if (!isNextCall && !isCallHangUp) {
        if (
          (HANGUP === status_lead && hangup_lead) ||
          (status_admin === HANGUP && status_lead === ANSWERED)
        ) {
          const hangupMoment = moment(
            hangup_lead || hangup_admin || new Date()
          );
          const currentTime = moment.utc();
          const diffInSeconds = currentTime.diff(hangupMoment, 'seconds');
          dispatchAction(updateTimer(time - diffInSeconds));
          isCallHangUp = true;
          dispatchAction(setLastHangUp(time - diffInSeconds));
          handleTimer(response);
        }
      }
      response(isNextCall);
    }

    if (isPooling && isNextPolling(status_admin, status_lead)) {
      timeoutId = setTimeout(() => {
        statusCheck(leadId, isNextPolling(status_admin, status_lead), response);
      }, [15000]);
    }
  }
};

const checkAPI = async (leadId) => {
  try {
    const res = await axiosInstance.get(`/api/call-status/${leadId}`);
    return res?.data?.data;
  } catch (error) {
    console.error('Error occured while fetching the call status ', error);
    return null;
  }
};

const checkDNP = ({
  req_id,
  status_admin,
  status_lead,
  dnp_admin,
  dnp_lead,
  hangup_lead,
}) => {
  const { callGlobalStatus } = getStateValues();
  const isAdminHangupOrMissed = [HANGUP, MISSED].includes(status_admin);
  const isLeadHangupOrMissed = [HANGUP, MISSED].includes(status_lead);

  if (
    isAdminHangupOrMissed &&
    [INITITATED, NOT_CALLED].includes(status_lead) &&
    callGlobalStatus != 'not_started'
  ) {
    if (dnp_admin >= 3) {
      return { action: 'exit_autodialer', message: 'admin_not_available' };
    } else {
      return {
        action: 'initiate_new_call',
        DNPObject: { dnp_admin, dnp_lead, req_id },
      };
    }
  }

  if (isLeadHangupOrMissed && !hangup_lead) {
    if (dnp_lead >= 2) {
      return { action: 'move_next_lead', dnp_admin, dnp_lead };
    } else {
      return {
        action: 'initiate_new_call',
        DNPObject: { dnp_admin, dnp_lead, req_id },
      };
    }
  }

  return null; // Default case, if needed
};

const isNextPolling = (status_admin, status_lead) => {
  const isAdminHangupOrMissed = [HANGUP, MISSED].includes(status_admin);
  const isLeadHangupOrMissed = [HANGUP, MISSED, NOT_CALLED].includes(
    status_lead
  );
  if (isAdminHangupOrMissed) {
    if (isLeadHangupOrMissed) {
      return false;
    } else {
      return true;
    }
  }

  return true;
};

export const initiateAutodialerCall = async (leadId, DNPObject) => {
  // Proceed with initiating the call
  const leadDetail = {
    leadId,
    mode: 'auto_dialer',
    DNPObject: Object.keys(DNPObject).length > 0 ? DNPObject : null,
  };

  isCallHangUp = false;
  dispatchAction(updateInteractionBox(false));
  dispatchAction(initiateCallThunk(leadDetail));
};
