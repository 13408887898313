import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentLead } from '../CurrentLeadContext';
import { CurrentLeadModal } from './CurrentLeadModal';
import CustomBackdrop from '../../pages/CustomBackdrop';
import { useSelector } from 'react-redux';

const CurrentLead = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.admins);
  const { currentLead, getCurrentLeadData, resetCurrentLeadContext } =
    useCurrentLead();
  const [openLeadDetailsDialog, setLeadDetailsDialog] = useState(false);

  useEffect(() => {
    const updateCurrentLead = (leadId) => {
      if (!currentLead || currentLead.lead_id !== leadId) {
        getCurrentLeadData(leadId);
      }
      setLeadDetailsDialog(!openLeadDetailsDialog);
    };

    const leadId = location.pathname.split('/').at(-1);
    updateCurrentLead(leadId);
    return () => {};
  }, []);

  const handleLeadDetailsDialogClose = () => {
    setLeadDetailsDialog(false);
    resetCurrentLeadContext();
    const url = window.location.href.split('?')[1];

    if (!location.state) {
      if (url === 'source=priority-list') {
        navigate('/leads/priority-list');
      } else {
        navigate('/bucket-search');
      }
    } else {
      navigate(location.state.from);
    }
  };

  if (loading || !currentLead || !openLeadDetailsDialog) {
    return <CustomBackdrop loading={loading} />;
  }

  return (
    <CurrentLeadModal
      {...{ openLeadDetailsDialog, handleLeadDetailsDialogClose }}
    />
  );
};

export default CurrentLead;
