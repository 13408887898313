import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CommonModal = ({
  open,
  onClose,
  title,
  children,
  width = '600px',
  onAction,
  actionText = 'Submit',
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    width,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='common-modal-title'
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            id='common-modal-title'
            variant='h6'
          >
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>{children}</Box>
        {onAction && (
          <Box sx={{ mt: 3, textAlign: 'right' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={onAction}
              sx={{ width: '100%' }}
            >
              {actionText}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CommonModal;
