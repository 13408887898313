/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from 'apis/axiosInstance';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import CircularProgress from '@mui/material/CircularProgress';
import {
  convertToCSV,
  convertUnixTimestampToDate,
  formatNumber,
  getCurrentMonthYear,
} from '../../utils/common';

const handleRazorpayData = (data) => {
  if (data?.length > 0 && data !== undefined && data !== null) {
    const settlementData = data.map((settlements) => {
      return {
        id: settlements?.entity_id || '',
        settlement_utr: settlements?.settlement_utr || '',
        type: settlements?.type || '',
        settlement_amount: settlements?.amount || '',
        fee: settlements?.fee || '',
        tax: settlements?.tax || '',
        total: settlements?.amount || '',
        notes: settlements?.notes || '',
        email: settlements?.email || '',
        date: convertUnixTimestampToDate(settlements?.settled_at) || '',
        payment_Id: settlements?.payment_id,
      };
    });
    return settlementData;
  }
};

const handleCashfreeData = (data) => {
  if (data.length > 0 && data !== undefined && data !== null) {
    const settlementData = data.map((settlements) => {
      return {
        id: settlements?.order_id || '',
        settlement_utr: settlements?.settlement_utr || '',
        type: settlements?.event_type || '',
        settlement_amount: settlements?.event_settlement_amount || '',
        fee: settlements?.payment_service_charge || '',
        tax: settlements?.payment_service_tax || '',
        total: settlements?.event_amount || '',
        email: settlements?.customer_email || '',
        mobile: settlements?.customer_phone || '',
        date:
          settlements?.settlement_date.split('T').join(' ').slice(0, 10) || '',
      };
    });
    return settlementData;
  } else {
    return null;
  }
};
const PaymentTable = ({ paymentsData }) => {
  const [razorpaySettlements, setRazorpaySettlements] = useState([]);
  const [cashfreeSettlements, setCashfreeSettlements] = useState([]);
  const [selectGateway, setSelectGateway] = useState('');
  const [razorpay, setRazorpay] = useState(false);
  const [cashfree, setCashfree] = useState(false);
  const [utrFilter, setUtrFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [utrFilteredPayment, setUtrFilteredPayment] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);

  useEffect(() => {
    if (dateFilter) {
      setShowProgressBar(true);
      axiosInstance
        .get(`/api/settlements/settlement_payment_details`, {
          params: {
            dateFilter: dateFilter,
          },
        })
        .then((res) => {
          if (res?.data) {
            res?.data
              ? setRazorpaySettlements(handleRazorpayData(res?.data[0]))
              : setRazorpaySettlements([]);
            res?.data
              ? setCashfreeSettlements(handleCashfreeData(res?.data[1]))
              : setCashfreeSettlements([]);
            setShowProgressBar(false);
          }
        })
        .catch((error) => {
          setShowProgressBar(false);
          hasError(true);
          errorMessage(`${error.message}`);
        });
    } else {
    }
  }, [dateFilter]);

  const clearFilter = () => {
    setUtrFilter('');
    setUtrFilteredPayment([]);
  };

  const handleUtrFilterChange = () => {
    if (!utrFilter.trim()) {
      setUtrFilteredPayment([]);
    } else {
      if (razorpay) {
        const paymentIds = razorpaySettlements
          ?.filter((settlement) => settlement.settlement_utr === utrFilter)
          ?.map((settlement) => {
            if (settlement.type === 'adjustment') {
              return settlement.payment_Id;
            } else {
              return settlement.id;
            }
          });
        fetchwithUtr(paymentIds, utrFilter.trim());
      } else if (cashfree) {
        const res = cashfreeSettlements.filter(
          (settlements) => settlements.settlement_utr === utrFilter
        );
        fetchCorrectLeadEmails(res);
      }
    }
  };

  const fetchwithUtr = async (paymentIds, utr) => {
    setShowProgressBar(true);
    await axiosInstance
      .post(`/api/settlements/utr_payment_details/v1`, {
        entityId: paymentIds,
        utrFilter: utr,
      })
      .then((res) => {
        if (res?.data?.data?.length > 0 && Array.isArray(res?.data?.data)) {
          const emails = res?.data?.data
            ?.map((settlement) =>
              settlement?.email !== 'void@razorpay.com'
                ? settlement.email
                : null
            )
            .filter((email) => email !== null);
          const mobile = res?.data?.data?.map((settlement) =>
            settlement?.contact?.substring(3)
          );
          fetchleadswithEmail(emails, mobile);
          setUtrFilteredPayment(res?.data?.data);
        } else if (!res?.date?.data) {
          setUtrFilteredPayment([]);
        }
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(error.message);
        setUtrFilteredPayment([]);
      })
      .finally(() => {
        setShowProgressBar(false);
      });
  };

  const fetchleadswithEmail = async (emails, mobile) => {
    try {
      await axiosInstance
        .get(`/api/getLeadDetails`, {
          params: {
            emails: emails,
            mobile: mobile,
          },
        })
        .then((res) => {
          if (res?.data && Array.isArray(res?.data) && res?.data?.length > 0) {
            setLeadDetails(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      setLeadDetails([]);
      console.log(error);
    }
  };

  const fetchCorrectLeadEmails = async (data) => {
    setShowProgressBar(true);
    await axiosInstance
      .post(`/api/settlements/utr_payment_details/v1`, {
        cashfreeData: data,
      })
      .then((res) => {
        if (res?.data?.data?.length > 0 && Array.isArray(res?.data?.data)) {
          const parsedData = res?.data?.data.map((ele) =>
            ele.type === 'string' ? JSON.parse(ele) : ele
          );
          const emails = parsedData.map((settlement) => settlement?.email);
          const mobile = parsedData.map((settlement) => settlement?.mobile);
          if (emails.length > 0) {
            fetchleadswithEmail(emails, mobile);
          }
          if (parsedData.length > 0) {
            setUtrFilteredPayment(parsedData);
          } else if (parsedData.length === 0) {
            setUtrFilteredPayment([]);
          }
        }
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(error.message);
        setUtrFilteredPayment([]);
      })
      .finally(() => {
        setShowProgressBar(false);
      });
  };
  const handlegatewayChange = (e) => {
    setShowProgressBar(true);
    setSelectGateway(e);
    if (e === 'cashfree') {
      setRazorpay(false);
      setShowProgressBar(false);
      setCashfree(true);
      if (dateFilter.length === 0) {
        setCashfreeSettlements(handleCashfreeData(paymentsData[1]));
      }
    } else if (e === 'razorpay') {
      setCashfree(false);
      setShowProgressBar(false);
      setRazorpay(true);
      if (dateFilter.length === 0) {
        setRazorpaySettlements(handleRazorpayData(paymentsData[0]));
      }
    }
  };

  const settlementColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'settlement_utr',
      headerName: 'UTR',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'settlement_amount',
      headerName: 'Settlement Amount',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'fee',
      headerName: 'Fee',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'tax',
      headerName: 'Tax',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'date',
      headerName: 'Settled_at',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
  ];
  const settlementGrid =
    selectGateway === 'razorpay'
      ? (razorpaySettlements || []).map((payment) => ({
          id: payment?.id,
          settlement_utr: payment?.settlement_utr,
          type: payment?.type,
          settlement_amount: formatNumber(payment?.settlement_amount),
          fee: formatNumber(payment?.fee),
          tax: formatNumber(payment?.tax),
          total: formatNumber(payment?.total),
          date: payment?.date,
        }))
      : selectGateway === 'cashfree'
      ? (cashfreeSettlements || []).map((payment) => ({
          id: payment?.id,
          settlement_utr: payment?.settlement_utr,
          type: payment?.type,
          settlement_amount: payment?.settlement_amount,
          fee: payment?.fee,
          tax: payment?.tax,
          total: payment?.total,
          date: payment?.date,
        }))
      : [];

  const settlementDetailcolumns = [
    { field: 'settled_at', headerName: 'Settled_at', width: 150 },
    { field: 'narration', headerName: 'Narration', width: 500 },
    { field: 'withdrawal', headerName: 'Withdrawal', width: 150 },
    { field: 'total', headerName: 'Deposit Amount', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
  ];
  let settlementDetailGrid = [];

  if (utrFilteredPayment.length > 0) {
    settlementDetailGrid = [
      ...utrFilteredPayment.map((payment) => {
        let withdrawal = 0;
        let total = 0;
        if (payment.type === 'REFUND') {
          withdrawal = payment?.total;
        } else {
          total = payment?.total;
        }
        return {
          id: payment?.id,
          settled_at: payment?.date,
          narration: `Split of Cashfree payment INR ${utrFilteredPayment
            .reduce((total, payment) => {
              if (payment.type === 'REFUND') {
                return total - parseFloat(payment?.total);
              } else {
                return (
                  total +
                  parseFloat(payment?.total) -
                  payment?.fee -
                  payment?.tax
                );
              }
            }, 0)
            .toFixed(2)} ${payment?.id}`,
          withdrawal: withdrawal,
          total: total,
          email: payment?.email,
        };
      }),
      {
        id: 'Summary',
        settled_at: utrFilteredPayment[0].date,
        narration: `Split of Cashfree payment INR ${utrFilteredPayment
          .reduce((total, payment) => {
            if (payment.type === 'REFUND') {
              return total - parseFloat(payment?.total);
            } else {
              return (
                total + parseFloat(payment?.total) - payment?.fee - payment?.tax
              );
            }
          }, 0)
          .toFixed(2)} Payment gateway charges`,
        withdrawal: utrFilteredPayment
          .reduce((total, payment) => {
            if (payment.type === 'REFUND') {
              return total + 0;
            } else {
              return (
                total +
                parseFloat(payment?.fee || 0) +
                parseFloat(payment?.tax || 0)
              );
            }
          }, 0)
          .toFixed(2),
        total: 0,
        email: 'no-reply-test-care@gocashfree.com',
      },
    ];
  }

  let dataGridRows = [];
  if (razorpay) {
    dataGridRows = [
      ...utrFilteredPayment.map((payment) => {
        let withdrawal = 0;
        let total = 0;
        if (payment?.entity === 'refund') {
          withdrawal = formatNumber(payment?.amount);
        } else {
          total = formatNumber(payment?.amount);
        }

        return {
          id: payment?.id,
          settled_at: convertUnixTimestampToDate(
            payment?.transaction?.settlement?.created_at || payment?.created_at
          ),
          narration: `Split of Razorpay payment of INR ${formatNumber(
            utrFilteredPayment.reduce((total, payment) => {
              let fee =
                payment.entity === 'refund' ? 0 : parseFloat(payment?.fee);
              return total + parseFloat(payment?.amount) - fee;
            }, 0)
          )} ${payment.id}`,

          withdrawal: withdrawal,
          total: total,
          email: payment?.email,
        };
      }),
      {
        id: 'Summary',
        settled_at: convertUnixTimestampToDate(
          utrFilteredPayment[0]?.transaction?.settlement?.created_at ||
            utrFilteredPayment[0]?.created_at
        ),
        narration: `Split of Razorpay payment of INR ${formatNumber(
          utrFilteredPayment.reduce((total, payment) => {
            let fee =
              payment.entity === 'refund' ? 0 : parseFloat(payment?.fee);
            return total + parseFloat(payment?.amount) - fee;
          }, 0)
        )} Payment gateway charges`,
        withdrawal: formatNumber(
          utrFilteredPayment.reduce(
            (total, payment) => total + parseFloat(payment?.fee || 0),
            0
          )
        ),
        total: 0,
        email: 'noreply@razorpay.com',
      },
    ];
  }

  const leadDetailsColumn = [
    { field: 'lead_email', headerName: 'Lead_Email', width: 300 },
    { field: 'lead_name', headerName: 'Name', width: 300 },
  ];

  let leadDetailsRows = [];
  if (
    leadDetails.length > 0 &&
    leadDetails !== null &&
    leadDetails !== undefined
  ) {
    leadDetailsRows = [
      ...leadDetails.map((lead) => ({
        id: lead?.id,
        lead_email: lead?.email,
        lead_name: `${lead?.fname} ${lead?.lname ? lead?.lname : ' '} #${
          lead?.id
        }`,
      })),
    ];
  }

  const handleCopyClick = () => {
    const copyData = razorpay ? dataGridRows : settlementDetailGrid;
    const excludeColumns = ['id'];

    const csvData = convertToCSV(copyData, excludeColumns);

    navigator.clipboard.writeText(csvData).then(
      () => {
        setIsSuccess(true);
        setSuccessMessage('Copied');
      },
      (error) => {
        console.log('error');
        setHasError(true);
        setErrorMessage(`Error copying data to clipboard: ${error.message}`);
      }
    );
  };
  const leadDetailsCopy = () => {
    const copyData = leadDetailsRows;
    const excludeColumns = ['id'];

    const csvData = convertToCSV(copyData, excludeColumns);

    navigator.clipboard.writeText(csvData).then(
      () => {
        setIsSuccess(true);
        setSuccessMessage('Copied');
      },
      (error) => {
        console.log('error');
        setHasError(true);
        setErrorMessage(`Error copying data to clipboard: ${error.message}`);
      }
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControl
          variant='outlined'
          style={{ margin: '10px', width: '10rem' }}
        >
          <InputLabel id='select-label'>Payment Method</InputLabel>
          <Select
            labelId='select-label'
            id='payment-method'
            value={selectGateway}
            onChange={(e) => handlegatewayChange(e.target.value)}
            label='Payment Method'
            disabled={utrFilter.length > 0}
          >
            <MenuItem value='razorpay'>Razorpay</MenuItem>
            <MenuItem value='cashfree'>Cashfree</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant='outlined'
          type='month'
          value={dateFilter.length === 0 ? getCurrentMonthYear() : dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          style={{ margin: '10px', width: '10rem' }}
          disabled={selectGateway.length === 0 || utrFilter.length > 0}
        />

        <TextField
          label='Filter by Settlement UTR'
          variant='outlined'
          value={utrFilter}
          onChange={(e) => setUtrFilter(e.target.value)}
          style={{ margin: '10px' }}
          disabled={selectGateway.length === 0 || showProgressBar}
        />

        {utrFilter.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Button
              variant='contained'
              onClick={handleUtrFilterChange}
              disabled={!utrFilter || showProgressBar}
            >
              Search
            </Button>
            {utrFilter && (
              <>
                <Button
                  variant='contained'
                  onClick={handleCopyClick}
                  disabled={utrFilteredPayment.length === 0}
                >
                  Copy Result
                </Button>
                <Button
                  variant='contained'
                  onClick={clearFilter}
                >
                  Clear Filter
                </Button>
              </>
            )}
            {leadDetails.length > 0 && (
              <Button
                variant='contained'
                onClick={leadDetailsCopy}
                disabled={leadDetails.length === 0}
              >
                Copy Lead Details
              </Button>
            )}
            {!leadDetails && <Box>No Lead Id found</Box>}
          </Box>
        )}
      </Box>
      {selectGateway.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            height: '400px',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
          }}
        >
          Please select a payment method to show it's settlements
        </Box>
      )}
      {selectGateway.length > 0 && (
        <>
          <Box textAlign={'center'}>
            {showProgressBar && <CircularProgress />}
          </Box>
          {utrFilter.length === 0 && (
            <>
              {razorpay &&
                razorpaySettlements &&
                razorpaySettlements.length > 0 &&
                !showProgressBar && (
                  <Box>
                    <DataGrid
                      rows={settlementGrid}
                      columns={settlementColumns}
                      pagination
                      autoHeight
                      disableSelectionOnClick
                    />
                  </Box>
                )}

              {razorpay &&
                !showProgressBar &&
                (!razorpaySettlements || razorpaySettlements.length === 0) && (
                  <Box
                    sx={{
                      color: 'blue',
                      display: 'flex',
                      height: '400px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                    }}
                  >
                    No Data To show Please Select a valid date
                  </Box>
                )}
            </>
          )}
          {utrFilter.length > 0 && (
            <>
              {!showProgressBar &&
                razorpay &&
                utrFilteredPayment &&
                utrFilteredPayment.length > 0 && (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                  >
                    <DataGrid
                      rows={dataGridRows}
                      columns={settlementDetailcolumns}
                      pagination
                      autoHeight
                      disableSelectionOnClick
                    />
                    {leadDetails.length > 0 && (
                      <Box width={'40%'}>
                        <DataGrid
                          rows={leadDetailsRows}
                          columns={leadDetailsColumn}
                          pagination
                          autoHeight
                          disableSelectionOnClick
                        />
                      </Box>
                    )}
                    {leadDetails.length === 0 && (
                      <Box
                        textAlign={'center'}
                        marginTop={'10px'}
                        color={'red'}
                      >
                        No Lead Id found
                      </Box>
                    )}
                  </Box>
                )}

              {razorpay &&
                !showProgressBar &&
                (!utrFilteredPayment || utrFilteredPayment.length === 0) && (
                  <Box
                    sx={{
                      color: 'red',
                      display: 'flex',
                      height: '400px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                    }}
                  >
                    Please Enter a valid Utr Number
                  </Box>
                )}
            </>
          )}
        </>
      )}
      {selectGateway.length > 0 && (
        <>
          {utrFilter.length === 0 && (
            <>
              {cashfree &&
                cashfreeSettlements &&
                cashfreeSettlements.length > 0 &&
                !showProgressBar && (
                  <Box>
                    <DataGrid
                      rows={settlementGrid}
                      columns={settlementColumns}
                      pagination
                      autoHeight
                      disableSelectionOnClick
                    />
                  </Box>
                )}

              {cashfree &&
                !showProgressBar &&
                (!cashfreeSettlements || cashfreeSettlements.length === 0) && (
                  <Box
                    sx={{
                      color: 'blue',
                      display: 'flex',
                      height: '400px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                    }}
                  >
                    No Data To show Please Select a valid date
                  </Box>
                )}
            </>
          )}
          {utrFilter.length > 0 && (
            <>
              {cashfree &&
                !showProgressBar &&
                utrFilteredPayment &&
                utrFilteredPayment.length > 0 && (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                  >
                    <DataGrid
                      rows={settlementDetailGrid}
                      columns={settlementDetailcolumns}
                      pagination
                      autoHeight
                      disableSelectionOnClick
                    />
                    {leadDetails.length > 0 && (
                      <Box width={'40%'}>
                        <DataGrid
                          rows={leadDetailsRows}
                          columns={leadDetailsColumn}
                          pagination
                          autoHeight
                          disableSelectionOnClick
                        />
                      </Box>
                    )}
                    {leadDetails.length === 0 && (
                      <Box
                        textAlign={'center'}
                        marginTop={'10px'}
                        color={'red'}
                      >
                        No Lead Id found
                      </Box>
                    )}
                  </Box>
                )}

              {cashfree &&
                !showProgressBar &&
                (!utrFilteredPayment || utrFilteredPayment.length === 0) && (
                  <Box
                    sx={{
                      color: 'red',
                      display: 'flex',
                      height: '400px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                    }}
                  >
                    Please Enter a valid Utr Number
                  </Box>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentTable;
