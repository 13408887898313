import React, { useState, useEffect } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { LabelTextFieldWithColorPicker } from './LabelTextFieldWithColorPicker';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import {
  currentAdminIsManagerOrAdmin,
  currentAdminIsManagerOrAdminOrMarketing,
  currentAdminIsMarketing,
  getSanitizedPhoneNumber,
  getTimeZoneFormattedTimestamp,
  splitByUnderscoresAndCapitalize,
  getDatabaseFormattedDate,
  currentAdminIsIC,
  currentAdminIsTrainee,
  currentAdminIsMarketingOrAdmin,
  currentAdminIsAdmin,
} from '../../utils/common';
import { handleKeyDown } from '../../utils/hrms/common';
import { useCurrentLead } from '../CurrentLeadContext';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';
import constants from '../../utils/constants';
import { getCurrentAdminId, isValidPhoneNumber } from '../../utils/common';
import WarningNotifier from '../ToastNotifications/WarningNotifier';
const { TOKEN_AMOUNT_PAID, COMPLETE_AMOUNT_PAID } = constants;

function LeadEditForm({
  openModal,
  setOpenModal,
  setShowConfetties,
  allDropReasons,
  setAllDropReasons,
}) {
  const {
    currentLead,
    currentLeadStatusActivities,
    currentLeadInteractions,
    getCurrentLeadAndUpdateContext,
    getCurrentLeadActivitiesAndUpdateContext,
    getCurrentLeadStatusActivitiesAndUpdateContext,
    currentLeadSignups,
    setCurrentLeadSignups,
  } = useCurrentLead();
  const [editedFields, setEditedFields] = useState({}); // this only contains the fields that we want to update
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isColorPalleteVisible, setIsColorPalleteVisible] = useState(false);
  const [isFontColorPalleteVisible, setIsFontColorPalleteVisible] =
    useState(false);
  const [nextStatusList, setNextStatusList] = useState([]);
  const [signupFields, setSignupFields] = useState({});
  const [hasWarning, setHasWarning] = useState(false);
  const [hasWarningMessage, setHasWarningMessage] = useState('');

  // useEffect(() => {
  //   fetchDropReasons();
  // }, []);

  // const fetchDropReasons = () => {
  //   axiosInstance.get(`/api/users/drop_reasons`)
  //   .then((res) => {
  //     if (res.data) {
  //       setAllDropReasons(res.data);
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     setHasError(true);
  //     setErrorMessage(err.response?.data?.message || "Couldn't fetch the drop reasons.");
  //   })
  // }

  const updateLeadDetailState = (name, value) => {
    if (name === 'snoozed_until_1' && !canSnoozeLead()) {
      setHasError(true);
      setErrorMessage(
        "You can't snooze the lead without adding an interaction"
      );
      return;
    }
    validateSnoozedUntil(name, value);

    if (currentLead[name] !== value) {
      setEditedFields({ ...editedFields, [name]: value });
    } else {
      const tmp = { ...editedFields };
      delete tmp[name];
      setEditedFields(tmp);
    }
  };

  const validateSnoozedUntil = (name, value) => {
    if (name !== 'snoozed_until_1' || !canSnoozeLead()) {
      return;
    }

    const date = getDatabaseFormattedDate(new Date(value));
    axiosInstance
      .get(`/api/validate-snooze?date=${date}`)
      .then((res) => {
        const message = res.data.message;
        if (message === '') return;
        setHasWarning(true);
        setHasWarningMessage(message);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An internal error occurred while fetching holidays'
        );
      });
  };

  const updateLeadDetails = (e) => {
    let { name, value } = e.target;

    if (
      (name === 'mobile' || name === 'whatsapp') &&
      (value.length > 10 || isNaN(Number(value)))
    ) {
      return;
    }

    if (name === 'is_hot') {
      value = e.target.checked;
    }

    if (name === 'notify_for_follow_up') {
      value = e.target.checked;
    }
    updateLeadDetailState(name, value);
  };

  const canSnoozeLead = () => {
    if (currentLeadInteractions.length === 0) {
      // no interaction has been added at all.
      return false;
    }

    let lastInteraction = null;
    for (let i = currentLeadInteractions.length - 1; i >= 0; --i) {
      if (currentLeadInteractions[i].updated_by === getCurrentAdminId()) {
        lastInteraction = new Date(currentLeadInteractions[i].updated_at);
        break;
      }
    }

    if (!lastInteraction) {
      // no interaction has been added by the current admin.
      return false;
    }
    const now = new Date();
    const diff = now - lastInteraction;
    return diff <= 30 * 60 * 1000; // whether, or not the interaction was added in the last 30 minutes.
  };

  const updateSnoozeUntil = (schedule) => {
    let date = new Date();
    switch (schedule) {
      case 'today_12pm':
        date.setMinutes(0);
        date.setHours(12);
        break;
      case 'today_3pm':
        date.setMinutes(0);
        date.setHours(15);
        break;
      case 'today_6pm':
        date.setMinutes(0);
        date.setHours(18);
        break;
      case 'today_9pm':
        date.setMinutes(0);
        date.setHours(21);
        break;
      case 'tomorrow_9am':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(9);
        break;
      case 'tomorrow_12pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(12);
        break;
      case 'tomorrow_6pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(18);
        break;
      case 'tomorrow_9pm':
        date.setMinutes(0);
        date.setDate(date.getDate() + 1);
        date.setHours(21);
        break;
    }

    updateLeadDetailState(
      'snoozed_until_1',
      getTimeZoneFormattedTimestamp(date)
    );
  };

  const setLabelColor = (color) => {
    updateLeadDetailState('label_color', color.hex);
    setIsColorPalleteVisible(false);
  };

  const setLabelFontColor = (color) => {
    updateLeadDetailState('label_font_color', color.hex);
    setIsFontColorPalleteVisible(false);
  };

  // function getStatusDropdown() {
  //   return (
  //     <FormControl id="status" fullWidth>
  //       <InputLabel id="lead_status">Status</InputLabel>
  //       <Select
  //         name="status"
  //         label="Status"
  //         labelId="lead_status"
  //         value={getFieldValue("status")}
  //         onChange={updateLeadDetails}
  //       >
  //         <MenuItem
  //           value={getFieldValue("status")}
  //           default
  //         >
  //           {splitByUnderscoresAndCapitalize(getFieldValue("status"))}
  //         </MenuItem>
  //         {
  //           nextStatusList.map((statusObj, index) => {
  //             if (!currentAdminIsManagerOrAdmin() && statusObj.status === constants.REACTIVATED) {
  //               return <MenuItem />
  //             }
  //             return (
  //               <MenuItem value={statusObj.status} divider>
  //                 <Stack>
  //                   <Typography variant="body1">
  //                     {splitByUnderscoresAndCapitalize(statusObj.status)}
  //                   </Typography>
  //                   {statusObj.description !== "" &&
  //                     <Typography variant="body2">
  //                       {statusObj.description}
  //                     </Typography>
  //                   }
  //                 </Stack>
  //               </MenuItem>
  //             );
  //           })
  //         }
  //       </Select>
  //     </FormControl>
  //   );
  // }

  function getAssigneeDropdown() {
    return (
      <Grid item>
        <AdminsAutocompleteDropdown
          disabled={
            currentLead?.status === constants.DROPPED ||
            currentLead?.status === constants.COMPLETE_AMOUNT_PAID ||
            !currentAdminIsManagerOrAdminOrMarketing()
          }
          label='Assignee'
          value={getFieldValue('assignee')}
          onChangeHandler={(e, newValue) => {
            e.target.name = 'assignee';
            e.target.value = newValue.value;
            updateLeadDetails(e);
          }}
          adminOptions={{
            access_type: ['allowed', 'restricted'],
            roles: [
              constants.ADMIN,
              constants.INDEPENDENT_CONTRIBUTOR,
              constants.TRAINEE,
            ],
          }}
          sx={{
            width: 'inherit',
          }}
          shouldShowOnlyICTraineeManagerSM
        />
      </Grid>
    );
  }

  const getFieldValue = (key) => {
    if (key in editedFields) {
      return editedFields[key];
    }

    if (key !== 'dropReason') {
      return currentLead[key];
    }

    if (currentLeadStatusActivities.length === 0) {
      return '';
    }

    const { status } = currentLeadStatusActivities.at(-1);
    if (!Object.values(constants).includes(status)) {
      // this is a drop reason;
      return status;
    }
    return '';
  };

  // const shouldShowDropReasonDropdown = () => {
  //   if (currentLead.status === constants.DROPPED) {
  //     return true;
  //   }
  //   if (editedFields.status === constants.DROPPED) {
  //     return true;
  //   }
  //   return false;
  // }

  const getSignupFields = () => {
    const len = currentLeadSignups.length;
    const signup = currentLeadSignups[len - 1] ?? {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
      utm_region: null,
      utm_adset: null,
    };

    return (
      <Stack spacing={2}>
        {Object.keys(signup).map((utm) => {
          if (
            utm === 'created_at' ||
            utm === 'updated_at' ||
            utm === 'program' ||
            signup[utm]
          ) {
            return <></>;
          }
          return (
            <FormControl fullWidth>
              <TextField
                size='small'
                type='text'
                name={utm}
                placeholder={utm.split('_').join(' ')}
                onChange={(e) => {
                  setSignupFields({
                    ...signupFields,
                    [utm]: e.target.value,
                  });
                }}
                value={signupFields[utm] ?? ''}
              />
            </FormControl>
          );
        })}
      </Stack>
    );
  };

  const handleSignupUpdate = () => {
    const body = {
      ...signupFields,
    };

    const len = currentLeadSignups.length;
    let req;
    if (len === 0) {
      body.lead_id = currentLead.id;
      req = axiosInstance.post(`/api/users/${body.lead_id}/signups`, body);
    } else {
      const { id } = currentLeadSignups[len - 1];
      req = axiosInstance.patch(`/api/signups/${id}`, body);
    }

    req
      .then((res) => {
        setCurrentLeadSignups([
          ...currentLeadSignups.splice(0, len - 1),
          res.data,
        ]);
        setIsSuccess(true);
        setSuccessMessage('Fields updated');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while updating signup field'
        );
      })
      .finally(() => {
        setSignupFields({});
      });
  };

  function submitHandler() {
    if (Object.keys(signupFields).length > 0) {
      handleSignupUpdate();
    }

    if (Object.keys(editedFields).length === 0) {
      setOpenModal(false);
      return;
    }

    const postData = { ...editedFields };

    /**
     * For dropping the leads, the fields that must be set are:
     * 2. status = 'dropped',
     * 3. dropReason (its a custom field that gets added only while dropping the lead)
     * 4. is_blacklisted = 1 (if the dropReason is 'blacklisted')
     * 5. assignee = null
     *
     * Note: A sales portal lead may not update all the fields. If we've enough information, update rest of the fields
     * otherwise, throw error.
     */

    if ((postData?.mobile ?? '') !== '') {
      if (!isValidPhoneNumber(postData.mobile)) {
        setHasError(true);
        setErrorMessage(`Invalid mobile number`);
        return;
      }

      postData.mobile = getSanitizedPhoneNumber(postData.mobile);
      postData.mobile_int = getSanitizedPhoneNumber(postData.mobile);
    }

    if ((postData?.whatsapp ?? '') !== '') {
      if (!isValidPhoneNumber(postData.whatsapp)) {
        setHasError(true);
        setErrorMessage(`Invalid whatsapp number`);
        return;
      }

      postData.whatsapp = getSanitizedPhoneNumber(postData.whatsapp);
    }

    if (postData?.product && postData?.product === 'null') {
      postData.product = null;
    }

    // if (postData?.dropReason && postData.dropReason !== "") {
    //   postData.status = constants.DROPPED;
    // }

    if (postData?.assignee) {
      const baseStatus = [constants.CREATED, constants.REACTIVATED, ''];
      if (baseStatus.includes(currentLead.status ?? '')) {
        postData.status = constants.ASSIGNED;
      }
    }

    switch (postData?.status) {
      // case constants.DROPPED:
      //   if ((postData?.dropReason ?? "") === "") {
      //     setHasError(true);
      //     setErrorMessage("Lead cannot be dropped without specifying the reason.");
      //     return;
      //   }
      //   break;

      case constants.ASSIGNED:
        if (!postData?.assignee && !currentLead.assignee) {
          setHasError(true);
          setErrorMessage(
            "Please select an assignee because you've updated the status to 'assigned'."
          );
          return;
        }
        if (!postData?.assignee && currentLead.assignee) {
          postData['assignee'] = currentLead.assignee;
        }
        break;

      case constants.REACTIVATED:
        postData['closed_at'] = null;
        break;

      default:
        break;
    }

    if (Object.keys(postData).length === 0) {
      return;
    }

    axiosInstance
      .put(base_url() + '/api/users/' + currentLead.lead_id, postData)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Lead successfully updated');
        getCurrentLeadAndUpdateContext(currentLead.id);
        getCurrentLeadActivitiesAndUpdateContext(currentLead.id);
        getCurrentLeadStatusActivitiesAndUpdateContext(currentLead.id);
        setOpenModal(false);
        setEditedFields({});

        if (
          [TOKEN_AMOUNT_PAID, COMPLETE_AMOUNT_PAID].includes(postData.status)
        ) {
          setShowConfetties(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.message.includes('Validation error')) {
          setHasError(true);
          setErrorMessage('Duplicate entry.');
        } else {
          setHasError(true);
          setErrorMessage(
            error.response?.data?.message || "Couldn't update the lead"
          );
        }
      });
  }

  /*
    Temporary change to hide lead Name,Linkedin,Email,Phone to Marketing
    display: none if current lead is marketing
  */

  const isPIIFieldNull = (field) => {
    return currentLead[field] !== null;
  };

  const isDisableEditBox = (boxName) => {
    return (
      isPIIFieldNull(boxName) &&
      (currentAdminIsMarketing() ||
        currentAdminIsIC() ||
        currentAdminIsTrainee())
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {hasWarning && (
        <WarningNotifier {...{ message: hasWarningMessage, setHasWarning }} />
      )}

      <Stack
        direction='row-reverse'
        spacing={2}
        sx={{ mr: 2 }}
      >
        <Button
          variant='contained'
          size='medium'
          onClick={() => setOpenModal(!openModal)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          size='medium'
          onClick={submitHandler}
          disabled={
            Object.keys(editedFields).length === 0 &&
            Object.keys(signupFields).length === 0
          }
        >
          Update
        </Button>
      </Stack>

      <Stack
        spacing={2}
        direction='column'
        p={2}
      >
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={isPIIFieldNull('fname') && currentAdminIsMarketing()}
              type='text'
              name='fname'
              label='First Name'
              onChange={updateLeadDetails}
              value={getFieldValue('fname')}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={isPIIFieldNull('lname') && currentAdminIsMarketing()}
              type='text'
              name='lname'
              label='Last Name'
              onChange={updateLeadDetails}
              value={getFieldValue('lname')}
            />
          </FormControl>
        </Grid>
        {!currentAdminIsMarketing() && (
          <>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  disabled={isDisableEditBox('email')}
                  type='email'
                  name='email'
                  label='Email Address'
                  onChange={updateLeadDetails}
                  value={getFieldValue('email')}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  disabled={isDisableEditBox('mobile')}
                  type='text'
                  name='mobile'
                  label='Mobile Number'
                  value={getFieldValue('mobile')}
                  onChange={updateLeadDetails}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  disabled={isDisableEditBox('whatsapp')}
                  type='text'
                  name='whatsapp'
                  label='Whatsapp Number'
                  value={getFieldValue('whatsapp')}
                  onChange={updateLeadDetails}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item>
          <FormControl
            fullWidth
            disabled={currentAdminIsMarketing()}
          >
            <InputLabel id='experience_label'>Experience</InputLabel>
            <Select
              type='text'
              name='experience'
              label='Experience'
              labelId='experience_label'
              value={getFieldValue('experience')}
              onChange={updateLeadDetails}
            >
              <MenuItem value=''>Select Option</MenuItem>
              <MenuItem value='student'>Student</MenuItem>
              <MenuItem value='graduated'>Graduated but not working</MenuItem>
              <MenuItem value='working_professional_0_2_exp'>
                Working professional with 0-2 years exp
              </MenuItem>
              <MenuItem value='working_professional_2_5_exp'>
                Working professional with 2-5 years exp
              </MenuItem>
              <MenuItem value='working_professional_5_plus_exp'>
                Working professional with 5+ years exp
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type="text"
              name="tech_stack"
              label="Tech stack"
              value={getFieldValue("tech_stack")}
              onChange={updateLeadDetails}
              sx={{ display: currentAdminIsMarketing() && 'none' }}
            />
          </FormControl>
        </Grid> */}
        {(currentAdminIsAdmin() || currentAdminIsMarketing()) && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='product_label'>Product</InputLabel>
              <Select
                type='text'
                name='product'
                label='product'
                labelId='product_label'
                value={getFieldValue('product')}
                onChange={updateLeadDetails}
              >
                <MenuItem value='super30'>Super 30</MenuItem>
                <MenuItem value='personal_coaching'>Personal coaching</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type='text'
              name='college'
              label='College'
              value={getFieldValue('college')}
              onChange={updateLeadDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type='text'
              name='branch'
              label='Branch'
              value={getFieldValue('branch')}
              onChange={updateLeadDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type='text'
              name='graduation_year'
              label='Graduation year'
              value={getFieldValue('graduation_year')}
              onChange={updateLeadDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type='text'
              name='company'
              label='Company'
              value={getFieldValue('company')}
              onChange={updateLeadDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              disabled={currentAdminIsMarketing()}
              type='text'
              name='role'
              label='Role'
              value={getFieldValue('role')}
              onChange={updateLeadDetails}
            />
          </FormControl>
        </Grid>
        <Grid item>
          {!currentAdminIsMarketing() && (
            <FormControl fullWidth>
              <TextField
                disabled={
                  isPIIFieldNull('linkedin_link') && currentAdminIsMarketing()
                }
                type='linkedin_link'
                name='linkedin_link'
                label='LinkedIn'
                onChange={updateLeadDetails}
                value={getFieldValue('linkedin_link')}
              />
            </FormControl>
          )}
          <br />
          <br />
          <LabelTextFieldWithColorPicker
            label={getFieldValue('label')}
            labelColor={getFieldValue('label_color')}
            labelFontColor={getFieldValue('label_font_color')}
            setLabelColor={setLabelColor}
            setLabelFontColor={setLabelFontColor}
            updateLeadDetails={updateLeadDetails}
            isColorPalleteVisible={isColorPalleteVisible}
            isFontColorPalleteVisible={isFontColorPalleteVisible}
            setIsColorPalleteVisible={setIsColorPalleteVisible}
            setIsFontColorPalleteVisible={setIsFontColorPalleteVisible}
          />
        </Grid>
        {/* <Grid item>
          <FormControl
            fullWidth
            disabled={currentAdminIsMarketing()}
          >
            <Typography>Today</Typography>
            <Box display={'inline-block'}>
              <Button onClick={() => updateSnoozeUntil('today_12pm')}>
                12 PM
              </Button>
              <Button onClick={() => updateSnoozeUntil('today_3pm')}>
                3 PM
              </Button>
              <Button onClick={() => updateSnoozeUntil('today_6pm')}>
                6 PM
              </Button>
              <Button onClick={() => updateSnoozeUntil('today_9pm')}>
                9 PM
              </Button>
            </Box>
            <Typography>Tomorrow</Typography>
            <Box display={'inline-block'}>
              <Button onClick={() => updateSnoozeUntil('tomorrow_9am')}>
                9 AM
              </Button>
              <Button onClick={() => updateSnoozeUntil('tomorrow_12pm')}>
                12 PM
              </Button>
              <Button onClick={() => updateSnoozeUntil('tomorrow_6pm')}>
                6 PM
              </Button>
              <Button onClick={() => updateSnoozeUntil('tomorrow_9pm')}>
                9 PM
              </Button>
            </Box>
            <TextField
              disabled={currentAdminIsMarketing()}
              min={getTimeZoneFormattedTimestamp(new Date()).split(0, 16)}
              type='datetime-local'
              name='snoozed_until_1'
              value={getFieldValue('snoozed_until_1')}
              onChange={updateLeadDetails}
            />
            <Box
              display='flex'
              gap='20px'
              justifyContent='space-between'
              mt={1}
            >
              {editedFields.snoozed_until_1 && !currentAdminIsMarketing() && (
                <FormControlLabel
                  control={<Checkbox />}
                  label='Notify me'
                  name='notify_for_follow_up'
                  onClick={updateLeadDetails}
                  checked={editedFields.notify_for_follow_up}
                />
              )}
            </Box>
          </FormControl>
        </Grid> */}
        {/* {currentAdminIsMarketingOrAdmin() && (
          <Grid item>
            <FormControlLabel
              name='is_hot'
              control={<Checkbox />}
              label='Hot lead'
              checked={getFieldValue('is_hot')}
              onClick={updateLeadDetails}
            />
          </Grid>
        )} */}
      </Stack>

      <Stack
        spacing={2}
        direction='column'
        p={2}
      >
        {getAssigneeDropdown()}
        {currentAdminIsMarketing() && getSignupFields()}

        {/* <Grid item>
          <Stack spacing={2}>
            {getStatusDropdown()}
            
            {shouldShowDropReasonDropdown() &&
              <FormControl id="drop-reason" 
                disabled={getFieldValue("status !== constants.DROPPED || currentAdminIsMarketing()")}
                fullWidth
              >
                <InputLabel id="drop-reason">Drop reason</InputLabel>
                <Select 
                  name="drop-reason"
                  label="Drop reason"
                  labelId="drop-reason"
                  value={getFieldValue("dropReason")}
                  onChange={(e) => updateLeadDetailState("dropReason", e.target.value)}
                >
                  {
                    allDropReasons.map((reason, index) => {
                      return (
                        <MenuItem value={reason}>{splitByUnderscoresAndCapitalize(reason)}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            }
          </Stack>
        </Grid> */}
      </Stack>
    </>
  );
}

export default React.memo(LeadEditForm);
