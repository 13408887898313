import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { capitalize, sortByFullName } from '../../utils/common';
import constants from '../../utils/constants';
import { getCurrAdmin } from 'features/auth/authSelector';
import { useSelector } from 'react-redux';
const { MANAGER, SENIOR_MANAGER, INDEPENDENT_CONTRIBUTOR, TRAINEE } = constants;

/**
 * https://mui.com/material-ui/react-autocomplete/#ControllableStates.js
 *
 * Mandatory props = {
 *
 *  label: (a string value),
 *
 *  sx: {
 *   Any styling goes here.
 *  },
 *
 *  onChangeHandler: () => {} // to update the value of admin,
 *
 *  value: stores currently selected adminId ,
 *
 * }
 *
 * Some of the props for which default value can be overriden = {
 *
 *  showAllOption: false (default),
 *  showNoneOption: false (default),
 *  showCurrentAdmin: false (default)
 *
 *  adminOptions: { // value for every key must be an array.
 *    roles: [] (not present by default, but can be set.)
 *  },
 * }
 *
 * Note: default values will not be used if the key of that 'prop' is found in 'props' object.
 * For ex: if 'sx' prop is passed during the component call, the default style will not be used.
 *
 * all other props are optional.
 *
 */

const defaultStyle = {
  width: 300,
};

const defaultAdminOptions = {
  access_type: ['allowed', 'restricted'],
};

export default function AdminsAutocompleteDropdown({
  value,
  onChangeHandler,
  label = 'Admins',
  adminOptions = defaultAdminOptions,
  sx = defaultStyle,
  showAllOption = false,
  showNoneOption = false,
  showCurrentAdmin = false,
  shouldShowOnlyICTraineeManagerSM = false,
  shouldShowOnlyICTrainee = false,
  ...restProps
}) {
  const [inputValue, setInputValue] = useState('');
  const [admins, setAdmins] = useState([]);
  const leadAssignableRoles = [
    MANAGER,
    SENIOR_MANAGER,
    INDEPENDENT_CONTRIBUTOR,
    TRAINEE,
  ];
  const assigneeRoleOnCreateLead = [INDEPENDENT_CONTRIBUTOR, TRAINEE];

  useEffect(() => {
    getAdmins();
    return () => {};
  }, []);

  useEffect(() => {
    if (value === '') {
      setInputValue('');
    }
    return () => {};
  }, [value]);
  const localStorageAdmins = useSelector((state) => state.admins.allAdmins);

  function getAdmins() {
    const currentAdmin = getCurrAdmin();
    let tmpAdmins = [];
    let tmpInputValue;
    // default options will be used, if undefined or null is passed.
    let options =
      Object.keys(adminOptions ?? {}).length === 0
        ? defaultAdminOptions
        : adminOptions;

    if (showAllOption) {
      tmpAdmins.push({
        label: 'All',
        value: '',
      });
    }

    if (showNoneOption) {
      tmpAdmins.push({
        label: 'None',
        value: 'none',
      });
    }

    localStorageAdmins.forEach((admin) => {
      if (showCurrentAdmin && admin.id !== currentAdmin.id) return;

      if (shouldShowOnlyICTraineeManagerSM || shouldShowOnlyICTrainee) {
        let hasIntersectingRole;

        if (shouldShowOnlyICTraineeManagerSM) {
          hasIntersectingRole =
            admin.roles.filter((e) => leadAssignableRoles.includes(e.name))
              .length > 0;
        } else {
          hasIntersectingRole =
            admin.roles.filter((e) => assigneeRoleOnCreateLead.includes(e.name))
              .length > 0;
        }

        if (!hasIntersectingRole) {
          return;
        }
      }

      let ok = true;
      for (const key in options) {
        if (!(key in admin)) {
          ok = false;
          break;
        }

        if (key === 'roles') {
          const roles = new Set([...admin.roles.map((r) => r.name)]);
          const hasIntersection =
            options[key].filter((r) => roles.has(r)).length > 0;

          ok = hasIntersection;
          if (!ok) {
            break;
          }
        } else if (!options[key].includes(admin[key])) {
          ok = false;
          break;
        }
      }

      if (admin.id == value) {
        tmpInputValue = {
          id: admin.id,
          label: admin.fname + ' ' + admin.lname,
          value: `${admin.id}`,
          access: admin.access_type,
        };
      }

      if (ok) {
        tmpAdmins.push({
          id: admin.id,
          label: admin.fname + ' ' + admin.lname,
          value: `${admin.id}`, // must be a string always.
          access: admin.access_type,
        });
      }
    });

    if (tmpInputValue) {
      setInputValue(tmpInputValue);
    }

    tmpAdmins = tmpAdmins.length > 0 && sortByFullName(tmpAdmins, 'label');
    setAdmins(tmpAdmins);
  }

  return (
    <Autocomplete
      {...restProps}
      value={inputValue}
      onChange={(event, newValue) => {
        setInputValue(newValue);
        onChangeHandler(event, newValue);
      }}
      options={admins}
      getOptionLabel={(option) => option.label ?? ''}
      sx={sx}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.id}
        >
          {option.label} ({capitalize(option.access)})
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
        />
      )}
      disableClearable
    />
  );
}
