import React, { useState, useEffect } from 'react';
import { useCurrentLead } from '../../CurrentLeadContext';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import constants from 'utils/constants';
import { CurrentLeadModalContent } from '../../data-table/CurrentLeadModal';
import CustomBackdrop from '../../../pages/CustomBackdrop';
import CurrentPostSalesLeadComponent from '../../CurrentPostSalesLeadContext';
import {
  initiateAutodialerCall,
  statusCheck,
} from '../autoDialerCommon/index.js';
import ShowExitAutodialerDialog from './ShowExitAutodialerDialog';
import { LeadDetailsModal } from 'components/users/LeadDetailsModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  endAutoDialer,
  fetchCurrentLeadDetails,
} from 'features/autoDialer/autoDailerThunk';
import {
  resetTimer,
  setIsMannualInteractionMarked,
  setIsNextButton,
  setLastHangUp,
  setLeadCount,
  setLoading,
  updateGlobalCallStatus,
} from 'features/autoDialer/autoDailerSlice';

function AutoDialerScreen() {
  const dispatch = useDispatch();
  const {
    loading,
    allLeads,
    leadCurrentIndex,
    nextButtonClickable,
    autoDialerLimitTimer,
    lastHangUp,
    callGlobalStatus,
  } = useSelector((state) => state.autoDialer);
  const { currentLead, getCurrentLeadData } = useCurrentLead();
  const { COMPLETED_AS_EXPECTED } = constants;
  const [endAutodialer, setEndAutodialer] = useState(false);

  useEffect(() => {
    if (
      allLeads &&
      allLeads?.length > 0 &&
      callGlobalStatus === 'not_started'
    ) {
      getCurrentLeadData(allLeads[leadCurrentIndex]);

      statusCheck(allLeads[leadCurrentIndex], false, response);

      initiateAutodialerCall(allLeads[leadCurrentIndex], {}, callGlobalStatus);
    }
  }, [allLeads]);

  useEffect(() => {
    if (callGlobalStatus === 'call_initiated') {
      statusCheck(allLeads[leadCurrentIndex], true, response);
    }
  }, [callGlobalStatus]);

  useEffect(() => {
    if (
      allLeads &&
      allLeads?.length > 0 &&
      leadCurrentIndex > 0 &&
      callGlobalStatus === 'not_started'
    ) {
      dispatch(resetTimer());
      dispatch(setIsMannualInteractionMarked(false));
      dispatch(setLoading(true));
      getCurrentLeadData(allLeads[leadCurrentIndex]);
      dispatch(fetchCurrentLeadDetails(allLeads[leadCurrentIndex]));
      initiateAutodialerCall(allLeads[leadCurrentIndex], {}, callGlobalStatus);
    } else if (callGlobalStatus != 'not_started') {
      getCurrentLeadData(allLeads[leadCurrentIndex]);
      dispatch(fetchCurrentLeadDetails(allLeads[leadCurrentIndex]));
    }
  }, [leadCurrentIndex]);

  const handleClose = () => setEndAutodialer(false);

  const getNewLead = () => {
    if (leadCurrentIndex + 1 < allLeads?.length && allLeads?.length > 0) {
      dispatch(setLeadCount(leadCurrentIndex + 1));
      dispatch(setLastHangUp(null));
      dispatch(updateGlobalCallStatus());
      return true;
    } else {
      exitAutoDialHandler(COMPLETED_AS_EXPECTED, 'All leads are completed');
    }

    return false;
  };
  const response = async (isNextCall) => {
    if (isNextCall) {
      switch (isNextCall.action) {
        case 'initiate_new_call':
          await dispatch(updateGlobalCallStatus());
          initiateAutodialerCall(
            allLeads[leadCurrentIndex],
            isNextCall.DNPObject,
            callGlobalStatus
          );

          break;
        case 'move_next_lead':
          getNewLead();
          break;
        case 'exit_autodialer':
          exitAutoDialHandler(isNextCall.message, 'Admin is nnot available');
          break;
        default:
          break;
      }
    }
  };

  const exitAutoDialHandler = (activityType, notes = null) => {
    dispatch(
      endAutoDialer({ leadID: allLeads[leadCurrentIndex], activityType, notes })
    );
  };

  const markInteraction = () => {
    dispatch(setIsMannualInteractionMarked(true));
    dispatch(setIsNextButton(true));
  };

  return (
    <>
      <CustomBackdrop open={loading} />

      <DialogTitle>
        <Grid
          container
          display='flex'
          alignContent='center'
          justifyContent='space-between'
        >
          <Grid
            item
            display='flex'
            gap='10px'
            alignItems='center'
            xs={4}
          >
            <CircleIcon sx={{ color: '#12b886' }} />
            <Typography
              sx={{
                fontSize: 'clamp(18px,1.6vh,22px)',
                fontFamily: 'monospace,sans-sirf',
              }}
            >
              Autodialer Mode! You are wired in, avoid all distractions!
            </Typography>
          </Grid>

          <Grid
            item
            xs={8}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            gap='2rem'
          >
            {lastHangUp && (
              <Typography
                style={{
                  color: autoDialerLimitTimer < 60 ? 'red' : 'black', // Red if less than 60 seconds
                }}
              >
                Remaining time: {Math.floor(autoDialerLimitTimer / 60)}:
                {String(autoDialerLimitTimer % 60).padStart(2, '0')}
              </Typography>
            )}
            <Button
              onClick={() => setEndAutodialer(true)}
              variant='contained'
              sx={{
                backgroundColor: '#ff0000',
                marginRight: '1vw',
                width: '20%',
                ':hover': {
                  backgroundColor: '#e74323',
                },
              }}
            >
              End Auto-dial
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          {currentLead && (
            <>
              <CurrentLeadModalContent />
              <CurrentPostSalesLeadComponent>
                <LeadDetailsModal
                  leadAutoDialMode={true}
                  currentLead={currentLead}
                  markInteraction={markInteraction}
                />
              </CurrentPostSalesLeadComponent>
            </>
          )}
          {allLeads?.length === 0 && (
            <Typography
              variant='h4'
              sx={{
                margin: 'auto',
                fontFamily: 'monospace, sans-sirf',
              }}
            >
              No more leads in auto dialer found.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={2}
          direction='row'
          width='50%'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Button
            onClick={getNewLead}
            variant='contained'
            disabled={!nextButtonClickable}
            sx={{
              backgroundColor: '#12b886',
              marginRight: '1vw',
              width: '20%',
            }}
          >
            Next lead
          </Button>
        </Stack>
      </DialogActions>

      <ShowExitAutodialerDialog
        open={endAutodialer}
        onClose={handleClose}
        onSubmit={exitAutoDialHandler}
      />
    </>
  );
}

export default AutoDialerScreen;
