import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import React, { useState } from 'react';
import AdminsTable from './AdminsTable';
import NewAdminModal from './NewAdminModal';
import AdminBulkImportModal from '../../../components/admins/AdminBulkImportModal';
import { useSelector } from 'react-redux';
import { currentAdminIsHr } from 'utils/common';

export default function PortalUsers({
  roles,
  setRoles,
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
}) {
  const [openNewAdminModal, setOpenNewAdminModal] = useState(false);
  const { allAdmins } = useSelector((state) => state.admins);
  const [openAdminBulkImportModal, setOpenAdminBulkImportModal] =
    React.useState(false);

  const refreshAdminsGraph = () => {
    let url = `/api/admins/refresh-graph`;
    axiosInstance
      .get(url)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage(res.data.message);
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occurred while refreshing the admins graph.'
        );
      });
  };

  const handleChange = () => {
    setOpenAdminBulkImportModal((s) => !s);
  };

  return (
    <Box sx={{ mr: 5, mt: 4, marginTop: '0rem' }}>
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <Button
          variant='contained'
          onClick={(e) => refreshAdminsGraph()}
        >
          Refresh admins graph
        </Button>
        <Button
          variant='contained'
          onClick={handleChange}
        >
          Import admins
        </Button>
        <Button
          variant='contained'
          onClick={(e) => setOpenNewAdminModal(true)}
        >
          Add new admin
        </Button>
      </Stack>
      {openAdminBulkImportModal && (
        <AdminBulkImportModal
          {...{
            open: openAdminBulkImportModal,
            onChange: handleChange,
            setIsSuccess,
            setHasError,
            setSuccessMessage,
            setErrorMessage,
          }}
        />
      )}

      {openNewAdminModal && (
        <NewAdminModal
          {...{
            openNewAdminModal,
            setOpenNewAdminModal,
            roles,
          }}
        />
      )}
      {roles?.length > 0 && allAdmins?.length > 0 ? (
        <AdminsTable
          {...{
            roles,
            setSuccessMessage,
            setErrorMessage,
            setIsSuccess,
            setHasError,
          }}
        />
      ) : (
        <Typography
          variant='body1'
          align='center'
        >
          No users found
        </Typography>
      )}
    </Box>
  );
}
