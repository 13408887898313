/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import { getCurrAdmin } from 'features/auth/authSelector';
import React from 'react';

const RealTimeCPETracking = () => {
  const admin = getCurrAdmin();
  const uid = admin['id'];
  const srcURL = `https://kamat.heycoach.in/public/dashboard/65400dc2-008f-4019-b875-be50491ed4a1?id=${uid}#hide_parameters=id`;

  return (
    <iframe
      src={srcURL}
      frameborder='0'
      width='800'
      height='600'
      allowtransparency
    ></iframe>
  );
};

export default RealTimeCPETracking;
