import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControl,
  Stack,
  TextField,
  Typography,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const products = [
  { id: 1, value: 'super30', name: 'Super 30' },
  { id: 2, value: 'personal_coaching', name: 'Personal Coaching' },
];

const CAPTrackingFilters = ({
  drivesDropdown,
  IndependentContributorDropdown,
  managerDropdown,
  filters,
  setFilters,
  initialFilterState,
  setTriggerRefresh,
  triggerRefresh,
  fetchingData,
  getCapDetails,
  currentDriveName,
  currentDriveDates,
}) => {
  const [leadId, setLeadId] = useState('');
  const [expanded, setExpanded] = useState(true);
  const [selectedICs, setSelectedICs] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedDrives, setSelectedDrives] = useState(
    currentDriveName ? [{ name: currentDriveName }] : []
  );
  const [startAt, setStartAt] = useState(
    currentDriveDates?.startsAt
      ? new Date(currentDriveDates?.startsAt).toISOString().split('T')[0]
      : ' '
  );
  const [endAt, setEndAt] = useState(
    currentDriveDates?.endsAt
      ? new Date(currentDriveDates?.endsAt).toISOString().split('T')[0]
      : ' '
  );
  const [product, setProduct] = useState([]);

  useEffect(() => {
    if (currentDriveName) {
      setSelectedDrives([{ name: currentDriveName }]);
    }

    if (currentDriveDates) {
      setStartAt(
        currentDriveDates.startsAt
          ? new Date(currentDriveDates.startsAt).toISOString().split('T')[0]
          : ''
      );
      setEndAt(
        currentDriveDates.endsAt
          ? new Date(currentDriveDates.endsAt).toISOString().split('T')[0]
          : ''
      );
    }
  }, [currentDriveName, currentDriveDates]);

  const findIdByName = (selectedData, name) => {
    const selectedManager = selectedData.find(
      (data) => data.Full_Name === name
    );
    return selectedManager ? selectedManager.Admin_id : null;
  };

  const searchHandler = () => {
    const SelectedICsId = selectedICs
      .map((name) =>
        findIdByName(IndependentContributorDropdown, name.Full_Name)
      )
      .filter((id) => id !== null)
      .join(',');

    const SelectedManagersId = selectedManagers
      .map((name) => findIdByName(managerDropdown, name.Full_Name))
      .filter((id) => id !== null)
      .join(',');

    const selectedDrivesName = selectedDrives
      .map((drive) => drive.name)
      .filter((id) => id !== null)
      .join(',');

    const selectedProduct = product.map((p) => p.value).join(',');
    const updatedFilters = {
      ...filters,
      lead_id: leadId,
      assignee: SelectedICsId,
      managers: SelectedManagersId,
      drive: selectedDrivesName,
      to: startAt,
      from: endAt,
      product: selectedProduct,
    };
    setFilters(updatedFilters);
  };

  const handleReset = () => {
    setFilters(initialFilterState);
    resetStates();
  };

  const resetStates = () => {
    setSelectedICs([]);
    setSelectedManagers([]);
    setSelectedDrives([]);
    setStartAt('');
    setEndAt('');
    setLeadId('');
    setProduct([]);
  };

  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography
            variant='h6'
            component='p'
          >
            Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl
                id='id'
                size='small'
                sx={{ width: 200, marginRight: '15px' }}
              >
                <TextField
                  size='small'
                  type='text'
                  name='id'
                  placeholder='Lead Id'
                  onChange={(event) => setLeadId(event.target.value)}
                  value={leadId}
                />
              </FormControl>

              <Autocomplete
                size='small'
                limitTags={1}
                disablePortal
                sx={{ width: 250, marginRight: '15px' }}
                getOptionLabel={(option) => option.Full_Name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Assignee'
                  />
                )}
                multiple
                id='filtered-select'
                options={IndependentContributorDropdown || []}
                value={selectedICs}
                onChange={(event, newValue) => setSelectedICs(newValue)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.Full_Name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />

              <Autocomplete
                size='small'
                limitTags={1}
                disablePortal
                sx={{ width: 250, marginRight: '15px' }}
                getOptionLabel={(option) => option.Full_Name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Manager'
                  />
                )}
                multiple
                id='filtered-select'
                options={managerDropdown || []}
                value={selectedManagers}
                onChange={(event, newValue) => setSelectedManagers(newValue)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.Full_Name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />

              <Autocomplete
                size='small'
                limitTags={1}
                disablePortal
                sx={{ width: 250 }}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Drive'
                  />
                )}
                multiple
                id='filtered-select'
                options={drivesDropdown || []}
                value={selectedDrives}
                onChange={(event, newValue) => setSelectedDrives(newValue)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option?.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                size='small'
                id='start-date'
                label='From'
                type='date'
                value={startAt}
                onChange={(event) => setStartAt(event.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ width: 200, marginRight: '15px' }}
              />
              <TextField
                size='small'
                id='end-date'
                label='To'
                type='date'
                value={endAt}
                onChange={(event) => setEndAt(event.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{ width: 200, marginRight: '15px' }}
              />
              <Autocomplete
                size='small'
                limitTags={1}
                disablePortal
                sx={{ width: 250, marginRight: '15px' }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Product'
                  />
                )}
                multiple
                id='filtered-select'
                options={products || []}
                value={product}
                onChange={(event, newValue) => setProduct(newValue)}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </Box>

            <Grid
              container
              spacing={2}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={(e) => handleReset(e)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Button
                  variant='contained'
                  fullWidth
                  onClick={() => searchHandler()}
                  disabled={fetchingData}
                >
                  {fetchingData ? 'Loading...!' : 'Search'}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CAPTrackingFilters;
