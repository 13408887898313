import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from 'apis/axiosInstance';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';

const defaultStyle = {
  width: 300,
};

const defaultAdminOptions = {
  access_type: ['allowed', 'restricted'],
};

let selectedAdmin = [];

export default function AdminMultiComplete({
  value,
  onChangeHandler,
  label = 'Admins',
  adminOptions = defaultAdminOptions,
  sx = defaultStyle,
  showAllOption = false,
  showNoneOption = false,
  showCurrentAdmin = false,
  ...restProps
}) {
  const selectAllAdmins = (state) => state.admins.allAdmins;

  const useAllAdmins = () => {
    return useSelector(selectAllAdmins);
  };
  const [inputValue, setInputValue] = useState([]);
  const [admins, setAdmins] = useState([]);
  const { allAdmins } = useAllAdmins();

  useEffect(() => {
    getAdmins();
  }, [allAdmins]);

  useEffect(() => {
    if (value.length === 0) {
      setInputValue([]);
    }
  }, [value]);

  async function getAdmins() {
    try {
      let url = `/api/admins/search`;
      const response = await axiosInstance.get(url, {
        params: { text: inputValue },
      });

      let adminsData = response.data.map((admin) => ({
        id: admin.id,
        label: admin.fullname,
        value: `${admin.id}`,
      }));

      adminsData.unshift({ id: 0, label: 'Select All', value: '0' });

      setAdmins(adminsData);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  }

  const handleOptionChange = (event, updatedAdmin) => {
    const filterSelectedAdmins = selectedAdmin.filter((e) => e.id === 0);
    const filterUpdatedAdmins = updatedAdmin.filter((e) => e.id === 0);

    const isSelectAllOptionSelectedWithZeroSelectedAdmin = () =>
      selectedAdmin.length === 0 && filterUpdatedAdmins.length === 1;

    const isSelectedAdminLengthZeroAndAnyOneOptionSelectedWithoutSelectAll =
      () => selectedAdmin.length === 0 && filterUpdatedAdmins.length === 0;

    const isSelectAllOptionSelected = () =>
      filterSelectedAdmins.length === 0 && filterUpdatedAdmins.length === 1;

    const isSelectAllOptionUnselected = () =>
      filterSelectedAdmins.length === 1 && filterUpdatedAdmins.length === 0;

    const isSelectAllOptionSelectedButUnselectingOtherOption = () =>
      filterSelectedAdmins.length === 1 &&
      filterUpdatedAdmins.length === 1 &&
      selectedAdmin.length - updatedAdmin.length === 1;

    const handleSelectAllOptionSelectedWithZeroSelectedAdmin = () => {
      let data = [...admins];
      data.shift();
      setInputValue(admins);
      onChangeHandler(data.map((adminList) => parseInt(adminList.value, 10)));
      selectedAdmin = [...admins];
    };

    const handleSelectedAdminLengthZeroAndAnyOneOptionSelectedWithoutSelectAll =
      () => {
        setInputValue([...updatedAdmin]);
        onChangeHandler(
          updatedAdmin.map((adminList) => parseInt(adminList.value, 10))
        );
        selectedAdmin = [...updatedAdmin];
      };

    const handleSelectAllOptionSelected = () => {
      let data = [...admins];
      data.shift();
      setInputValue(admins);
      onChangeHandler(data.map((adminList) => parseInt(adminList.value, 10)));
      selectedAdmin = [...admins];
    };

    const handleSelectAllOptionUnselected = () => {
      setInputValue([]);
      onChangeHandler([]);
      selectedAdmin = [];
    };

    const handleSelectAllOptionSelectedButUnselectingOtherOption = () => {
      let data = [...updatedAdmin];
      data.shift();
      setInputValue(data);
      onChangeHandler(data.map((adminList) => parseInt(adminList.value, 10)));
      selectedAdmin = [...data];
    };

    const ifAllConditionsNotTrue = () => {
      setInputValue([...updatedAdmin]);
      onChangeHandler(
        updatedAdmin.map((adminList) => parseInt(adminList.value, 10))
      );
      selectedAdmin = [...updatedAdmin];
    };

    if (isSelectAllOptionSelectedWithZeroSelectedAdmin()) {
      handleSelectAllOptionSelectedWithZeroSelectedAdmin();
      return;
    }

    if (isSelectedAdminLengthZeroAndAnyOneOptionSelectedWithoutSelectAll()) {
      handleSelectedAdminLengthZeroAndAnyOneOptionSelectedWithoutSelectAll();
      return;
    }

    if (isSelectAllOptionSelected()) {
      handleSelectAllOptionSelected();
      return;
    }

    if (isSelectAllOptionUnselected()) {
      handleSelectAllOptionUnselected();
      return;
    }

    if (isSelectAllOptionSelectedButUnselectingOtherOption()) {
      handleSelectAllOptionSelectedButUnselectingOtherOption();
      return;
    }

    ifAllConditionsNotTrue();
  };

  return (
    <Autocomplete
      {...restProps}
      value={inputValue}
      multiple
      id='tags-filled'
      onChange={handleOptionChange}
      options={admins}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label ?? ''}
      getOptionSelected={(option, value) => option.id === value.id}
      freeSolo
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option.id}
        >
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{
            maxHeight: '100px',
            overflowY: 'auto',
          }}
        />
      )}
    />
  );
}
