import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from '../../Mode';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import InfoIcon from '@mui/icons-material/Info';
import HelperModal from './HelperModal';
import {
  isValidEmailId,
  isValidLinkedinURL,
  hasFullAccessMode,
  getCurrentAdminId,
  isValidPhoneNumber,
  hasBasicAccessMode,
  hasRestrictedAccessMode,
  getAccessMode,
  getStatusLabel,
  getCurrentAdminRole,
  getSubordinateAdminIds,
} from '../../../utils/common';
import {
  IconButton,
  InputAdornment,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import constants from '../../../utils/constants';
const {
  ADMIN,
  INDEPENDENT_CONTRIBUTOR: IC,
  MANAGER,
  SENIOR_MANAGER,
  MARKETING,
  PROGRAM_MANAGER: PM,
  FINANCE,
  TRAINEE,
} = constants;

const validateSearchText = (searchText) => {
  const validationFunctions = [
    isValidLinkedinURL,
    isValidEmailId,
    isValidPhoneNumber,
  ];

  let ok = hasFullAccessMode();

  // lead ids are searched using "#" prefix
  if (!hasFullAccessMode() && searchText[0] !== '#') {
    validationFunctions.forEach((func) => {
      if (func(searchText)) {
        ok = true;
      }
    });
  } else if (hasRestrictedAccessMode() && searchText[0] === '#') {
    ok = true;
  }

  return ok;
};

export default function LeadSearchBar({ getLeadDetails }) {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onHoverLeadId, setOnHoverLeadId] = useState(-1);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldSearch, setShouldSearch] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const rolesToShowLeadName = [ADMIN, IC, MANAGER, SENIOR_MANAGER];
  const fixedRolesChecks = [IC, MANAGER, TRAINEE];

  useEffect(() => {
    if (searchText.length > 0 && shouldSearch) {
      searchLead();
    }
    return () => {};
  }, [shouldSearch]);

  const shouldShowLeadAssineeNameAndStatus = (
    assignee,
    assigneeNameOrStatus
  ) => {
    const rolesCheckToShowAssigneNameAndStatus =
      getCurrentAdminRole().filter((role) => fixedRolesChecks.includes(role))
        .length > 0;
    const verifyAdminIdsForLeadStatus =
      getSubordinateAdminIds().includes(assignee);

    if (rolesCheckToShowAssigneNameAndStatus) {
      if (rolesCheckToShowAssigneNameAndStatus && verifyAdminIdsForLeadStatus) {
        return assigneeNameOrStatus;
      }
      return '';
    } else {
      return assigneeNameOrStatus;
    }
  };

  const searchLead = () => {
    if (
      !validateSearchText(searchText) &&
      getAccessMode().includes(constants.BASIC)
    ) {
      setHasError(true);
      setErrorMessage('You dont have permission to use this feature.');
      setShouldSearch(false);
      return;
    }

    if (!validateSearchText(searchText)) {
      setHasError(true);
      setErrorMessage('Invalid search text');
      setShouldSearch(false);
      return;
    }

    axiosInstance
      .get(`/api/users/search?search_text=${encodeURIComponent(searchText)}`)
      .then((res) => {
        const { data = [] } = res;
        const results = data.map((datum) => {
          const { id, name, assignee_name, assignee, status } = datum;
          return {
            assignee,
            label:
              getCurrentAdminRole().filter((role) =>
                rolesToShowLeadName.includes(role)
              ).length > 0
                ? `${name} #${id}`
                : `#${id}`,
            value: id,
            assigneeName: assignee_name
              ? `${shouldShowLeadAssineeNameAndStatus(assignee, assignee_name)}`
              : 'No assignee',
            status: shouldShowLeadAssineeNameAndStatus(assignee, status),
          };
        });
        setSearchResults(results);
        setOpenPopper(true);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't search for the user"
        );
      })
      .finally(() => {
        setShouldSearch(false);
      });
  };

  const clearSearchText = () => {
    setSearchText('');
    setOpenPopper(false);
    setSearchResults([]);
    setAnchorEl(null);
    setOnHoverLeadId(-1);
  };

  const canSelectOptions = (result) => {
    if (hasFullAccessMode() || hasRestrictedAccessMode()) {
      return true;
    }
    if (hasBasicAccessMode() && getCurrentAdminId() === result.assignee) {
      return true;
    }
    return false;
  };

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      <TextField
        placeholder={getRandomPlaceholder()}
        variant='standard'
        value={searchText}
        onKeyDown={(e) => {
          const key = e.keyCode || e.charCode;
          if (key == 8 || key == 46) {
            // listens for 'backspace' and 'delete'
            setOpenPopper(false);
          }
          if (key === 13) {
            // listens for 'enter' key to make search request
            setShouldSearch(true);
          }
        }}
        onChange={(e) => {
          setAnchorEl(e.currentTarget);
          setSearchText(e.target.value);
        }}
        sx={{
          width: {
            xs: '200px',
            sm: '270px',
            md: '40vw',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={searchText.length === 0}
                onClick={(e) => clearSearchText()}
              >
                <CloseIcon />
              </IconButton>

              <IconButton
                disabled={searchText.length === 0}
                onClick={(e) => setShouldSearch(true)}
              >
                <SearchIcon />
              </IconButton>

              <IconButton onClick={(e) => setOpenHelpModal(true)}>
                <InfoIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement='bottom'
        style={{
          zIndex: 1300,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            left: '7vh',
            border: 1,
            width: {
              xs: '160px',
              sm: '240px',
              md: '490px',
            },
            pt: 1.5,
            backgroundColor: 'white',
            maxHeight: '40vh',
            overflow: 'auto',
          }}
        >
          {searchResults.length === 0 && (
            <Typography
              variant='body1'
              component='p'
              textAlign='center'
            >
              No results found
            </Typography>
          )}
          {searchResults.length > 0 &&
            searchResults.map((result) => {
              return (
                <Typography
                  variant='body1'
                  component='p'
                  onClick={(e) => {
                    if (canSelectOptions(result)) {
                      getLeadDetails(result.value);
                    }
                  }}
                  onMouseEnter={(e) => {
                    setOnHoverLeadId(result.value);
                  }}
                  sx={{
                    cursor: !canSelectOptions(result) ? 'default' : 'pointer',
                    backgroundColor:
                      onHoverLeadId === result.value ? '#ebf8ff' : 'default',
                    ml: 1,
                  }}
                >
                  <div
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{result.label}</span>
                    <span
                      style={{
                        paddingRight: '10px',
                      }}
                    >
                      {getStatusLabel(result.status, false, {
                        fontSize: '9px',
                      })}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <span
                      style={{
                        paddingRight: '10px',
                        color:
                          result.assigneeName !== 'No assignee'
                            ? '#512da8'
                            : '#b1aeae',
                      }}
                    >
                      {result.assigneeName}
                    </span>
                  </div>
                  <hr class='MuiDivider-root MuiDivider-fullWidth css-9mgopn-MuiDivider-root' />
                </Typography>
              );
            })}
        </Box>
      </Popper>

      {openHelpModal && (
        <HelperModal {...{ openHelpModal, setOpenHelpModal }} />
      )}
    </Stack>
  );
}

const getRandomPlaceholder = () => {
  const placeHolders = {
    [constants.FULL]: [
      'Type lead id to search a lead.',
      'Type lead name to search a lead',
      'Type here to check if a lead exists',
      'Search with [one of the eligible fields - email, mobile, whatsapp, linkedin url, lead id, lead name]',
    ],
    [constants.RESTRICTED]: [
      'Type lead id to search a lead.',
      'Type here to check if a lead exists',
      'Search with [one of the eligible fields - email, mobile, whatsapp, linkedin url, lead name]',
    ],
    [constants.BASIC]: [
      'Type here to check if a lead exists',
      'Search with [one of the eligible fields - email, mobile, whatsapp, linkedin url]',
    ],
  };

  const currentAccessModes = getAccessMode();
  let accessMode = constants.BASIC;

  if (currentAccessModes.includes(constants.FULL)) {
    accessMode = constants.FULL;
  } else if (currentAccessModes.includes(constants.RESTRICTED)) {
    accessMode = constants.RESTRICTED;
  }

  const randomIndex = Math.floor(
    Math.random() * (placeHolders[accessMode] ?? []).length
  );
  return placeHolders[accessMode][randomIndex];
};
