import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import WorkLogCalendarView from './WorkLog/WorkLogCalendarView';
import { Box, Button, Typography } from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import constants from '../../utils/constants';
import { getCurrAdmin } from 'features/auth/authSelector';
import { updateCurrAdmin } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';

const ReportsInRestrictedMode = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const admin = getCurrAdmin();
  const [missingDates, setMissingDates] = useState('');
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getMissingReportsDates();
    return () => {};
  }, []);

  const getMissingReportsDates = () => {
    axiosInstance
      .get(`/api/reports/missing-reports-date`)
      .then((res) => {
        const missingReportsData = res.data;
        setMissingDates(missingReportsData[0]?.Missing_Report_Dates);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not get missing date.'
        );
      });
  };

  const updateAdminAccess = () => {
    const adminId = admin?.id;
    const body = { access: constants.RESTRICTED };
    axiosInstance
      .patch(`/api/subordinate/${adminId}`, body)
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Admin successfully updated');
        const updatedAdmin = {
          ...admin,
          access_type: constants.ALLOWED,
          missingReportsFlag: false,
        };
        dispatch(updateCurrAdmin(updatedAdmin));
        setShouldNavigate(true);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the admin.'
        );
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {shouldNavigate && (
        <Navigate
          to='/bucket-search'
          replace={true}
        />
      )}

      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'black',
          fontSize: '24px',
          marginLeft: '9%',
          marginTop: '10px',
        }}
      >
        Work Log
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
          marginLeft: '9%',
          marginRight: '9%',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            color: 'blue',
            fontSize: '20px',
          }}
        >
          Your missing reports dates : {missingDates}
        </Typography>
        <Button
          variant='contained'
          sx={{ width: '100px' }}
          onClick={() => updateAdminAccess()}
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ marginLeft: '8%', marginRight: '8%', marginTop: '20px' }}>
        <WorkLogCalendarView />
      </Box>
    </>
  );
};

export default React.memo(ReportsInRestrictedMode);
