import React from 'react';
import { FollowUpTimeConfirmationModal } from '../FollowUpTimeConformationModal';

const FollowUpTimeConfirmationHandler = ({
  openFollowUpTimeConfirmationModal,
  setOpenFollowUpTimeConfirmationModal,
  addInteraction,
}) => (
  <>
    {openFollowUpTimeConfirmationModal && (
      <FollowUpTimeConfirmationModal
        {...{
          openFollowUpTimeConfirmationModal,
          setOpenFollowUpTimeConfirmationModal,
          addInteraction,
        }}
      />
    )}
  </>
);

export default FollowUpTimeConfirmationHandler;
