import React, { useState, useEffect } from 'react';
import { Box, ButtonGroup, Button, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from 'apis/axiosInstance';
import EditIcon from '@mui/icons-material/Edit';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';

const UsersTable = ({
  data,
  setData,
  loading,
  setLoading,
  setIsSuccess,
  setSuccessMessage,
  setOpenFullScreenDialog,
  setSelectedUser,
}) => {
  const [rows, setRows] = useState(data);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setRows(data);
  }, [data]);

  const columns = [
    {
      field: 'user_id',
      headerName: 'S.No',
      width: 60,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 350,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 180,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 240,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const states = ['active', 'inactive'];
        return (
          <ButtonGroup
            color='primary'
            size='small'
          >
            {states.map((state) => (
              <Button
                key={state}
                variant={params.row.status === state ? 'contained' : 'outlined'}
                onClick={() => updateUserStatus(params.row, state)}
              >
                {state}
              </Button>
            ))}
          </ButtonGroup>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={() => handleEditClick(params.row)}
          color='primary'
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const updateUserStatus = async (row, status) => {
    const updatedRows = rows.map((item) =>
      item.user_id === row.user_id ? { ...item, status } : item
    );
    setRows(updatedRows);

    try {
      setIsTableLoading(true);
      const response = await axiosInstance.patch(
        `/api/v1/access-table/${row.user_id}/users`,
        {
          data: { status },
        }
      );

      if (response.status === 200) {
        setIsSuccess(true);
        setSuccessMessage('Status updated successfully');
        const updatedData = data.map((item) =>
          item.user_id === row.user_id ? { ...item, status } : item
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error('Error updating user status:', error);
      setHasError(true);
      setErrorMessage('You can not update your status');

      // Rollback UI to previous state
      const revertedRows = rows.map((item) =>
        item.user_id === row.user_id ? { ...item, status: row.status } : item
      );
      setRows(revertedRows);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleEditClick = (row) => {
    setSelectedUser(row);
    setOpenFullScreenDialog(true);
  };

  return (
    <Box
      width='inherit'
      height='100%'
      sx={{
        mt: 5,
        '& .access.allowed': {
          color: 'green',
          fontWeight: '600',
        },
        '& .access.disabled': {
          color: 'red',
          fontWeight: '600',
        },
      }}
    >
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.user_id}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '15px',
          },
        }}
      />
    </Box>
  );
};

export default UsersTable;
