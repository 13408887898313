import axiosInstance from 'apis/axiosInstance';
import constants from '../../utils/constants';
const {
  NOT_CALLED,
  INITITATED,
  STARTED,
  ANSWERED,
  MISSED,
  HANGUP,
  HANGUPWITHANSWERED,
} = constants;

const callNotificationsArray = [
  NOT_CALLED,
  INITITATED,
  STARTED,
  ANSWERED,
  HANGUP,
  MISSED,
];

export const handleMakeCallLogicUsingTelecmi = async (currentLead) => {
  // Proceed with initiating the call
  const leadDetail = {
    leadId: currentLead?.id,
    mode: 'telecmi',
    DNPObject: {},
  };

  try {
    const response = await axiosInstance.post(
      '/api/telecmi_initiate_call',
      leadDetail
    );

    if (response?.data?.success) {
      return {
        action: 'call_initiated',
        successMessage: 'Call is initiated.',
      };
    } else {
      return {
        action: 'error',
        errorMessage: 'Something went wrong, call cannot be initiated.',
      };
    }
  } catch (error) {
    console.error('Error initiating call:', error);
    return {
      action: 'error',
      errorMessage:
        error.response?.data?.message ||
        'Something went wrong, call cannot be initiated.',
    };
  }
};

export const getCurrentIncomingCallStatus = async (
  leadId,
  setInterectionBoxes,
  setHasError,
  setErrorText
) => {
  try {
    await axiosInstance.get(`/api/call-incoming-status`).then((res) => {
      const currentStatus = res.data;
      if (currentStatus == ANSWERED) {
        setInterectionBoxes(true);
      }
      if (currentStatus == HANGUPWITHANSWERED) {
        const postData = {
          leadId, //  leadId: currentLead?.id,
          status: ANSWERED,
        };
        axiosInstance.post(`/api/interaction-box-visibility`, postData);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentCallStatus = (
  leadId,
  handleError,
  handleHangupFunction,
  setInterectionBoxes,
  clearCallData,
  setCallHappening,
  setIsSuccess,
  setSuccessText,
  updateCurrentLeadContexts,
  pollingRef,
  isPooling
) => {
  axiosInstance
    .get(`/api/call-status/${leadId}`)
    .then((res) => {
      console.log('res ->>>>>', res?.data);
      const { hangup_lead, hangup_admin, status_lead, status_admin } =
        res.data.data;

      console.log('res.data.data=>>>> ', res.data.data);
      if (status_admin == INITITATED) {
        handleStatusChange(
          INITITATED,
          'a',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      } else if (status_lead == STARTED) {
        handleStatusChange(
          STARTED,
          'b',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      } else if (status_admin == ANSWERED) {
        handleStatusChange(
          ANSWERED,
          'a',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      } else if (status_lead == ANSWERED) {
        localStorage.setItem('callStatus', status_lead);
        handleStatusChange(
          ANSWERED,
          'b',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef,
          hangup_lead
        );
      } else if (status_lead == HANGUP) {
        handleStatusChange(
          HANGUP,
          'b',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      } else if (status_lead == MISSED) {
        handleStatusChange(
          MISSED,
          'b',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      } else if (status_admin == MISSED) {
        handleStatusChange(
          MISSED,
          'a',
          setCallHappening,
          setIsSuccess,
          setSuccessText,
          setInterectionBoxes,
          clearCallData,
          handleHangupFunction,
          updateCurrentLeadContexts,
          pollingRef
        );
      }

      console.log(
        'isNextPolling(status_admin, status_lead) >>>>',
        isNextPolling(status_admin, status_lead)
      );
      if (isPooling) {
        setTimeout(() => {
          getCurrentCallStatus(
            leadId,
            handleError,
            handleHangupFunction,
            setInterectionBoxes,
            clearCallData,
            setCallHappening,
            setIsSuccess,
            setSuccessText,
            updateCurrentLeadContexts,
            pollingRef,
            isNextPolling(status_admin, status_lead)
          );
        }, 15000);
      }
    })
    .catch((err) => handleError(err));
};

const handleStatusChange = (
  currentStatus,
  leg,
  setCallHappening,
  setIsSuccess,
  setSuccessText,
  setInterectionBoxes,
  clearCallData,
  handleHangupFunction,
  updateCurrentLeadContexts,
  pollingRef,
  hangup_lead = null
) => {
  try {
    switch (currentStatus) {
      case INITITATED:
        updateCallState(
          true,
          false,
          'Call initiated.',
          setCallHappening,
          setIsSuccess,
          setSuccessText
        );
        break;

      case STARTED:
        updateCallState(
          true,
          false,
          'Call is ringing.',
          setCallHappening,
          setIsSuccess,
          setSuccessText
        );
        break;

      case ANSWERED:
        if (leg == 'b') {
          updateCallState(
            true,
            false,
            'Call is answered.',
            setCallHappening,
            setIsSuccess,
            setSuccessText
          );
          setInterectionBoxes(true);
        }
        break;

      case MISSED:
        updateCallState(
          false,
          false,
          'Call has ended.',
          setCallHappening,
          setIsSuccess,
          setSuccessText
        );
        console.log('Call has ended', pollingRef);
        if (pollingRef.current) {
          pollingRef.current = null;
        }
        clearCallData();
        updateCurrentLeadContexts();
        break;

      case HANGUP:
        handleHangupFunction(hangup_lead);
        updateCallState(
          false,
          true,
          'Call has ended.',
          setCallHappening,
          setIsSuccess,
          setSuccessText
        );
        console.log('Call has end', pollingRef);
        if (pollingRef.current) {
          pollingRef.current = null;
        }
        clearCallData();
        updateCurrentLeadContexts();
        break;

      default:
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

const isNextPolling = (status_admin, status_lead) => {
  const isAdminHangupOrMissed = [HANGUP, MISSED].includes(status_admin);
  const isLeadHangupOrMissed = [HANGUP, MISSED, NOT_CALLED].includes(
    status_lead
  );
  if (isAdminHangupOrMissed) {
    if (isLeadHangupOrMissed) {
      return false;
    } else {
      return true;
    }
  }

  return true;
};

export const updateCallState = (
  isHappening,
  isHangup,
  successText,
  setCallHappening,
  setIsSuccess,
  setSuccessText
) => {
  setCallHappening(isHappening);
  setIsSuccess(true);
  setSuccessText(successText);
  isHangup(true);
};

export const getTelecmiEnabledUsers = (
  setTelecmiEnabledUserss,
  setHasError,
  setErrorText
) => {
  axiosInstance
    .get('/api/telecmi_users')
    .then((response) => {
      if (response?.data) {
        setTelecmiEnabledUserss(response?.data);
      }
    })
    .catch((error) => {
      setHasError(true);
      setErrorText(
        error.response?.data?.message ?? 'Something went wrong, fetch cmi users'
      );
      console.error('error in fetching telecmi users', error);
    });
};
