import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import UsersTable from 'components/accessTable/UsersTable';
import AddAdminModal from 'components/accessTable/AddAdminModal';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import FullScreenUserDailog from 'components/accessTable/FullScreenUserDailog';
import AddIcon from '@mui/icons-material/Add';

const AccessTableUsers = () => {
  const [role, setRole] = React.useState('all');
  const [openAddPopup, setOpenAddPopup] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openFullScreenDailog, setOpenFullScreenDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});

  useEffect(() => {
    getAllAccessTableUsers();
  }, [openAddPopup, isSuccess]);

  // Filter data whenever `role` or `data` changes
  useEffect(() => {
    if (role === 'all') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((user) => user.role === role));
    }
  }, [role, data]);

  const getAllAccessTableUsers = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get('api/v1/access-table/users');
      setData(res?.data?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Stack>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0 }}>
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel id='role'>Roles</InputLabel>
          <Select
            labelId='role'
            id='role'
            value={role}
            label='Role'
            size='small'
            onChange={(e) => setRole(e.target.value)}
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={'admin'}>Admin</MenuItem>
            <MenuItem value={'users'}>User</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => setOpenAddPopup(true)}
        >
          Add Users
        </Button>
      </Box>
      {/* modal */}
      {openAddPopup && (
        <AddAdminModal
          openAddPopup={openAddPopup}
          setOpenAddPopup={setOpenAddPopup}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setHasError={setHasError}
        />
      )}
      {/* Table */}
      <UsersTable
        data={filteredData}
        setData={setData}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
        loading={loading}
        setHasError={setHasError}
        setLoading={setLoading}
        setSelectedUser={setSelectedUser}
        setOpenFullScreenDialog={setOpenFullScreenDialog}
      />
      {/* user modal */}
      {openFullScreenDailog && (
        <FullScreenUserDailog
          setOpenFullScreenDialog={setOpenFullScreenDialog}
          selectedUser={selectedUser}
          openFullScreenDailog={openFullScreenDailog}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          setHasError={setHasError}
        />
      )}
    </Stack>
  );
};

export default AccessTableUsers;
