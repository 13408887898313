import { Box, Button, Tab, Tabs } from '@mui/material';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import { TabPanel } from 'pages/Admins/AdminDashboard';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessTableFeatures from './AccessTableFeatures';
import AccessTableGroups from './AccessTableGroups';
import AccessTableUsers from './AccessTableUsers';

const AccessTableHome = () => {
  const [roles, setRoles] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };
  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant='scrollable'
        >
          <Tab label='Features'></Tab>
          <Tab label='Groups'></Tab>
          <Tab label='Users'></Tab>
        </Tabs>
        <TabPanel
          value={currentTab}
          index={0}
        >
          {/* <h1>Features</h1> */}
          <AccessTableFeatures />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={1}
        >
          {/* <h1>Groups</h1> */}
          <AccessTableGroups />
        </TabPanel>
        <TabPanel
          value={currentTab}
          index={2}
        >
          {/* <h1>Users</h1> */}
          <AccessTableUsers />
        </TabPanel>
      </Box>
    </>
  );
};

export default AccessTableHome;
