import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { isValidEmailId, isValidPhoneNumber } from '../../utils/common';

const PersonalInfo = ({
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  formSubmitted,
}) => {
  useEffect(() => {
    let errors = {};
    if (!formValues.fname) {
      errors.fname = 'First name is required';
    }
    if (formValues.email && !isValidEmailId(formValues.email)) {
      errors.email = 'Enter a valid email';
    }
    if (!isValidPhoneNumber(formValues.mobile)) {
      errors.mobile = 'Enter a Valid mobile number';
    }
    if (formValues.whatsapp && !isValidPhoneNumber(formValues.whatsapp)) {
      errors.whatsapp = 'Enter a Valid mobile number';
    }
    setFormErrors(errors);
  }, [formValues, setFormErrors]);
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      mobile_int: formValues.mobile,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      py={2}
      px={2}
      sx={{ background: '#E2EEFE' }}
    >
      <Grid
        item
        xs={12}
        py={2}
      >
        <Typography variant='h6'>Personal Information</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='fname'
            label='First Name'
            value={formValues.fname}
            onChange={handleChange}
          />
        </FormControl>
        <Typography
          color={formSubmitted && formErrors.fname ? 'error' : 'initial'}
          variant='body2'
        >
          {formSubmitted && formErrors.fname}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='lname'
            label='Last Name'
            value={formValues.lname}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='email'
            label='Email Address'
            value={formValues.email}
            onChange={handleChange}
          />
        </FormControl>
        <Typography
          color={formSubmitted && formErrors.email ? 'error' : 'initial'}
          variant='body2'
        >
          {formSubmitted && formErrors.email}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='number'
            name='mobile'
            label='Mobile Number'
            value={formValues.mobile}
            onChange={handleChange}
          />
        </FormControl>
        <Typography
          color={formSubmitted && formErrors.mobile ? 'error' : 'initial'}
          variant='body2'
        >
          {formSubmitted && formErrors.mobile}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl fullWidth>
          <TextField
            type='number'
            name='whatsapp'
            label='Whatsapp Number'
            value={formValues.whatsapp}
            onChange={handleChange}
          />
        </FormControl>
        <Typography
          color={formSubmitted && formErrors.whatsapp ? 'error' : 'initial'}
          variant='body2'
        >
          {formSubmitted && formErrors.whatsapp}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <TextField
            type='text'
            name='linkedin_link'
            label='LinkedIn'
            value={formValues.linkedin_link}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
