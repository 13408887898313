import { Dialog } from '@mui/material';
import React, { useEffect } from 'react';
import AutoDialerScreen from './AutoDialerScreen';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutodialLeads } from 'features/autoDialer/autoDailerThunk';
import CustomBackdrop from 'pages/CustomBackdrop';

function AutoDialerDialog() {
  const dispatch = useDispatch();
  const { allLeads } = useSelector((state) => state.autoDialer);
  const { currAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAutodialLeads());
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={currAdmin?.autodialer_access}
        fullScreen
        sx={{
          '& .MuiDialogContent-root': {
            overflowY: 'hidden',
            height: '100%',
            padding: '0px',
          },
          '& .MuiDialog-container': {
            padding: '40px 120px',
            backgroundColor: '#3cb371',
            position: 'relative',
          },
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        }}
      >
        {allLeads && allLeads.length > 0 ? (
          <AutoDialerScreen />
        ) : (
          <CustomBackdrop open={true} />
        )}
      </Dialog>
    </>
  );
}

export default AutoDialerDialog;
