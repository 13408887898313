import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import axiosInstance from 'apis/axiosInstance';
import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import AddIcon from '@mui/icons-material/Add';
import CommonModal from 'components/common/commonModal';
import { TextField } from '@mui/material';
const FullScreenFeatureDailog = ({
  selectedFeature,
  setOpenFullScreenDialog,
  openFullScreenDailog,
  setIsSuccess,
  setSuccessMessage,
  setHasError,
}) => {
  const [featureData, setFeatureData] = React.useState({});
  const [selectedUserForUpdate, setSelectedUserForUpdate] = React.useState([]);
  const [selectedGroupsForUpdate, setSelectedGroupsForUpdate] = React.useState(
    []
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);
  const [inactiveUsers, setInactiveUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [openAddGroupModal, setOpenAddGroupModal] = React.useState(false);
  const [inactiveGroups, setInactiveGroups] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [editedFeatureName, setEditedFeatureName] = React.useState(
    selectedFeature?.feature_name || ''
  );
  const [isUpdating, setIsUpdating] = React.useState(false);

  React.useEffect(() => {
    if (selectedFeature?.feature_id) {
      getUserById(selectedFeature?.feature_id);
    }
  }, [selectedFeature?.feature_id]);

  const handleNameChange = (event) => {
    setEditedFeatureName(event.target.value);
  };

  const handleUpdateFeatureName = async () => {
    if (editedFeatureName !== selectedFeature.feature_name) {
      setIsUpdating(true);
      try {
        const response = await axiosInstance.patch(
          `/api/v1/access-table/${selectedFeature.feature_id}/features`,
          { data: { name: editedFeatureName } }
        );

        if (response.status === 200) {
          setIsSuccess(true);
          setSuccessMessage('Feature name updated successfully');

          setFeatureData((prevData) => ({
            ...prevData,
            feature_name: editedFeatureName,
          }));

          selectedFeature.feature_name = editedFeatureName;
        }
      } catch (error) {
        console.error('Failed to update group name:', error);
        setHasError(true);
        setErrorMessage('Error updating group name');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const getUserById = async (featureId) => {
    const response = await axiosInstance.get(
      `/api/v1/access-table/${featureId}/features`
    );
    setFeatureData(response?.data?.data?.data);
    setSelectedUserForUpdate(
      response?.data?.data?.data?.feature_details?.feature_users || []
    );
    setSelectedGroupsForUpdate(
      response?.data?.data?.data?.feature_details?.feature_groups || []
    );
  };

  const handleClose = () => {
    setOpenFullScreenDialog(false);
  };

  const allGroups = featureData?.feature_details?.feature_groups;
  const allUsers = featureData?.feature_details?.feature_users;

  const handleUpdate = async () => {
    try {
      // Identify removed and active users
      const removedUsers = allUsers
        .filter(
          (originalFeature) =>
            !selectedUserForUpdate.some(
              (selectedFeature) =>
                selectedFeature.user_id === originalFeature.user_id
            )
        )
        .map((feature) => ({
          user_id: feature.user_id,
          status: 'inactive',
        }));

      // Identify removed and active groups
      const removedGroups = allGroups
        .filter(
          (originalGroup) =>
            !selectedGroupsForUpdate.some(
              (selectedGroup) =>
                selectedGroup.group_id === originalGroup.group_id
            )
        )
        .map((group) => ({
          group_id: group.group_id,
          status: 'inactive',
        }));

      // Construct payload conditionally
      const data = {};
      if (removedUsers.length > 0) {
        data.users = [...removedUsers];
      } else {
        data.groups = [...removedGroups];
      }

      if (!data.users && !data.groups) {
        console.log('No changes to update');
        return;
      }

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedFeature?.feature_id}/features`,
        { data }
      );

      if (response.data.code === 200) {
        setIsSuccess(true);
        setSuccessMessage(response.data.message);
      }

      handleClose();
    } catch (error) {
      setHasError(true);
      setErrorMessage('Error while updating access');
      console.error('Error updating user or group:', error);
    }
  };

  const handleUserToggle = (feature) => {
    setSelectedUserForUpdate((prevSelectedFeatures) => {
      const isAlreadyActive = prevSelectedFeatures.some(
        (selected) => selected.user_id === feature.user_id
      );
      if (isAlreadyActive) {
        return prevSelectedFeatures.filter(
          (selected) => selected.user_id !== feature.user_id
        );
      } else {
        return [...prevSelectedFeatures, feature];
      }
    });
  };

  const handleGroupToggle = (group) => {
    setSelectedGroupsForUpdate((prevSelectedGroups) => {
      const isAlreadyActive = prevSelectedGroups.some(
        (selected) => selected.group_id === group.group_id
      );
      if (isAlreadyActive) {
        return prevSelectedGroups.filter(
          (selected) => selected.group_id !== group.group_id
        );
      } else {
        return [...prevSelectedGroups, group];
      }
    });
  };

  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const handleOpenAddGroupModal = () => {
    setOpenAddGroupModal(true);
  };

  const handleCloseAddGroupModal = () => {
    setOpenAddGroupModal(false);
  };

  // Inside modal functionality
  React.useEffect(() => {
    if (openAddUserModal) {
      fetchActiveUsers();
    }
    if (openAddGroupModal) {
      fetchActiveGroups();
    }
  }, [openAddUserModal, openAddGroupModal]);

  const fetchActiveUsers = async () => {
    try {
      const featureUsers = featureData.feature_details.feature_users || [];
      const allUsers = featureData.users_and_groups.users || [];
      const inactiveUsers = allUsers.filter(
        (user) =>
          !featureUsers.some(
            (featureUser) => featureUser.fullName === user.name
          )
      );
      setInactiveUsers(inactiveUsers);
    } catch (error) {
      console.error('Error fetching inactive users:', error);
    }
  };

  const fetchActiveGroups = async () => {
    try {
      const featureGroups = featureData.feature_details.feature_groups || [];
      const allGroups = featureData.users_and_groups.groups || [];
      const inactiveGroups = allGroups.filter(
        (group) =>
          !featureGroups.some(
            (featureGroup) => featureGroup.group_name === group.group_name
          )
      );
      setInactiveGroups(inactiveGroups);
    } catch (error) {
      console.error('Error fetching inactive Groups:', error);
    }
  };

  const handleUserFeatureToggle = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };
  const handleGroupFeatureToggle = (userId) => {
    setSelectedGroups((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const handleAddUser = async () => {
    try {
      const usersPayload = selectedUsers.map((user_id) => ({
        user_id,
        status: 'active',
      }));

      const payload = {
        data: {
          users: usersPayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedFeature?.feature_id}/features`,
        payload
      );

      if (response.data.code === 200) {
        if (usersPayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('Feature User Updated Sucessfully');
        }
        fetchActiveUsers();
        await getUserById(selectedFeature?.feature_id);
        handleCloseAddUserModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const handleAddGroup = async () => {
    try {
      const groupsPayload = selectedGroups.map((group_id) => ({
        group_id,
        status: 'active',
      }));

      const payload = {
        data: {
          groups: groupsPayload,
        },
      };

      const response = await axiosInstance.patch(
        `/api/v1/access-table/${selectedFeature?.feature_id}/features`,
        payload
      );
      if (response.data.code === 200) {
        if (groupsPayload.length > 0) {
          setIsSuccess(true);
          setSuccessMessage('Feature Groups Updated Sucessfully');
        }
        fetchActiveGroups();
        await getUserById(selectedFeature?.feature_id);
        handleCloseAddGroupModal();
      } else {
        console.error('Unexpected response:', response.data);
      }
    } catch (error) {
      console.error('Error updating users:', error);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '900px', // Medium size
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: 2,
  };

  return (
    <React.Fragment>
      {/* {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )} */}

      {/* <SuccessNotifier {...{ message: setSuccessMessage, setIsSuccess }} /> */}

      <Modal
        open={openFullScreenDailog}
        onClose={handleClose}
        aria-labelledby='user-modal-title'
        aria-describedby='user-modal-description'
      >
        <Box sx={modalStyle}>
          <Box align='right'>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              mx: 3,
            }}
          >
            <TextField
              id='editable-group-name'
              variant='standard'
              value={editedFeatureName}
              onChange={handleNameChange}
              onBlur={handleUpdateFeatureName}
              size='small'
              fullWidth
            />
          </Box>

          <Container sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
              <Typography>Status: {selectedFeature?.status}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              {/* Users Section */}
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Users
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddUserModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>

                {allUsers?.map((user) => (
                  <Box
                    key={user.user_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {user.fullName}
                    </Typography>
                    {/* <Switch
                      checked={selectedUserForUpdate.some(
                        (userFeature) => userFeature.user_id === user.user_id
                      )}
                      onChange={() => handleUserToggle(user)}
                      color='primary'
                    /> */}
                  </Box>
                ))}
              </Box>
              <CommonModal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                title='Add Users'
                onAction={handleAddUser}
                actionText='Add Users'
              >
                <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {inactiveUsers.length === 0 ? (
                    <Typography>No Active Users Found</Typography>
                  ) : (
                    inactiveUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mb: 1,
                          p: 1,
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <Typography sx={{ flex: 1 }}>{user.name}</Typography>
                        <Switch
                          checked={selectedUsers.includes(user.user_id)}
                          onChange={() => handleUserFeatureToggle(user.user_id)}
                          color='primary'
                        />
                      </Box>
                    ))
                  )}
                </Box>
              </CommonModal>

              {/* Groups Section */}
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 2,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    component='h3'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Groups
                  </Typography>
                  <IconButton
                    onClick={handleOpenAddGroupModal}
                    color='primary'
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {allGroups?.map((group) => (
                  <Box
                    key={group.group_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      px: 1,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        flex: 1,
                        fontSize: '0.9rem',
                        wordBreak: 'break-word',
                      }}
                    >
                      {group.group_name}
                    </Typography>
                    <Switch
                      checked={selectedGroupsForUpdate.some(
                        (userGroup) => userGroup.group_id === group.group_id
                      )}
                      onChange={() => handleGroupToggle(group)}
                      color='primary'
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <CommonModal
              open={openAddGroupModal}
              onClose={handleCloseAddGroupModal}
              title='Add Groups'
              onAction={handleAddGroup}
              actionText='Add Groups'
            >
              <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                {inactiveGroups.length === 0 ? (
                  <Typography>No Inactive Groups Found</Typography>
                ) : (
                  inactiveGroups.map((group) => (
                    <Box
                      key={group.user_id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1,
                        p: 1,
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>
                        {group.group_name}
                      </Typography>
                      <Switch
                        checked={selectedGroups.includes(group.group_id)}
                        onChange={() =>
                          handleGroupFeatureToggle(group.group_id)
                        }
                        color='primary'
                      />
                    </Box>
                  ))
                )}
              </Box>
            </CommonModal>

            <Box sx={{ mt: 3 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleUpdate}
                sx={{ width: '100%' }}
              >
                Update
              </Button>
            </Box>
          </Container>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default FullScreenFeatureDailog;
