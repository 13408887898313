import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { Box, ListItemIcon, ListItemText } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useSocket } from '../../socket/socketContext';
import { getCurrentAdminId } from '../../utils/common';
import { logout, setToken } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { persistor } from '../../redux/store';

function Logout() {
  const [socket, disconnectSocket] = useSocket();
  const history = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  function logoutFnc() {
    if (socket) {
      disconnectSocket();
    }
    const userId = getCurrentAdminId();
    setToken(null);
    localStorage.clear();
    dispatch(logout());
    persistor.purge();
    cookies.remove('x-auth-token', { path: '/' });
    history(`/?${userId}`);
  }

  return (
    <GoogleLogout
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Box
          px={2}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            cursor: 'pointer',
          }}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText
            primary={'Logout'}
            sx={{
              cursor: 'pointer',
              marginLeft: '-1rem',
            }}
          />
        </Box>
      )}
      buttonText='Logout'
      onLogoutSuccess={logoutFnc}
    />
  );
}

export default Logout;
