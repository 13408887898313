import axiosInstance from 'apis/axiosInstance';
import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Calendar from 'react-calendar';
import './react-calendar-worklog.css';
import { base_url } from '../../../components/Mode';
import {
  getDatabaseFormattedDate,
  getTimeZoneFormattedTimestamp,
  splitByUnderscoresAndCapitalize,
} from '../../../utils/common';
import constants from '../../../utils/constants';
import { WorkLogModal } from './WorkLogModal';
import SuccessNotifier from '../../../components/ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../../components/ToastNotifications/ErrorNotifier';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
const { PERSONAL_LEAVE, TRAINING } = constants;

export default function WorkLogCalendarView() {
  // Note: This calendar view currently shows snoozed leads of the month. So, we will ignore every preset filter related to snoozed.
  // Note: JS .getMonth() returns value from 0 to 11 instead of 1 to 12.
  const [date, setDate] = useState(new Date()); // by default fetch data of current month.
  const [monthlyData, setMonthlyData] = useState({});
  const [openWorkLog, setOpenWorkLog] = useState(false);
  const [clickedDate, setClickedDate] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedReport, setSelectedReport] = useState({});
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    getMonthlySummary();
    getHolidays();
    return () => {};
  }, [date]);

  const adminsData = useSelector((state) => state.admins.allAdmins);
  async function getHolidays() {
    try {
      const holidaysApiUrl = `/api/holidays`;
      const response = await axiosInstance.get(holidaysApiUrl);
      setHolidays(response?.data);
    } catch (error) {
      setErrorMessage(error.message || `Error fetching holidays`);
      console.log('Error', error);
    }
  }

  useEffect(() => {
    if (!clickedDate) {
      setSelectedReport({});
    } else {
      const d = new Date(clickedDate).getDate();
      setSelectedReport(d in monthlyData ? monthlyData[d] : {});
    }
    return () => {};
  }, [clickedDate]);

  function getMonthlySummary() {
    const queryParams = [
      `month=${date.getMonth() + 1}`,
      `year=${date.getFullYear()}`,
    ];
    const url = `/api/reports/work-log?${queryParams.join('&')}`;
    axiosInstance
      .get(url)
      .then((res) => {
        if (res['data']) {
          setMonthlyData(res['data']);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            `Couldn't fetch monthly reports summary`
        );
      });
  }

  const isFixedOrWeeklyHoliday = (dateToCheck) => {
    if (holidays) {
      const fixedHolidayDates = holidays.map((holiday) => holiday?.date);
      const formattedDate = getDatabaseFormattedDate(dateToCheck);
      return fixedHolidayDates.includes(formattedDate);
    }
  };

  const TileContent = ({ activeStartDate, date, view }) => {
    const now = new Date();
    const currentDate = date.getDate();

    let tileProps = {
      color: '#E59CBD',
      label: null,
      borderBottom: '',
    };

    if (view === 'month' && isFixedOrWeeklyHoliday(date)) {
      tileProps = {
        color: '#B3C3C5',
        label: '',
        borderBottom: '',
      };

      holidays.forEach((holiday) => {
        if (holiday.date === getDatabaseFormattedDate(date)) {
          tileProps.label = holiday.type;
        }
      });
    }

    if (date <= now && currentDate in monthlyData) {
      tileProps = getWorkLogProps(monthlyData[currentDate]);
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: tileProps.color,
          display: 'flex',
        }}
      >
        {tileProps.label ? (
          getWorkLogLabel(tileProps.label, {
            paddingLeft: 0,
            marginTop: 1,
          })
        ) : (
          <span className='calender-tile-date'>{date.getDate()}</span>
        )}
      </div>
    );
  };

  const handleDayClick = (value, e) => {
    if (new Date(value) > new Date()) {
      return;
    }
    const selectedDate = getTimeZoneFormattedTimestamp(new Date(value)).split(
      'T'
    )[0];
    setClickedDate(selectedDate);

    // Get the admin's creation date from local storage

    const adminCreationDate = adminsData
      ? new Date(adminsData[0]?.created_at.slice(0, 10))
      : null;

    // Compare admin's creation date with the selected date
    if (
      adminCreationDate !== null &&
      new Date(adminCreationDate) > new Date(selectedDate)
    ) {
      // If admin creation date is after the selected date
      setOpenWorkLog(false);
      return;
    }
    setOpenWorkLog(true);
  };

  // fires when month is changed using prev/next buttons.
  const handleActiveStartDateChange = ({
    action,
    activeStartDate,
    view,
    value,
  }) => {
    setDate(new Date(activeStartDate));
  };

  const handleClose = () => {
    setOpenWorkLog(false);
    setSelectedReport({});
    setClickedDate(null);
    getMonthlySummary();
  };

  return (
    <div>
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {openWorkLog && (
        <WorkLogModal
          {...{
            monthlyData,
            openWorkLog,
            clickedDate,
            setIsSuccess,
            setHasError,
            setErrorMessage,
            setSuccessMessage,
            selectedReport,
            handleClose,
            holidays,
          }}
        />
      )}
      <Calendar
        defaultView='month'
        value={date}
        className='react-calendar'
        tileContent={TileContent}
        prev2Label={null}
        next2Label={null}
        prevLabel={<ArrowBackIosIcon />}
        nextLabel={<ArrowForwardIosIcon />}
        showNeighboringMonth={false}
        onClickDay={handleDayClick}
        onActiveStartDateChange={handleActiveStartDateChange}
      />
    </div>
  );
}
export function getWorkLogProps(data) {
  // https://github.com/heycoach-in/sales/issues/440#issuecomment-1465624357
  const tileProps = {
    color: '#E59CBD',
    label: null,
    borderBottom: 'default',
  };

  const { leave_type: leaveType, count: hasReportEntries } = data;

  switch (leaveType) {
    case PERSONAL_LEAVE:
      tileProps.color = 'navajowhite';
      tileProps.label = 'on_leave';
      tileProps.borderBottom = 'darkorange';
      break;
    case TRAINING:
      tileProps.color = 'navajowhite';
      tileProps.label = 'training';
      tileProps.borderBottom = 'darkorange';
      break;
    // case PERSONAL_LEAVE_HALF_DAY:
    //   tileProps.color = 'navajowhite';
    //   tileProps.label = 'on_leave';
    //   tileProps.borderBottom = 'darkorange';
    //   break;
    default:
      break;
  }

  if (hasReportEntries) {
    tileProps.color = '#9CE5D3';
  }
  return tileProps;
}

export function getWorkLogLabel(label, sx) {
  const getBackgroundColor = () => {
    switch (label) {
      case 'on_leave':
        return '#fff176';
      case 'weekday_off':
        return '#primary';
      case 'holiday':
        return 'primary';
      default:
        return 'primary';
    }
  };

  return (
    <Chip
      label={splitByUnderscoresAndCapitalize(label).toUpperCase()}
      size='small'
      sx={{
        backgroundColor: getBackgroundColor(),
        fontSize: '12px',
        fontWeight: 500,
        ...sx,
      }}
    />
  );
}
