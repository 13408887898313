import axiosInstance from 'apis/axiosInstance';
import React, { useEffect, useState } from 'react';
import { base_url } from '../../../components/Mode';
import moment from 'moment';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  getFormattedDate,
  getSanitizedPhoneNumber,
  get24HourFormattedTimeFromPmAm,
} from '../../../utils/common';
import { ReportUploads } from '../ReportUploads';
import constants from '../../../utils/constants';
import { useSelector } from 'react-redux';

const { PERSONAL_LEAVE } = constants;

export function WorkLogModal({
  monthlyData,
  openWorkLog,
  clickedDate,
  setIsSuccess,
  setHasError,
  setErrorMessage,
  setSuccessMessage,
  selectedReport,
  handleClose,
  holidays,
  children = <></>,
}) {
  const [expanded, setExpanded] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [traineeMark, setTraineeMark] = useState();
  const [loading, setLoading] = useState(false);
  const { showImportBtn } = useSelector((state) => state.admins);
  const currentAdmin = useSelector((state) => state.admins.allAdmins);
  useEffect(() => {
    const created_at = currentAdmin[0]?.created_at.slice(0, 10);
    getNextDates(created_at, 7);
  }, []);

  const getNextDates = (startDateStr, count) => {
    const startDate = new Date(startDateStr);
    const nextDates = [];

    let i = 0;
    do {
      const nextDate = new Date(startDate);
      nextDate.setDate(startDate.getDate() + i);
      const holiday = holidays.find(
        (holiday) =>
          holiday.date === nextDate.toISOString().slice(0, 10) &&
          (holiday.type === 'fixed_holiday' || holiday.type === 'weekday_off')
      );
      if (!holiday) {
        nextDates.push(nextDate.toISOString().slice(0, 10));
      }
      i++;
    } while (nextDates.length < count);
    if (nextDates.includes(clickedDate)) {
      setTraineeMark(nextDates.length - nextDates.indexOf(clickedDate));
    }
    return nextDates;
  };

  const setDuplicateColumnError = (rowIndex, colName) => {
    const message = `Duplicate Column Error: column = ${colName}.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const setEmptyValueError = (rowIndex, colName) => {
    const message = `Empty Value Error at row = ${rowIndex} and column = ${colName}.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const setDateValueError = (rowIndex, colName) => {
    const message = `Date Value Error at row = ${rowIndex} and column = ${colName}. Date must be of the format 'yyyy-mm-dd' and must be equal to the work log date, i.e '${clickedDate}'.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const setMobileFormatError = (rowIndex, colName) => {
    const message = `Mobile Format Error at row = ${rowIndex} and column = ${colName}. Please upload an edited version of the report from the app.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const setCallTypeError = (rowIndex, colName) => {
    const message = `Call Type Error at row = ${rowIndex} and column = ${colName}. Valid types are (Incoming, Outgoing, Missed, Rejected).`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const setMissingColumnError = () => {
    const message = `Missing Column Error: Please make sure the report contains all the mandatory columns i.e., Name, From Number, To Number, Date, Time, Duration, Type.`;
    console.log(message); // intentional
    setHasError(true);
    setErrorMessage(message);
  };

  const resetReport = () => {
    handleClose();
  };

  const handleLogOff = () => {
    const adminCreationDate = currentAdmin
      ? currentAdmin[0]?.created_at.slice(0, 10)
      : null;

    // Compare admin's creation date with the selected date
    if (
      adminCreationDate !== null &&
      moment(adminCreationDate).isAfter(moment(clickedDate))
    ) {
      // If admin creation date is after the selected date
      handleClose();
      setHasError(true);
      setErrorMessage(
        'You are not authorized to add work log for dates before your ID creation.'
      );
      return;
    }
    const { leave_notes = '', leave_type = '' } = leaveDetail;
    if (leave_notes !== '' && leave_type === '') {
      setHasError(true);
      setErrorMessage('Please also add leave type.');
      return;
    }

    if ([PERSONAL_LEAVE].includes(leave_type) && leave_notes === '') {
      setHasError(true);
      setErrorMessage('Please also add leave notes.');
      return;
    }

    const formBody = new FormData();
    const date = `${clickedDate} 11:00:00+05:30`; //IST
    const body = {};

    if ((csvData ?? []).length !== 0) {
      const reports = JSONifyReports(clickedDate);
      if (reports.length === 0) {
        return;
      }
      body['report_data'] = reports;
    }

    if (uploadedImages.length > 0) {
      uploadedImages.forEach((img) => {
        formBody.append('report_images', img.data);
      });
    }

    Object.keys(leaveDetail).forEach((key) => {
      if ((leaveDetail[key] ?? '') !== '') {
        body[key] = leaveDetail[key];
      }
    });

    if (Object.keys(body).length === 0) {
      setHasError(true);
      setErrorMessage(
        'Atleast one of the following fields must be non empty: Reports, Log time off'
      );
      return;
    }

    body['date'] = date;

    const upload = async () => {
      let reportId;
      try {
        setLoading(true);
        if (Object.keys(selectedReport ?? {}).length === 0) {
          const res = await axiosInstance.post(`/api/reports`, body);
          reportId = res.data.id;
        } else {
          reportId = selectedReport.id;
          await axiosInstance.patch(`/api/reports/${reportId}`, body);
        }

        if (uploadedImages.length > 0 && reportId) {
          await axiosInstance.post(
            `/api/reports/${reportId}/images?context=report`,
            formBody,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            'An error occured while adding work log.'
        );
      } finally {
        handleClose();
        setLoading(false);
      }
    };

    upload();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const tmpLeaveDetails = {
      ...leaveDetail,
    };

    if ((value ?? '') === '') {
      delete tmpLeaveDetails[name];
    } else {
      tmpLeaveDetails[name] = value;
    }
    setLeaveDetail(tmpLeaveDetails);
  };

  const JSONifyReports = (uploadDate) => {
    const validPhoneRegex = /^[0-9]*$/;
    const rowsFound = csvData.length;
    const colsFound = (csvData[0] ?? []).length;
    const reports = [];

    for (let i = 1; i < rowsFound; i += 1) {
      const isEmptyRow = csvData[i].every((x) => (x ?? '') === '');
      if (isEmptyRow) {
        continue;
      }
      // Indo: Setting _Int for testing purposes
      const jsonRow = {
        name: null,
        from_number: null,
        to_number: null,
        date: null,
        time: null,
        duration: null,
        type: null,
        from_number_int: null,
        to_number_int: null,
      };

      for (let j = 0; j < colsFound; j += 1) {
        let value = `${csvData[i][j] ?? ''}`.trim();
        let colName = csvData[0][j];
        colName = colName.toLowerCase().split(' ').join('_');

        if (!(colName in jsonRow)) {
          continue;
        }

        // initial value is null, but if a non-null value is found then it means there are more than 1 columns with same name.
        if (jsonRow[colName] !== null) {
          setDuplicateColumnError(i + 1, csvData[0][j]);
          resetReport();
          return [];
        }

        if ((value ?? '') === '') {
          setEmptyValueError(i + 1, csvData[0][j]);
          resetReport();
          return [];
        }

        if (
          colName === 'type' &&
          !['Incoming', 'Outgoing', 'Missed', 'Rejected'].includes(value)
        ) {
          setCallTypeError(i + 1, csvData[0][j]);
          resetReport();
          return [];
        }

        if (colName === 'date') {
          if (uploadDate !== value) {
            setDateValueError(i + 1, csvData[0][j]);
            resetReport();
            return [];
          }
        }

        if (colName === 'from_number' || colName === 'to_number') {
          value = getSanitizedPhoneNumber(value);
          if (!validPhoneRegex.test(value)) {
            setMobileFormatError(i + 1, csvData[0][j]);
            resetReport();
            return [];
          }
        }

        if (colName === 'time') {
          value = get24HourFormattedTimeFromPmAm(value);
          console.log(value);
        }

        if (colName === 'duration') {
          value = getDurationConvertedIntoSeconds(value);
        }

        jsonRow[colName] = value;
        // Indo: Setting _Int for testing purposes
        // Set integer values for from_number_int and to_number_int if applicable
        if (colName === 'from_number') {
          jsonRow.from_number_int = value ? parseInt(value, 10) : null;
        }
        if (colName === 'to_number') {
          jsonRow.to_number_int = value ? parseInt(value, 10) : null;
        }
      }

      let ok = true;
      Object.keys(jsonRow).forEach((col) => {
        if (!ok) {
          return;
        }
        if (jsonRow[col] === null) {
          ok = false;
        }
      });

      if (!ok) {
        setMissingColumnError();
        resetReport();
        return;
      }

      reports.push(jsonRow);
    }

    if (reports.length === 0) {
      setHasError(true);
      setErrorMessage('Empty report');
      resetReport();
    }
    return reports;
  };
  //compairing role created date to clicked date to hide training option from dropdown after 7 days
  const shouldRenderTraining = (clicked) => {
    const adminCreationDate = currentAdmin
      ? currentAdmin[0]?.created_at.slice(0, 10)
      : null;
    const currentDate = moment(clicked);

    if (!adminCreationDate || currentDate.isBefore(moment(adminCreationDate))) {
      return false;
    }

    const dates = [];
    for (const key in monthlyData) {
      if (monthlyData.hasOwnProperty(key)) {
        const entry = monthlyData[key];
        dates.push(entry.admin_created_at);
      }
    }
    const createdAt = moment(dates[0]);
    const sevenDaysAfterCreatedAt = createdAt.clone().add(6, 'days');
    const shouldRenderItem = currentDate.isBefore(sevenDaysAfterCreatedAt);
    return shouldRenderItem;
  };

  return (
    <Dialog
      open={openWorkLog}
      onClose={() => handleClose()}
      maxWidth='md'
      fullWidth
    >
      <DialogContent>
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          <Typography
            variant='h5'
            component='div'
          >
            Work log for {getFormattedDate(new Date(clickedDate), true)}
          </Typography>

          <Typography
            onClick={(e) => handleClose()}
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
            }}
          >
            close
          </Typography>
        </Stack>

        {children}
        <Typography
          sx={{
            bgcolor: 'white',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontWeight: 500,
            fontSize: '15px',
            fontStyle: 'italic',
            color: 'red',
            margin: '5px',
          }}
        >
          Your dialer report is missing please mark leave.
        </Typography>
        <Accordion
          expanded={expanded}
          sx={{ mt: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            <Typography
              variant='body1'
              component='p'
            >
              Log time off
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid
              container
              spacing={1}
              columns={26}
            >
              <Grid
                item
                xs={8}
                sm={8}
                md={15}
              >
                <FormControl
                  size='large'
                  fullWidth
                >
                  <InputLabel
                    id='leave_type'
                    fullWidth
                  >
                    Select leave type
                  </InputLabel>
                  <Select
                    name='leave_type'
                    label='Leave type'
                    onChange={handleChange}
                    value={leaveDetail?.leave_type}
                    fullWidth
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='personal_leave'>Personal leave</MenuItem>
                    {shouldRenderTraining(clickedDate) && (
                      <MenuItem value='training'>
                        {`Training (Reports can be skipped for ${traineeMark}
                        days)`}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={8}
                sm={8}
                md={10}
              >
                <FormControl
                  id='name'
                  fullWidth
                >
                  <TextField
                    size='large'
                    type='text'
                    name='leave_notes'
                    placeholder='Add leave notes'
                    onChange={handleChange}
                    value={leaveDetail?.leave_notes}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              {leaveDetail?.leave_type === 'personal_leave' && (
                <Typography
                  sx={{
                    bgcolor: 'white',
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em',
                    fontWeight: 500,
                    fontSize: '13px',
                    fontStyle: 'italic',
                    color: 'red',
                    margin: '5px',
                  }}
                >
                  Your leaves will be sent to the HRMS portal and the payroll
                  may get affected in case you have insufficient leaves
                  available.
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* {(Object.keys(selectedReport ?? {}).length === 0 ||
          (selectedReport.count ?? 0) === 0 ||
          showImportBtn) && (
          <ReportUploads
            {...{
              csvData,
              setCsvData,
              uploadedImages,
              setUploadedImages,
            }}
          />
        )} */}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          onClick={(e) => handleLogOff()}
          autoFocus
          disabled={loading}
        >
          Add work log
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const getDurationConvertedIntoSeconds = (duration) => {
  if (!duration || duration === '' || duration === '0s') {
    return 0;
  }
  const splittedDuration = duration.split(' ');
  const integerPattern = /\d+/g;

  let val = 0;
  splittedDuration.forEach((d) => {
    const len = d.length;
    const t = d.match(integerPattern)[0]; // returns an array
    if (d[len - 1] === 'h') {
      val += Number(t) * 3600;
    } else if (d[len - 1] === 'm') {
      val += Number(t) * 60;
    } else {
      val += Number(t);
    }
  });
  return val;
};
