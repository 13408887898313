import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import rupeeImage from './../../images/rupee.png';
import dumyProfilePicture from './../../images/Ellipse-28.svg';
import firstPrize from './../../images/first-prize.png';
import secondPrize from './../../images/second-prize.png';
import thirdPrize from './../../images/third-prize.png';
import ErrorNotifier from './../ToastNotifications/ErrorNotifier';
import {
  calculateIncentiveAndMilestone,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common';
import LeaderboardSkeleton from './LeaderboardSkeleton';
import { Grid, TextField } from '@mui/material';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import ChartBox from './LeaderboardDailyLogsChart';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { Textfit } from 'react-textfit';
import InfoIcon from '@mui/icons-material/Info';
import LeaderboardIncentiveStructureTable from './LeaderboardIncentiveStructureTable';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getCurrAdmin } from 'features/auth/authSelector';

const Leaderboard = () => {
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [driveName, setDriveName] = useState('');
  const [showMessage, setShowMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notEligibleList, setNotEligibleList] = useState([]);
  const [isChartOpen, setIsChartOpen] = useState(false);
  const [openChartId, setOpenChartId] = useState(null);
  const [chartData, setChartData] = useState([]);
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
  const [defaultDate, setDefaultDate] = useState(formattedToday);
  const [prorataAndIncentiveData, setProrataAndIncentiveData] = useState({});
  const [
    showLeaderboardIncentiveStructure,
    setShowLeaderboardIncentiveStructure,
  ] = useState(false);

  const toggleLeaderboardIncentiveStructure = () => {
    setShowLeaderboardIncentiveStructure(!showLeaderboardIncentiveStructure);
  };

  const message = `The leaderboard is waiting for its first champion...
  Secure a CAP and make your mark!`;

  useEffect(() => {
    const adminFromStorage = getCurrAdmin();
    if (adminFromStorage) {
      setCurrentAdmin(adminFromStorage);
    }

    getLeaderboardData();
  }, []);

  const getLeaderboardData = () => {
    axiosInstance
      .get(`/api/leaderboard`)
      .then((res) => {
        setProrataAndIncentiveData(res.data?.incentiveValues);
        setStartDate(new Date(res.data?.startDate));
        setEndDate(new Date(res.data?.endDate));
        setDriveName(res.data?.drive_name);
        setLeaderboardList(res.data?.data || []);
        setNotEligibleList(res.data?.notELigible);
        setShowMessage(message);
      })
      .catch((Err) => {
        console.error(Err);
        setErrorMessage(Err.message);
        setHasError(true);
      });
  };

  const formatLeaderboardPeriod = (startDate, endDate) => {
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = startDate.toLocaleString('default', {
      month: 'short',
    });
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    const startYear = startDate.getFullYear().toString().slice(-2);
    const endYear = endDate.getFullYear().toString().slice(-2);

    return `${startDay}${getOrdinalSuffix(
      startDay
    )} ${startMonth}'${startYear} - ${endDay}${getOrdinalSuffix(
      endDay
    )} ${endMonth}'${endYear}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const LeaderBoardTable = leaderboardList?.map((data, index) => {
    const isAdmin = data.admin_id === currentAdmin.id;
    const id = isAdmin ? currentAdmin.id : data.admin_id;
    let bgColor = '#F1F8FF';
    let prizeImage;
    const { incentive, nextMilestone } = calculateIncentiveAndMilestone(
      data.total_caps,
      prorataAndIncentiveData
    );

    if (index === 0) {
      bgColor = '#FFE78D';
      prizeImage = firstPrize;
    } else if (index === 1) {
      bgColor = '#E5E5E5';
      prizeImage = secondPrize;
    } else if (index === 2) {
      bgColor = '#F5E7C3';
      prizeImage = thirdPrize;
    }

    const profilePicture = data.profile_picture
      ? data.profile_picture
      : dumyProfilePicture;
    const isChartOpen = openChartId === id;

    return (
      <Grid
        container
        flexDirection={'row'}
        sx={{
          borderRadius: '36px',
          background: isAdmin ? '#C5E0FF' : bgColor,
          height: isChartOpen ? '300px' : '40px',
          alignItems: isChartOpen ? 'start' : 'center',
          color: '#000000',
          fontSize: '16px',
          overflow: 'hidden',
          margin: '10px 0',
          paddingTop: isChartOpen ? '1rem' : '0',
        }}
        key={data.id}
      >
        <Grid
          item
          md={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '2rem',
            }}
          >
            {data?.previous_rank - data?.rank > 0 ? (
              <>
                <Typography sx={{ color: 'green', fontSize: '14px' }}>
                  {data?.previous_rank - data?.rank}
                </Typography>
                <ArrowUpwardIcon
                  sx={{ color: 'green', fontSize: '14px', marginTop: '4px' }}
                />
              </>
            ) : (
              <>
                <Typography sx={{ color: 'red', fontSize: '14px' }}>
                  {data?.previous_rank - data?.rank}
                </Typography>
                <ArrowDownwardIcon
                  sx={{ color: 'red', fontSize: '14px', marginTop: '4px' }}
                />
              </>
            )}
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              marginRight: '2rem',
              paddingRight: '3rem',
            }}
          >
            {index + 1}
          </Typography>
        </Grid>
        <Grid
          item
          md={2.6}
        >
          <Typography
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                minWidth: '22px',
                border: 'none',
                marginTop: isChartOpen ? '0px' : '10px',
              }}
            >
              <img
                src={prizeImage}
                alt=''
                height={'22px'}
                style={{}}
              />
            </span>{' '}
            <span
              style={{
                marginTop: isChartOpen ? '0px' : '7px',
              }}
            >
              <span>
                <img
                  src={profilePicture}
                  alt=''
                  height='20px'
                  width='20px'
                  style={{
                    borderRadius: '50%',
                  }}
                />
              </span>
            </span>{' '}
            <Link
              style={{
                textDecoration: 'none',
                color: 'inherit',
                marginTop: isChartOpen ? '-10px' : '0',
              }}
              to={`/admin-profile/${data?.username}`}
            >
              <Textfit mode='single'>{data.fullname}</Textfit>
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          md={1.5}
        >
          <Typography
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              marginLeft: '-1rem',
            }}
          >
            {data.total_caps}
          </Typography>
        </Grid>
        <Grid
          item
          md={2.9}
        >
          <Typography
            sx={{
              textAlign: 'center',
              marginLeft: '4.5rem',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <span
              style={{
                height: '20px',
                width: '20px',
              }}
            >
              <img
                src={rupeeImage}
                height={'19px'}
                alt=''
              />
            </span>{' '}
            {incentive}
            {data?.total_caps <= 5 && (
              <Tooltip
                title='This is an intrapolative incentive to be rolled once the cliff of 6 CAPs has been hit.'
                arrow
              >
                <InfoOutlinedIcon sx={{ height: '19px' }} />
              </Tooltip>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          md={2.5}
        >
          <Typography
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <span
              style={{
                height: '20px',
                width: '20px',
              }}
            >
              <img
                src={rupeeImage}
                height={'19px'}
                alt=''
              />
            </span>
            {nextMilestone}
          </Typography>
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            alignItems: 'center',
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={() => showAndHideChartOnClick(id)}
          >
            <InsertChartOutlinedRoundedIcon />
          </span>
        </Grid>
        {isChartOpen && openChartId === id && (
          <ChartBox
            chartDatalist={chartData}
            isChartOpen={isChartOpen}
            date={defaultDate}
          />
        )}
      </Grid>
    );
  });

  const getChartData = (id) => {
    axiosInstance
      .get(`/api/leaderboard_daily_logs/${id}`)
      .then((res) => {
        setChartData(res.data.data);
        setIsChartOpen(true);
      })
      .catch((error) => {
        console.error(error);
        setHasError(true);
        setErrorMessage(error.message);
      });
  };

  const dateChangeFunctionHandler = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate > formattedToday) {
      setHasError(true);
      setErrorMessage(`Date should be less than or equal to ${formattedToday}`);
      return;
    }

    if (selectedDate < getTimeZoneFormattedTimestamp(startDate).split('T')[0]) {
      setHasError(true);
      setErrorMessage(
        `Date must be greater than or equal to the ${
          getTimeZoneFormattedTimestamp(startDate).split('T')[0]
        }`
      );
      return;
    }

    axiosInstance
      .get(`/api/leaderboard?date=${selectedDate}`)
      .then((res) => {
        setLeaderboardList(res.data?.data || []);
        setDefaultDate(selectedDate);
        setShowMessage(message);
        setHasError(false);
      })
      .catch((Err) => {
        console.error(Err);
        setErrorMessage(Err.message);
        setHasError(true);
      });
  };

  const showAndHideChartOnClick = (id) => {
    if (openChartId === id) {
      setOpenChartId(null);
      setChartData([]);
      setIsChartOpen(false);
    } else {
      setOpenChartId(id);
      getChartData(id);
    }
  };

  const notEligibleAdminsTable = notEligibleList?.map((data, index) => {
    const isAdmin = data.admin_id === currentAdmin.id;
    let bgColor = 'transparent';
    const { incentive, nextMilestone } = calculateIncentiveAndMilestone(
      data.total_caps
    );

    const profilePicture = data.profile_picture
      ? data.profile_picture
      : dumyProfilePicture;

    return (
      <Grid
        container
        flexDirection={'row'}
        sx={{
          borderRadius: '36px',
          background: isAdmin ? '#C5E0FF' : bgColor,
          height: '40px',
          alignItems: 'center',
          color: '#000000',
          fontSize: '16px',
          overflow: 'hidden',
          margin: '10px 0',
        }}
      >
        <Grid md={2}>
          <Typography
            sx={{
              textAlign: 'center',
              marginLeft: '1.2em',
            }}
          >
            -
          </Typography>
        </Grid>
        <Grid md={2.6}>
          <Typography
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                marginTop: '10px',
                minWidth: '22px',
                border: 'none',
              }}
            ></span>{' '}
            <span
              style={{
                marginTop: '7px',
              }}
            >
              <span>
                <img
                  src={profilePicture}
                  alt=''
                  height='20px'
                  width='20px'
                  style={{
                    borderRadius: '50%',
                  }}
                />
              </span>
            </span>{' '}
            <Link
              style={{ textDecoration: 'none', color: 'inherit' }}
              to={`/admin-profile/${data?.username}`}
              key={data.id}
            >
              <span>{data.fullname}</span>
            </Link>
          </Typography>
        </Grid>
        <Grid md={1.5}>
          <Typography
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              marginLeft: '-1rem',
            }}
          >
            {data.total_caps}
          </Typography>
        </Grid>
        <Grid md={2.9}>
          <Typography
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <span
              style={{
                height: '20px',
                width: '20px',
              }}
            >
              <img
                src={rupeeImage}
                height={'19px'}
                alt=''
              />
            </span>{' '}
            {incentive}
          </Typography>
        </Grid>
        <Grid md={2.5}>
          <Typography
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <span
              style={{
                height: '20px',
                width: '20px',
              }}
            >
              <img
                src={rupeeImage}
                height={'19px'}
                alt=''
              />
            </span>
            {nextMilestone}
          </Typography>
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {hasError && (
          <ErrorNotifier
            message={errorMessage}
            setHasError={setHasError}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2,
            margin: '0',
          }}
        >
          <Box
            sx={{
              color: '#000000',
              fontSize: '21px',
              fontWeight: 'bold',
              display: 'flex',
            }}
          >
            <Typography sx={{ fontSize: '23px' }}>Leaderboard </Typography>
            <span
              style={{
                marginLeft: '.4rem',
              }}
            >
              <EmojiEventsIcon
                style={{ color: '#f4d002', fontSize: '1.8rem' }}
              />
            </span>
          </Box>
          <Box
            sx={{
              color: '#000000',
              fontSize: '21px',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '7px',
            }}
          >
            {leaderboardList.length > 0 && (
              <>
                <Typography
                  sx={{
                    backgroundColor: '#fd4e1e',
                    color: 'white',
                    borderRadius: '24px',
                    padding: '5px 10px',
                    fontSize: '14px',
                  }}
                >
                  {driveName} drive{' '}
                  <span>({formatLeaderboardPeriod(startDate, endDate)})</span>
                </Typography>
                <InfoIcon
                  style={{
                    color: '#1976D2',
                    cursor: 'pointer',
                    fontSize: '2rem',
                  }}
                  onClick={toggleLeaderboardIncentiveStructure}
                />
                <TextField
                  InputProps={{
                    style: {
                      padding: '-5px',
                      height: '40px',
                      width: '150px',
                    },
                  }}
                  style={{
                    background: 'transparent;',
                    fontWeight: 400,
                  }}
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  value={defaultDate}
                  onChange={dateChangeFunctionHandler}
                />
              </>
            )}
          </Box>
        </Box>
        {showLeaderboardIncentiveStructure && (
          <div onClick={toggleLeaderboardIncentiveStructure}>
            <LeaderboardIncentiveStructureTable
              toggleLeaderboardIncentiveStructure={
                toggleLeaderboardIncentiveStructure
              }
            />
          </div>
        )}
        <Box
          sx={{
            backgroundColor: '#3994ff',
            borderRadius: '10px',
            height: '47px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            color: '#fff',
            fontSize: '23px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            margin: '0',
            padding: '0 10px',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              flex: 1,
              textAlign: 'center',
              marginLeft: '-1.5rem',
            }}
          >
            Rank
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              flex: 1,
            }}
          >
            Name
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              flex: 1,
              paddingLeft: '4rem',
            }}
          >
            CAPs
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              flex: 1,
              marginLeft: '-3rem',
              marginRight: '3rem',
            }}
          >
            Incentives
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              flex: 1,
              textAlign: 'center',
            }}
          >
            Next Milestones
          </Typography>
        </Box>

        <>{LeaderBoardTable}</>
        <>{leaderboardList.length === 0 && <LeaderboardSkeleton />}</>
      </Box>

      {notEligibleList && notEligibleList.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '50px',
            }}
          >
            <Typography
              sx={{
                bgcolor: 'gray',
                height: '2px',
                width: '30%',
              }}
            ></Typography>
            <Typography
              sx={{
                color: 'black',
                width: '40%',
                textAlign: 'center',
                fontSize: '21px',
              }}
            >
              Not Eligible for the Leaderboard
            </Typography>
            <Typography
              sx={{
                bgcolor: 'gray',
                height: '2px',
                width: '30%',
              }}
            ></Typography>
          </Box>
          <Box>{notEligibleAdminsTable}</Box>
        </>
      )}
    </>
  );
};

export default Leaderboard;
