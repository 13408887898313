import ProfileImageAndSocialMedia from './Molecules/ProfileImageAndSocialMedia';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Analytics from './Molecules/Analytics';
import HeatMap from '../../components/profile/HeatMap';
import BioComp from '../../components/profile/BioComp';
import ImageGallery from './Molecules/ImageGallery';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { getCurrentAdminId } from '../../utils/common';
import { Box } from '@mui/material';
import { get } from 'lodash';
import { getCurrAdmin } from 'features/auth/authSelector';
import { updateCurrAdmin } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
const Profile = () => {
  const [profilePage, setProfilePage] = useState();
  const [descData, setdescData] = useState('');
  const [heatMap, setHeatMap] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [totalcaps, setTotalCaps] = useState(0);
  const [maxCapDrive, setMaxCapDrive] = useState(0);
  const [totalincentive, setTotalIncentive] = useState(0);
  const [highestIncentive, setHighestIncentive] = useState(0);
  const [totalTAPs, setTotalTAPs] = useState(null);
  const [showProfileEditingBanner, setShowProfileEditingBanner] =
    useState(false);
  const [requiredField, setRequiredField] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [previewImage, setpreviewImage] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();
  }, [update]);

  useEffect(() => {
    fetchCaps();
  }, []);

  useEffect(() => {
    if (profilePage?.adminDetails) {
      setAdmin(getCurrentAdminId() === profilePage?.adminDetails?.id);
    }
  }, [edit, profilePage]);

  const handleImagePreview = (url) => {
    setpreviewImage(url);
  };

  const handleImagePreviewClose = () => {
    setpreviewImage(null);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const saveEdit = (data) => {
    updateProfileData(data);
  };

  const checkMissingFields = (data) => {
    const requiredFields = [
      'username',
      'designation',
      'social_media',
      'profile_picture',
      'description',
      'banner',
      'admin_gallery',
    ];

    const missingFields = [];

    for (const field of requiredFields) {
      const value = data[field];
      if (
        value === null ||
        value === undefined ||
        (typeof value === 'string' && /^\s*$/.test(value.trim()))
      ) {
        missingFields.push(field);
      }
    }
    setRequiredField(missingFields.length > 0 ? true : false);
  };

  const fetchCaps = async () => {
    try {
      const username = getUsernameFromUrl();
      const response = await axiosInstance.get(
        `/api/admin/cap/?username=${username}`
      );

      const TotalCap = response.data?.count ?? 0;
      const MaxCapinDrive = response.data?.maxDrive?.maxCount ?? 0;
      setTotalCaps(TotalCap);
      setMaxCapDrive(MaxCapinDrive);
      const TotalIncentive = response.data?.Total ?? 0;
      const TotalTAPs = response.data?.totalTAP ?? 0;
      const HighestDriveIncentiveValue = response.data?.Highest ?? 0;
      setTotalIncentive(TotalIncentive);
      setHighestIncentive(HighestDriveIncentiveValue);
      setTotalTAPs(TotalTAPs);
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };

  const fetchData = async () => {
    const username = getUsernameFromUrl();
    try {
      const response = await axiosInstance.get(
        `/api/my-profile/?username=${username}`
      );
      setProfilePage(response.data);
      setHeatMap(response.data.heatMapData);
      checkMissingFields(response?.data?.adminDetails);
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  const getUsernameFromUrl = () => {
    const pathUrl = window.location.pathname.split('/');
    const usernameIndex = pathUrl.indexOf('admin-profile') + 1;

    return usernameIndex < pathUrl.length ? pathUrl[usernameIndex] : null;
  };

  const updateProfileData = async (data) => {
    if (
      !data.username ||
      (data.username && data.username.trim().length === 0)
    ) {
      setHasError(true);
      setErrorMessage('Username cannot be empty');
      return;
    }

    if (data.bio) {
      data.bio = data.bio.trim();
      data.username = data.username.trim();
    }
    if (descData?.about != null) {
      data.description = descData.about.trim();
    }

    try {
      const res = await axiosInstance.patch(`/api/my-profile`, data);
      const oldadmin = getCurrAdmin();
      const { username } = oldadmin;
      const updatedAdmin = {
        ...oldadmin,
        username: res?.data?.updatedData.username,
        bio: res?.data?.updatedData.bio,
      };
      dispatch(updateCurrAdmin(updatedAdmin));

      if (username !== res.data.updatedData.username) {
        navigate('/bucket-search');
      }
      setEdit(false);
      closeEditBannerHandler();
      setIsSuccess(true);
      setUpdate(!update);
      setSuccessMessage('Profile updated successfully');
    } catch (err) {
      setHasError(true);
      setErrorMessage(err.message);
    }
  };

  const openEditBannerHandler = () => {
    handleEdit();
    setShowProfileEditingBanner(true);
  };

  const closeEditBannerHandler = () => {
    setShowProfileEditingBanner(false);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      {profilePage && (
        <Box sx={{ top: '-30px', position: 'relative' }}>
          <ProfileImageAndSocialMedia
            handleImagePreview={handleImagePreview}
            closeImagePreview={handleImagePreviewClose}
            previewImage={previewImage}
            admin={admin}
            requiredField={requiredField}
            setUpdate={setUpdate}
            update={update}
            edit={edit}
            onhandleEdit={handleEdit}
            onsaveEdit={saveEdit}
            personalData={profilePage?.adminDetails}
            about={descData}
            showProfileEditingBanner={showProfileEditingBanner}
            openEditBannerHandler={openEditBannerHandler}
          />

          <Analytics
            edit={edit}
            totalTAP={totalTAPs}
            totalcaps={totalcaps}
            maxCapDrive={maxCapDrive}
            totalincentive={totalincentive}
            highestIncentive={highestIncentive}
          />
          <HeatMap statusData={heatMap} />
          <BioComp
            bioData={profilePage?.adminDetails}
            edit={edit}
            SetBio={setdescData}
            onhandleEdit={handleEdit}
          />
          <ImageGallery
            handleImagePreview={handleImagePreview}
            closeImagePreview={handleImagePreviewClose}
            previewImage={previewImage}
            admin={admin}
            setUpdate={setUpdate}
            update={update}
            personalData={profilePage?.adminDetails}
            edit={edit}
          />
        </Box>
      )}
    </>
  );
};
export default Profile;
