import React from 'react';
import EligibilityFormDialog from '../EligibilityFormDialog';

const EligibilityFormDialogHandler = ({
  selectedEligbilityForm,
  setSelectedEligibilityForm,
  selectedEfConversionFlow,
  setSelectedEfConversionFlow,
}) => (
  <>
    {selectedEligbilityForm.length > 0 && (
      <EligibilityFormDialog
        {...{
          selectedEligbilityForm,
          setSelectedEligibilityForm,
          selectedEfConversionFlow,
          setSelectedEfConversionFlow,
        }}
      />
    )}
  </>
);

export default EligibilityFormDialogHandler;
