import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { addRecencyText } from '../../utils/AddRecencyText';
import {
  getCurrentAdminId,
  getFormattedTimestamp,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmInteractionDeletionModal } from '../../components/users/ConfirmInteractionDeletionModal';
import axiosInstance from 'apis/axiosInstance';
import { getTimePeriod } from '../../components/Sidebar';
import { useSelector } from 'react-redux';

const CandidateInteractionsList = ({
  inteactionNotes,
  getInteractions,
  selectedRowData,
}) => {
  const [
    openDeleteInteractionConfirmation,
    setOpenDeleteInteractionConfirmation,
  ] = useState(false);
  const [interactionId, setInteractionId] = useState(-1);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedInteractionId, setSelectedInteractionId] = useState(-1);
  const { allAdmins } = useSelector((state) => state.admins);
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));

  const handleDelete = () => {
    const url = `/api/hrms/hrms_candidates/${selectedRowData.id}/interactions/${selectedInteractionId}`;

    axiosInstance
      .delete(url)
      .then((res) => {
        getInteractions();
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't delete the interaction."
        );
      })
      .finally(() => {
        setOpenDeleteInteractionConfirmation(false);
      });
  };

  const result = (inteactionNotes || []).map(function (interaction) {
    const noteslist = interaction.notes.split('\n');
    var notesHTML = [];
    noteslist.forEach(function (noteText) {
      const notePrefix = noteText.split(': ');
      var noteLine = [];
      // Highlight only if it is used as a title (title would not be more than 30-35 chars long).
      if (notePrefix.length > 1 && notePrefix[0].length <= 35) {
        noteLine.push(<font color='brown'>{notePrefix[0]}: </font>);
        notePrefix.shift();
        noteLine.push(notePrefix.join(': '));
      } else {
        noteLine.push(notePrefix.join(': '));
      }
      notesHTML.push(
        <>
          <span>{noteLine}</span>
          <br />
        </>
      );
    });

    const timestamp = new Date(Date.parse(interaction.created_at));
    const { fname, lname, access_type } = adminMap[interaction.admin_id];

    // check time before allowing to delete the Interaction only permissible within 30 minutes of creation
    const isDeletable = (interaction) => {
      const now = getTimeZoneFormattedTimestamp(new Date(), 0, 0, false);
      const interactionCreatedAt = getTimeZoneFormattedTimestamp(
        new Date(interaction.created_at),
        0,
        0,
        false
      );
      const elapsedTime = getTimePeriod(now, interactionCreatedAt, true) * 60;

      if (interaction.admin_id === getCurrentAdminId() && elapsedTime < 30) {
        return true;
      } else {
        return false;
      }
    };

    return {
      timestamp: timestamp,
      data: (
        <Box
          onMouseEnter={() => {
            if (isDeletable(interaction)) {
              setInteractionId(interaction.id);
            }
          }}
          onMouseLeave={() => {
            setInteractionId(-1);
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              component='div'
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                float={'right'}
                mt={'10px'}
                display={'inline-block'}
                style={{ marginRight: 10 }}
              >
                <strong>
                  {access_type === 'disabled'
                    ? 'System user'
                    : [fname, lname || ''].join(' ')}
                </strong>
              </Typography>
            </Box>
            <Box component='div'>
              <Typography
                float={'right'}
                mt={'10px'}
                display={'inline-block'}
                pr={'20px'}
                sx={{
                  float: 'right',
                  fontSize: '12px',
                }}
                color={'#9e9e9e'}
              >
                {addRecencyText(timestamp)} {getFormattedTimestamp(timestamp)}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: '#ebf8ff',
              padding: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
            m={'0px 10px 10px 0px'}
            borderRadius={'5px'}
            key={interaction.id}
          >
            <Typography mt={1}>{notesHTML}</Typography>

            {interactionId === interaction.id && (
              <DeleteIcon
                onClick={(e) => {
                  setSelectedInteractionId(interaction.id);
                  setOpenDeleteInteractionConfirmation(true);
                }}
                fontSize='small'
                sx={{
                  cursor: 'pointer',
                }}
                color='error'
              />
            )}
          </Box>
          {openDeleteInteractionConfirmation && (
            <ConfirmInteractionDeletionModal
              {...{
                handleDelete,
                openDeleteInteractionConfirmation,
                setOpenDeleteInteractionConfirmation,
              }}
            />
          )}
        </Box>
      ),
    };
  });

  result.sort(function (itemA, itemB) {
    const dateA = new Date(itemA.timestamp);
    const dateB = new Date(itemB.timestamp);

    // Compare the 2 dates
    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  });

  return result.map((item) => item.data);
};

export default CandidateInteractionsList;
