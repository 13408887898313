import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import constants from '../utils/constants';
import { logOut } from '../components/SpeechRecognition/utils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { loginUser } from 'features/auth/authThunk';

const Login = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const reasonMessage = useSelector((state) => state.auth.reasonMessage);
  const currAdmin = useSelector((state) => state.auth.currAdmin);
  const navigate = useNavigate();
  const userId = window.location.href?.split('?')[1];

  // Handle logout based on URL
  useEffect(() => {
    if (userId) {
      logOut(parseInt(userId));
      navigate('/');
    }
  }, [userId, navigate]);

  useEffect(() => {
    let didNavigate = false;

    if (currAdmin && !didNavigate) {
      const isTraineeOrIC = [
        constants.INDEPENDENT_CONTRIBUTOR,
        constants.TRAINEE,
      ].includes(currAdmin.roles[0]);

      didNavigate = true;
      navigate(
        isTraineeOrIC ? '/leads/priority-list' : '/dashboard/leaderboard'
      );
    }
  }, [currAdmin, navigate]);

  // Handle Google login success
  const responseSuccessGoogle = (response) => {
    dispatch(loginUser(response.tokenId));
  };

  // Handle Google login failure
  const responseFailureGoogle = (error) => {
    console.error('Google Login Failed', error);
  };

  return (
    <Box
      textAlign='center'
      margin={10}
    >
      <Typography
        variant='h4'
        m={2}
      >
        HeyCoach Sales Portal
      </Typography>
      <Typography
        variant='h6'
        color='red'
      >
        {errorMessage}
      </Typography>
      {reasonMessage && (
        <Typography
          variant='h6'
          color='red'
        >
          Reason: {reasonMessage}
        </Typography>
      )}
      <br />
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText='Sign in with Google'
        onSuccess={responseSuccessGoogle}
        onFailure={responseFailureGoogle}
        style={{ width: '400px' }}
        disabled={isLoading}
      />
      <br />
      {isLoading && (
        <Typography
          variant='h6'
          m={2}
          sx={{ position: 'absolute', bottom: '25px', right: '50%' }}
          color='#c1c1c1'
          fontSize='8px'
        >
          Logging in...
        </Typography>
      )}
    </Box>
  );
};

export default Login;
