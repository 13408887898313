import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axiosInstance from 'apis/axiosInstance';
import AdminProfileManagerTab from './ManagersTable';
import ReporteeTable from './ReporteeTable';
import { currentAdminIsManager } from './../../../utils/common';
import { getCurrAdmin } from 'features/auth/authSelector';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ManagerAndReporteeTable() {
  const [currentTab, setCurrentTab] = useState(0);
  const [managerAndReporteeData, setManagerAndReporteeData] = useState(null);
  const admin = getCurrAdmin();
  useEffect(() => {
    if (admin) {
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get(`/api/admins/hierarchy`);
          setManagerAndReporteeData(response?.data);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      };

      fetchData();
    }
  }, []);

  const handleTabChange = (event, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const checkWeatherReporteeOrManagerExist = (managerAndReporteeData) => {
    return (
      managerAndReporteeData?.managersListInTreeFormate.children.length > 0 ||
      managerAndReporteeData?.reporteesData.length > 0
    );
  };

  return (
    <>
      {managerAndReporteeData &&
        checkWeatherReporteeOrManagerExist(managerAndReporteeData) && (
          <Box
            p={2}
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              mt: 2,
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
              >
                <Tab label='Managers' />
                {currentAdminIsManager() && <Tab label='Reportees' />}
              </Tabs>
            </Box>
            <TabPanel
              value={currentTab}
              index={0}
            >
              <AdminProfileManagerTab
                managerData={managerAndReporteeData?.managersListInTreeFormate}
                currentAdminData={admin}
              />
            </TabPanel>
            {currentAdminIsManager() && (
              <TabPanel
                value={currentTab}
                index={1}
              >
                <ReporteeTable
                  reportees={managerAndReporteeData?.reporteesData}
                />
              </TabPanel>
            )}
          </Box>
        )}
    </>
  );
}
