import React from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

const SalesClosureTable = ({
  salesClosureData,
  setSalesClosureOpen,
  disableRecordActions,
}) => {
  const { allAdmins } = useSelector((state) => state.admins);
  const columns = [
    {
      field: 'gold_enabled',
      headerName: 'gold enabled',
      width: 200,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'sales_drive_name',
      headerName: 'Sales drive',
      width: 200,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'sales_owner',
      headerName: 'Sales owner',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => {
        const matchingAdmin = allAdmins.find(
          (admin) => admin.id === params.row.sales_owner
        );
        return `${matchingAdmin.fname} ${matchingAdmin.lname} `;
      },
    },
    {
      field: 'manager',
      headerName: 'Manager',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => {
        const matchingAdmin = allAdmins.find(
          (admin) => admin.id === params.row.manager
        );
        return `${matchingAdmin.fname} ${matchingAdmin.lname} `;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'actions',
      getActions: () => {
        return [
          <EditIcon
            onClick={(e) => {
              return setSalesClosureOpen(false);
            }}
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
      hide: disableRecordActions(),
    },
  ];
  return (
    <Box
      height='100%'
      sx={{ mt: 5 }}
    >
      <Typography
        component={'h2'}
        mb={2}
      >
        {' '}
        Sales Closure
      </Typography>
      <DataGrid
        rows={salesClosureData}
        columns={columns}
        pagination
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '25px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            paddingLeft: '20px',
          },
        }}
      />
    </Box>
  );
};

export default SalesClosureTable;
