import ErrorNotifier from 'components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from 'components/ToastNotifications/SuccessNotifier';
import ConfettiEffect from '../ConfettingEffect';

const ConfettiEffectHandler = ({
  showConfetties,
  hasError,
  errorText,
  setHasError,
  isSuccess,
  successText,
  setIsSuccess,
}) => (
  <>
    {showConfetties && <ConfettiEffect />}
    {hasError && (
      <ErrorNotifier
        message={errorText}
        setHasError={setHasError}
      />
    )}
    {isSuccess && (
      <SuccessNotifier
        message={successText}
        setIsSuccess={setIsSuccess}
      />
    )}
  </>
);

export default ConfettiEffectHandler;
