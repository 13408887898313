import React, { useEffect } from 'react';
import useTitle from './components/UseTitle';
import Routing from './routes/routes';
import { useNavigate } from 'react-router-dom';
import { SocketProvider } from './socket/socketContext';
import ManagerScreen from './components/training/trainee/mockcalls/manager/ManagerScreen';
import ICScreen from './components/training/trainee/mockcalls/IC/ICScreen';
import { setupAxiosInterceptors } from './apis/axiosInstance';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { setToken } from 'features/auth/authSlice';

function NewApp() {
  const token = useSelector((state) => state.auth.token);

  const history = useNavigate();
  useTitle('Sales | HeyCoach');

  useEffect(() => {
    setupAxiosInterceptors(setToken, history);
  }, [history]);

  return (
    <main
      style={{
        height: '98vh',
        overflow: 'auto',
      }}
    >
      <SocketProvider user={token}>
        <Routing />
        <ManagerScreen />
        <ICScreen />
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </SocketProvider>
    </main>
  );
}

export default NewApp;
