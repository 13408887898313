import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import ManagerPoolAction from './ManagerPoolAction';
import Pool from './Pool';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../../components/Mode';
import {
  currentAdminIsAdmin,
  getCurrentAdminId,
  getCurrentAdminName,
  sortByFullName,
} from '../../utils/common';
import constants from '../../utils/constants';
import { useSelector } from 'react-redux';
import { getCurrAdmin } from 'features/auth/authSelector';

function ManagerPool() {
  const admin = getCurrAdmin();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedManager, setSelectedManager] = useState(admin.id);
  const [freshleads, setFreshleads] = useState([]);
  const [reactivatedleads, setReactivatedleads] = useState([]);
  const [tapLeads, setTapleads] = useState([]);
  const [groupByUtmSource, setGroupByUtmSource] = useState([]);
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [tag, setTag] = useState();
  const [reporteeIcs, setReporteeIcs] = useState([]);
  const [change, setChange] = useState(false);
  const { allAdmins } = useSelector((state) => state.admins);
  const ics = allAdmins.filter((e) => {
    const roles = e.roles;
    if (e.access_type === 'allowed' || e.access_type === 'restricted') {
      for (const role of roles) {
        if (['independent_contributor', 'trainee'].includes(role.name)) {
          return true;
        }
      }
    }
    return false;
  });

  const managers = allAdmins.filter((e) => {
    const roles = e.roles;
    if (e.access_type === 'allowed' || e.access_type === 'restricted') {
      for (const role of roles) {
        if (role.name === 'lead') {
          return true;
        }
      }
    }
    return false;
  });

  useEffect(() => {
    getTappedLeads();
    getFreshleads();
    getReactivatedleads();
    getUTMSource();
    getTags();
  }, [selectedManager, change]);

  useEffect(() => {
    getIndependentContributorDropdown();
  }, []);

  const managerDropdown = () => {
    return sortByFullName(subordinateAdmins, 'Full_Name').map(
      (manager, index) => {
        return (
          <MenuItem
            value={manager?.Admin_id}
          >{`${manager?.Full_Name}`}</MenuItem>
        );
      }
    );
  };

  const getIndependentContributorDropdown = async () => {
    try {
      const res = await axiosInstance.get(`/api/all-reportees`, {
        params: {
          managerPool: true,
        },
      });

      const reportee = res?.data;

      const subordinateAdminsFiltered = reportee.filter(
        (ic) => ic.role === constants.MANAGER
      );

      const ics = reportee.filter(
        (ic) =>
          ic.role === constants.INDEPENDENT_CONTRIBUTOR ||
          ic.role === constants.TRAINEE
      );

      const currentAdminId = await getCurrentAdminId();
      const currentAdminName = getCurrentAdminName();

      const adminExists = res.data.some(
        (admin) => admin.Admin_id === currentAdminId
      );

      if (!adminExists) {
        subordinateAdminsFiltered.push({
          Full_Name: currentAdminName,
          Admin_id: currentAdminId,
        });
      }

      setSubordinateAdmins(subordinateAdminsFiltered);
      setReporteeIcs(ics);
    } catch (error) {
      setHasError(true);
      setErrorMessage(`Error in fetching assignee data`);
    }
  };

  const getTappedLeads = async () => {
    let url = `/api/manager_pool/tapped_leads?ids=${selectedManager}`;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setTapleads(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const getFreshleads = async () => {
    let url = `/api/manager_pool/fresh_leads?ids=${selectedManager}`;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setFreshleads(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const getReactivatedleads = async () => {
    let url = `/api/manager_pool/reactivated_leads?ids=${selectedManager}`;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setReactivatedleads(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching reactivated leads.'
        );
      });
  };

  const getUTMSource = async () => {
    let url = base_url() + `/api/manager/manager-pools?id=${selectedManager}`;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          const leadsGroupedByField = res?.data.reduce((groups, lead) => {
            const { utm_source, lead_id, Lead_Name } = lead;
            const source = utm_source ? utm_source : 'Unknown Source';
            if (!groups[source]) {
              groups[source] = [];
            }
            groups[source].push({ lead_id, Lead_Name });
            return groups;
          }, {});

          const leadsGroupedArray = Object.keys(leadsGroupedByField).map(
            (utm_source) => ({
              field: utm_source,
              leads: leadsGroupedByField[utm_source],
            })
          );
          setGroupByUtmSource(leadsGroupedArray);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occurred while fetching fresh leads.'
        );
      });
  };

  const getTags = async () => {
    let url = `/api/manager_pool/tag_wise_leads?ids=${selectedManager}`;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setTag(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);
  };

  const managerIdToAllowRepolling = [101, 206, 588];

  const isRepoolingAllowed = () => {
    const currentAdminId = getCurrentAdminId();

    return (
      managerIdToAllowRepolling.includes(currentAdminId) ||
      currentAdminIsAdmin()
    );
  };

  const filterManagersBySubordinateAdmins = (managers, subordinates) => {
    return managers?.filter((manager) =>
      subordinates?.some((subordinate) => subordinate?.Admin_id === manager?.id)
    );
  };

  const heirchyFilterManagers = filterManagersBySubordinateAdmins(
    managers,
    subordinateAdmins
  );

  const filterIcReportees = (icsList, reporteeIcs) => {
    return icsList?.filter((ic) =>
      reporteeIcs?.some((reportee) => reportee?.Admin_id === ic?.id)
    );
  };

  const sunordinateIcs = filterIcReportees(ics, reporteeIcs);

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      {isRepoolingAllowed() && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: '3%',
            marginRight: '2%',
          }}
        >
          <Box></Box>
          <FormControl sx={{ minWidth: '250px' }}>
            <InputLabel id='managers-label'>Managers</InputLabel>
            <Select
              labelId='managers-label'
              id='manager-select'
              value={selectedManager}
              label='Managers'
              size='small'
              onChange={handleManagerChange}
            >
              {managerDropdown()}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box>
        {freshleads?.length === 0 &&
        tapLeads?.length === 0 &&
        reactivatedleads?.length === 0 ? (
          <Box
            style={{
              border: '1px solid black',
              paddingLeft: '40%',
              paddingTop: '5%',
              paddingBottom: '5%',
              marginBottom: '2%',
            }}
          >
            <h3 style={{ fontFamily: 'cursive' }}>😔 No leads in pool</h3>
          </Box>
        ) : (
          <>
            <Pool
              freshleads={freshleads}
              tapLeads={tapLeads}
              reactivatedleads={reactivatedleads}
              tag={tag}
              groupByUtmSource={groupByUtmSource}
            />
            {admin &&
              heirchyFilterManagers?.length > 0 &&
              selectedManager &&
              ics && (
                <ManagerPoolAction
                  selectedManager={selectedManager}
                  managers={heirchyFilterManagers}
                  admin={admin}
                  ics={sunordinateIcs}
                  isRepoolingAllowed={isRepoolingAllowed()}
                  subordinateAdmins={subordinateAdmins}
                  setChange={setChange}
                />
              )}
          </>
        )}
      </Box>
    </>
  );
}

export default ManagerPool;
