import axiosInstance from 'apis/axiosInstance';

export const handleShowPLContactDetails = async (
  leadId,
  setShowPLContactDetails,
  setHasError,
  setErrorText
) => {
  try {
    setShowPLContactDetails(true);

    const res = await axiosInstance.post(`/api/lead/${leadId}/contact-detail`);
  } catch (error) {
    setHasError(true);
    setErrorText(`something went wrong`);
    console.error(error);
  }
};

export const fetchDropReasons = (
  status,
  setAllDropReasons,
  setHasError,
  setErrorText
) => {
  axiosInstance
    .get(`/api/users/drop_reasons?status=${status}`)
    .then((res) => {
      if (res.data) {
        setAllDropReasons(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      setHasError(true);
      setErrorText(
        err.response?.data?.message || "Couldn't fetch the drop reasons."
      );
    });
};

export const getAppLastSync = async (
  date,
  setIsLoading,
  handleContactDetails,
  setHasError,
  setErrorText
) => {
  setIsLoading(true);
  let currentTime;
  try {
    const res = await axiosInstance.get(`/mobile/api/last-sync`);
    const lastSyncTime = new Date(res.data).getTime();
    if (date) {
      currentTime = new Date(date).getTime();
    } else {
      currentTime = new Date().getTime();
    }
    handleContactDetails(currentTime, lastSyncTime);
    setIsLoading(false);
  } catch (error) {
    setHasError(true);
    setErrorText(
      error.response?.data?.message || 'Error: Could not sync with the app.'
    );
  }
};

export const saveInteraction = async (leadId, postData) => {
  try {
    const response = await axiosInstance.post(
      '/api/users/' + leadId + '/interactions',
      postData
    );
    return response;
  } catch (error) {
    console.error(
      'Something went wrong while saving the interaction of lead ',
      error
    );
    return null;
  }
};

export const saveInteractionFiles = async (id, formBody) => {
  try {
    await axiosInstance.post(
      '/api/interactions/' + id + '/files?context=interaction',
      formBody,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (error) {
    console.error(
      'Something went wrong while uploading the interaction files the server ',
      error
    );
  }
};

export const saveInteractionBoxVisibility = async (postData) => {
  try {
    await axiosInstance.post(`/api/interaction-box-visibility`, postData);
  } catch (error) {
    console.error(
      'Something went wrong while saving the interaction box visibility ',
      error
    );
  }
};

export const leadDropRequest = async (
  id,
  setRaisedDropRequestDetails,
  setHasError,
  setErrorText
) => {
  try {
    const res = await axiosInstance.get(
      `/api/manager_actions/${id}/drop_request`
    );
    if (res.data !== null) {
      setRaisedDropRequestDetails(res.data);
    }
  } catch (error) {
    console.error(error);
    setHasError(true);
    setErrorText(
      error.response?.data?.message || "Couldn't fetch the drop request."
    );
  }
};

export const fetchEFSBannerStatus = async (
  id,
  setShowEFSBannerStatus,
  setHasError,
  setErrorText
) => {
  try {
    const res = await axiosInstance.get(`/api/users/${id}/show_efs_banner`);
    if (res.data?.showBanner) {
      setShowEFSBannerStatus(res.data?.showBanner);
    }
  } catch (error) {
    console.error(error, { error: error.message });

    setHasError(true);
    setErrorText('Error: Could not fetch EFS banner.');
  }
};

export const fetchDnpBannerStatus = async (
  id,
  leadAutoDialMode,
  setShowDnpBanner,
  setHasError,
  setErrorText
) => {
  try {
    const res = await axiosInstance.get(`/api/users/${id}/show_dnp_banner`);
    if (res.data?.showBanner && !leadAutoDialMode) {
      setShowDnpBanner(res.data?.showBanner);
    }
  } catch (error) {
    console.error(error, { error: error.message });
    setHasError(true);
    setErrorText('Error: Could not fetch DNP banner.');
  }
};

export const statusChangeWithDNP = async (lead_id, postData) => {
  try {
    await axiosInstance.post('/api/users/' + lead_id + '/dnp', postData);
  } catch (error) {
    console.error(
      'Something went wrong while updating the status of lead on DNP',
      error
    );
  }
};

export const updateLead = async (lead_id, body) => {
  try {
    await axiosInstance.put('/api/users/' + lead_id, body);
  } catch (error) {
    console.error(
      'Something went wrong while updating the status of lead on DNP',
      error
    );
  }
};

export const getFocusedModeLeads = (
  queryParams,
  handleLeadList,
  setHasError,
  setErrorMessage
) => {
  const url = `/api/focus-mode-leads/v1?` + queryParams.join('&');
  axiosInstance
    .get(url)
    .then((res) => {
      handleLeadList(res.data?.details?.leadData);
    })
    .catch((err) => {
      setHasError(true);
      setErrorMessage(
        err.response?.data?.message || "Couldn't fetch the leads."
      );
    });
};
