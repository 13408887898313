import { createSlice } from '@reduxjs/toolkit';
import {
  endAutoDialer,
  fetchAutodialLeads,
  fetchCurrentLeadDetails,
  initiateCallThunk,
  lastHangupTimeThunk,
} from '../autoDialer/autoDailerThunk'; // Ensure the thunk is imported

const autoDialerSlice = createSlice({
  name: 'autoDialer',
  initialState: {
    allLeads: [],
    leadCurrentIndex: 0,
    currentLead: {},
    loading: true,
    errorMessage: null,
    successMessage: null,
    nextButtonClickable: false,
    autoDialerLimitTimer: 180,
    isMannualIneractionMarked: false,
    callGlobalStatus: 'not_started',
    showInteractionBox: false,
    lastHangUp: null,
  },
  reducers: {
    setAllLeads(state, action) {
      state.allLeads = action.payload;
    },
    setLeadCount(state, action) {
      state.leadCurrentIndex = action.payload;
    },
    setCurrentLead(state, action) {
      state.currentLead = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    setSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setIsMannualInteractionMarked(state, action) {
      state.isMannualIneractionMarked = action.payload;
    },
    setIsNextButton(state, action) {
      state.nextButtonClickable = action.payload;
    },
    resetAutoDialerState(state) {
      state.allLeads = [];
      state.leadCurrentIndex = 0;
      state.currentLead = null;
      state.loading = false;
      state.errorMessage = null;
      state.successMessage = null;
      state.callGlobalStatus = 'not_started';
      state.isMannualIneractionMarked = false;
      state.showInteractionBox = false;
    },
    timeTicker(state, action) {
      state.autoDialerLimitTimer = state.autoDialerLimitTimer - 1;
    },
    updateTimer(state, action) {
      state.autoDialerLimitTimer = action.payload;
    },
    resetTimer(state, action) {
      state.autoDialerLimitTimer = 180;
    },
    setLastHangUp(state, action) {
      state.lastHangUp = action.payload;
    },
    updateGlobalCallStatus(state, action) {
      state.callGlobalStatus = 'not_started';
    },
    updateInteractionBox(state, action) {
      state.showInteractionBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state for `fetchAutodialLeads`
      .addCase(fetchAutodialLeads.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      // Handle fulfilled state for `fetchAutodialLeads`
      .addCase(fetchAutodialLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.allLeads = action.payload.leadData;
        console.log('count >>>>>>', action.payload);
        if (
          state.currentLead &&
          state.currentLead.id == state.allLeads[action.payload.count]
        ) {
          state.leadCurrentIndex = action.payload.count;
        }
        state.successMessage = 'Leads fetched successfully';
      })
      // Handle rejected state for `fetchAutodialLeads`
      .addCase(fetchAutodialLeads.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload || 'Failed to fetch leads.';
      })
      // Handle pending state for `fetchAutodialLeads`
      .addCase(fetchCurrentLeadDetails.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      // Handle fulfilled state for `fetchCurrentLeadDetails`
      .addCase(fetchCurrentLeadDetails.fulfilled, (state, action) => {
        state.loading = false;
        console.log('action.payload. >>>>', action.payload);
        state.currentLead = action.payload;
        state.successMessage = 'Lead Details fetched successfully';
      })
      // Handle rejected state for `fetchCurrentLeadDetails`
      .addCase(fetchCurrentLeadDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.payload || 'Failed to fetch current lead details.';
      })
      // Handle pending state for `fetchAutodialLeads`
      .addCase(endAutoDialer.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
      })
      // Handle fulfilled state for `endAutoDialer`
      .addCase(endAutoDialer.fulfilled, (state, action) => {
        state.loading = false;
        state.currentLead = null;
        state.allLeads = [];
        state.leadCurrentIndex = 0;
        state.successMessage = 'Turning off the autodialer successfully...';
      })
      // Handle rejected state for `endAutoDialer`
      .addCase(endAutoDialer.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.payload || 'Failed to turning off the autodialer mode...';
      })
      // Handle `initiateCallThunk` pending state
      .addCase(initiateCallThunk.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
        state.callGlobalStatus = 'started';
      })
      // Handle `initiateCallThunk` fulfilled state
      .addCase(initiateCallThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = 'Call is initiated successfully.';
        state.nextButtonClickable = false; // Example additional action
        state.isMannualIneractionMarked = false;
        state.autoDialerLimitTimer = 180;
        console.log('action.payload.action ', action.payload);
        state.callGlobalStatus = action.payload.action;
      })
      // Handle `initiateCallThunk` rejected state
      .addCase(initiateCallThunk.rejected, (state, action) => {
        state.callGlobalStatus = 'not_started';
        state.loading = false;
        state.errorMessage =
          action.payload?.errorMessage ||
          'Something went wrong while initiating the call.';
      })
      // Handle pending state for `lLastHangupTimeThunk`
      .addCase(lastHangupTimeThunk.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
      })
      // Handle fulfilled state for `lastHangupTimeThunk`
      .addCase(lastHangupTimeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = 'Last hangup time updated successfully.';
        state.lastHangUp = action.payload.timestamp; // Store the updated timestamp
      })
      // Handle rejected state for `lastHangupTimeThunk`
      .addCase(lastHangupTimeThunk.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          action.payload?.errorMessage || 'Failed to update last hangup time.';
      });
  },
});

export const {
  setAllLeads,
  setLeadCount,
  setCurrentLead,
  setLoading,
  setErrorMessage,
  setSuccessMessage,
  setIsMannualInteractionMarked,
  resetAutoDialerState,
  timeTicker,
  updateTimer,
  resetTimer,
  setIsNextButton,
  setLastHangUp,
  updateGlobalCallStatus,
  updateInteractionBox,
} = autoDialerSlice.actions;

export default autoDialerSlice.reducer;
