import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'apis/axiosInstance';
import { updateCurrAdmin } from 'features/auth/authSlice';
import { toast } from 'react-toastify';
import { resetAutoDialerState } from './autoDailerSlice';

export const fetchAutodialLeads = createAsyncThunk(
  'autoDialer/fetchLeads', // Action type for Redux
  async (_, { dispatch, rejectWithValue }) => {
    const url = `/api/fetch_auto_dialer_leads`;

    try {
      const response = await axiosInstance.get(url);
      const { leadData, count } = response?.data?.data;
      return { leadData, count }; // Return the data to the reducer
    } catch (error) {
      dispatch(
        updateCurrAdmin({
          autodialer_access: false,
        })
      );
      dispatch(resetAutoDialerState());
      toast.success(
        error.response?.data?.message ??
          'Auto dialer is turned off successfully...'
      );
      return rejectWithValue(
        error.response?.data?.message ||
          'Something went wrong while fetching the leads for autodialer mode'
      );
    }
  }
);

export const fetchCurrentLeadDetails = createAsyncThunk(
  'autoDialer/currentLead', // Action type for Redux
  async (leadId, { rejectWithValue }) => {
    const url = `/api/users/${leadId}`;

    try {
      const response = await axiosInstance.get(url);
      const leadData = response?.data;
      console.log('response?.data >>>>', response?.data);
      return leadData; // Return the data to the reducer
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          'Something went wrong while fetching the current lead details for autodialer mode'
      );
    }
  }
);

export const initiateCallThunk = createAsyncThunk(
  'telecmi/initiateCall', // Action type for Redux
  async (leadDetail, { rejectWithValue }) => {
    const url = '/api/telecmi_initiate_call';

    try {
      const response = await axiosInstance.post(url, leadDetail);

      if (response?.data?.success) {
        toast.success('Call is initiated.');
        return {
          action: 'call_initiated',
        };
      } else {
        toast.error('Something went wrong, call cannot be initiated.');
        return rejectWithValue({
          action: 'error',
          errorMessage: 'Something went wrong, call cannot be initiated.',
        });
      }
    } catch (error) {
      console.error('Error initiating call:', error);
      const errorMessage =
        error.response?.data?.message ||
        'Something went wrong, call cannot be initiated.';
      toast.error(errorMessage);
      return rejectWithValue({
        action: 'error',
        errorMessage,
      });
    }
  }
);

export const lastHangupTimeThunk = createAsyncThunk(
  'telecmi/lastHangupTime', // Action type for Redux
  async (endTime, { rejectWithValue }) => {
    const url = '/api/last_hangup_time';

    try {
      const response = await axiosInstance.post(url, {
        timestamp: endTime.toISOString(),
      });

      if (response?.data?.success) {
        toast.success('Last hangup time updated.');
        return {
          action: 'hangup_time_updated',
        };
      } else {
        toast.error('Something went wrong, hangup time cannot be updated.');
        return rejectWithValue({
          action: 'error',
          errorMessage: 'Something went wrong, hangup time cannot be updated.',
        });
      }
    } catch (error) {
      console.error('Error updating last hangup time:', error);
      const errorMessage =
        error.response?.data?.message ||
        'Something went wrong, hangup time cannot be updated.';
      toast.error(errorMessage);
      return rejectWithValue({
        action: 'error',
        errorMessage,
      });
    }
  }
);

export const endAutoDialer = createAsyncThunk(
  'autoDialer/end', // Action type for Redux
  async (
    { leadID = null, activityType, notes = null },
    { dispatch, getState, rejectWithValue }
  ) => {
    const url = `/api/ending_auto_dialer_mode`;

    try {
      const response = await axiosInstance.post(url, {
        lead_id: leadID,
        activityType,
        notes: notes ? notes : '',
      });
      if (response?.data?.status === 'success') {
        // Use getState to get current auth state
        dispatch(
          updateCurrAdmin({
            autodialer_access: false,
          })
        );
        dispatch(resetAutoDialerState());
        toast.success('Auto dialer is turned off successfully...');
      }
    } catch (error) {
      toast.error(
        'Something went wrong while turning off the autodialer mode...'
      );
      return rejectWithValue(
        error.response?.data?.message ||
          'Something went wrong while fetching the current lead details for autodialer mode'
      );
    }
  }
);
