import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import DataTable from 'react-data-table-component';
import { CopyIcon } from '../../../utils/common';
import { newLeadsColumnsOrder } from '../../../utils/importLeadsColumnFormat';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import exisingSignup from './../../../images/existing_signup.png';

export default function DisplayImportLeads({ serverResponse }) {
  const columns = [
    {
      name: 'Failure reason',
      selector: (row) => (
        <Typography
          align='center'
          variant='body2'
          sx={{
            backgroundColor: '#ef9a9a',
            padding: '2px',
            margin: 1,
          }}
        >
          {row.error}
        </Typography>
      ),
      width: '400px',
    },
    {
      name: 'First name',
      selector: (row) => row?.row?.fname ?? '--',
      maxWidth: '200px',
    },
    {
      name: 'Last name',
      selector: (row) => row?.row?.lname ?? '--',
      width: '200px',
      maxWidth: '200px',
    },
    {
      name: 'Mobile',
      selector: (row) => row?.row?.mobile ?? '--',
      width: '200px',
    },
    {
      name: 'Email',
      selector: (row) => row?.row?.email ?? '--',
      width: '200px',
    },
    {
      name: 'Whatsapp',
      selector: (row) => row?.row?.whatsapp ?? '--',
      width: '200px',
    },
    {
      name: 'Lead type source',
      selector: (row) => row?.row?.lead_source_type ?? '--',
      width: '200px',
    },
    {
      name: 'College',
      selector: (row) => row?.row?.college ?? '--',
      width: '200px',
    },
    {
      name: 'Branch',
      selector: (row) => row?.row?.branch ?? '--',
      width: '200px',
    },
    {
      name: 'Graduation year',
      selector: (row) => row?.row?.graduation_year ?? '--',
      width: '200px',
    },
    {
      name: 'Company',
      selector: (row) => row?.row?.company ?? '--',
      width: '200px',
    },
    {
      name: 'Linkedin link',
      selector: (row) => row?.row?.linkedin_link ?? '--',
      width: '200px',
    },
    {
      name: 'Github link',
      selector: (row) => row?.row?.github_link ?? '--',
      width: '200px',
    },
    {
      name: 'Experience',
      selector: (row) => row?.row?.experience ?? '--',
      width: '200px',
    },
    {
      name: 'Role',
      selector: (row) => row?.row?.role ?? '--',
      width: '200px',
    },
    {
      name: 'Current salary',
      selector: (row) => row?.row?.current_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Desired salary',
      selector: (row) => row?.row?.desired_salary ?? '--',
      width: '200px',
    },
    {
      name: 'Current location',
      selector: (row) => row?.row?.current_location ?? '--',
      width: '200px',
    },
    {
      name: 'Total experience',
      selector: (row) => row?.row?.total_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Portfolio link',
      selector: (row) => row?.row?.portfolio_link ?? '--',
      width: '200px',
    },
    {
      name: 'Internship experience',
      selector: (row) => row?.row?.internship_experience ?? '--',
      width: '200px',
    },
    {
      name: 'Utm source',
      selector: (row) => row?.row?.utm_source ?? '--',
      width: '200px',
    },
    {
      name: 'Utm campaign',
      selector: (row) => row?.row?.utm_campaign ?? '--',
      width: '200px',
    },
    {
      name: 'Utm medium',
      selector: (row) => row?.row?.utm_medium ?? '--',
      width: '200px',
    },
    {
      name: 'Utm term',
      selector: (row) => row?.row?.utm_term ?? '--',
      width: '200px',
    },
    {
      name: 'Utm content',
      selector: (row) => row?.row?.utm_content ?? '--',
      width: '200px',
    },
    {
      name: 'Utm region',
      selector: (row) => row?.row?.utm_region ?? '--',
      width: '200px',
    },
    {
      name: 'Utm adset',
      selector: (row) => row?.row?.utm_adset ?? '--',
      width: '200px',
    },
    {
      name: 'Product',
      selector: (row) => row?.row?.product ?? '--',
      width: '200px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    cells: {
      style: {
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
    pagination: {
      style: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: 'black',
      },
    },
  };

  const downloadFailedEntriesCSV = (failedEntries) => {
    if (failedEntries.length === 0) {
      return;
    }

    const headers = ['error', ...newLeadsColumnsOrder];

    const csvData = failedEntries.map((entry) => {
      const rowData = [
        entry.error,
        ...newLeadsColumnsOrder.map((header) => entry.row[header] ?? ''),
      ];
      return rowData.join(',');
    });

    const csvContent = [headers.join(','), ...csvData].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'failed_entries.csv';
    link.click();
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction='row-reverse'
        spacing={2}
        justifyContent={'space-between'}
      >
        {serverResponse.failedLeadEntries.length > 0 && (
          <Button
            variant='contained'
            size='small'
            sx={{ height: '40px' }}
            onClick={() =>
              downloadFailedEntriesCSV(serverResponse.failedLeadEntries)
            }
          >
            Download Failed Entries
          </Button>
        )}

        <Stack
          direction='row-reverse'
          spacing={2}
        >
          <Box
            sx={{
              border: '1px solid #E0E0E0',
              fontSize: '15px',
              fontWeight: 'bold',
              width: '120px',
              height: '45px',
              textAlign: 'center',
              borderRadius: '10px',
              color: '#FF6464',
              display: 'flex',
              justifyContent: 'space-evenly',
              paddingTop: '10px',
            }}
          >
            <WarningAmberRoundedIcon />
            <Typography>
              Failed: {serverResponse.failedLeadEntries.length}
            </Typography>
          </Box>

          <Box
            sx={{
              border: '1px solid #E0E0E0',
              fontSize: '15px',
              fontWeight: 'bold',
              width: '250px',
              height: '45px',
              textAlign: 'center',
              borderRadius: '10px',
              color: '#D2C023',
              display: 'flex',
              justifyContent: 'space-evenly',
              paddingTop: '10px',
            }}
          >
            <img
              src={exisingSignup}
              height={'23px'}
              alt='icon'
            />
            <Typography sx={{ marginRight: '-15px' }}>
              Existing(1+Signup):{' '}
              {serverResponse.successSignupEntriesForDuplicateLeads.length}
            </Typography>
            {CopyIcon(
              serverResponse.successSignupEntriesForDuplicateLeads.join(','),
              true,
              'Copy lead ids'
            )}
          </Box>

          <Box
            sx={{
              border: '1px solid #E0E0E0',
              fontSize: '15px',
              fontWeight: 'bold',
              width: '220px',
              height: '45px',
              textAlign: 'center',
              borderRadius: '10px',
              color: '#15A615',
              display: 'flex',
              justifyContent: 'space-evenly',
              paddingTop: '10px',
            }}
          >
            <VerifiedRoundedIcon />
            <Typography sx={{ marginRight: '-15px' }}>
              New Signups: {serverResponse.successLeadEntries.length}
            </Typography>
            {CopyIcon(
              serverResponse.successLeadEntries.join(','),
              true,
              'Copy lead ids'
            )}
          </Box>
        </Stack>
      </Stack>

      {serverResponse.failedLeadEntries.length > 0 && (
        <DataTable
          customStyles={customStyles}
          highlightOnHover={true}
          columns={columns}
          data={serverResponse.failedLeadEntries}
          dense
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
          }}
        />
      )}
    </Stack>
  );
}
