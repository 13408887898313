import { createSlice } from '@reduxjs/toolkit';
import { loginUser } from './authThunk';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currAdmin: null,
    token: null,
    isAuthenticated: false,
    loading: false,
    successMessage: null,
    errorMessage: null,
    reasonMessage: null,
  },
  reducers: {
    login(state, action) {
      state.currAdmin = action.payload.admin;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.loading = false;
      state.successMessage = 'login successful';
      state.errorMessage = null;
    },
    logout(state) {
      state.currAdmin = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.successMessage = null;
      state.errorMessage = null;
    },
    updateCurrAdmin(state, action) {
      state.currAdmin = { ...state.currAdmin, ...action.payload };
    },

    setToken(state, action) {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.errorMessage = null;
        state.successMessage = null;
        state.reasonMessage = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.currAdmin = action.payload.admin;
        state.isAuthenticated = true;
        state.successMessage = 'login successful';
        state.errorMessage = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
        state.successMessage = null;
        state.reasonMessage = action.payload.reason;
        state.token = null;
        state.currAdmin = null;
        state.isAuthenticated = false;
      });
  },
});

export const { login, logout, updateCurrAdmin, setToken } = authSlice.actions;
export default authSlice.reducer;
