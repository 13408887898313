import axios from 'axios';
import { base_url } from '../components/Mode'; // Ensure this exports a function correctly
import { persistor } from '../redux/store';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: base_url(),
  timeout: 60000,
  withCredentials: true, // Enable sending cookies with requests
});

// Function to inject interceptors with user state and navigation hooks
export const setupAxiosInterceptors = (setUser, history) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.error('Request Error:', error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error?.response?.status === 401 ||
        (error?.response?.status === 403 &&
          error?.response?.data ===
            'Access denied. The required cookies are missing in the request.')
      ) {
        try {
          localStorage.clear(); // Clear all local storage
          persistor.purge();
        } catch (err) {
          console.error('Error occurred during auto-logout:', err);
        }
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
